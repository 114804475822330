export const PRE_BANNER_LOCATIONS = {
	TOP: 0,
	CENTRE: 1,
	BOTTOM: 2
}

export const PRE_BANNER_COLOUR_SCHEMES = {
	DARK: 0,
	LIGHT: 1,
	CUSTOM: 2
}

export const preBannerLocationRadios = [
	{
		value: PRE_BANNER_LOCATIONS.BOTTOM,
		imageSrc: require('../../../../../../assets/images/pre-banner-bottom.svg')
	},
	{
		value: PRE_BANNER_LOCATIONS.TOP,
		imageSrc: require('../../../../../../assets/images/pre-banner-top.svg')
	},
	{
		value: PRE_BANNER_LOCATIONS.CENTRE,
		imageSrc: require('../../../../../../assets/images/pre-banner-centre.svg')
	}
]

export const preBannerColourSchemeRadios = [
	{
		value: PRE_BANNER_COLOUR_SCHEMES.DARK,
		label: 'Dark'
	},
	{
		value: PRE_BANNER_COLOUR_SCHEMES.LIGHT,
		label: 'Light'
	},
	{
		value: PRE_BANNER_COLOUR_SCHEMES.CUSTOM,
		label: 'Custom'
	}
]

export const acceptAllRadios = [
	{
		value: true,
		label: 'Show'
	},
	{
		value: false,
		label: 'Hide'
	}
]
export const rejectAllRadios = [
	{
		value: true,
		label: 'Show'
	},
	{
		value: false,
		label: 'Hide'
	}
]
export const customiseButtonRadios = [
	{
		value: true,
		label: 'Show'
	},
	{
		value: false,
		label: 'Hide'
	}
]
