<template>
	<ViewLayout>
		<template #header-title>
			Configure Consent Banner
		</template>
		<template
			#header-caption
		>
			Configure a cookie consent banner
		</template>
		<template #header-after>
			<Stepper
				:step.sync="currentStep"
				:steps="steps"
				:in-progress="inProgress"
			/>
		</template>
		<template #content>
			<div class="mb-2">
				<StepperItems
					:step="currentStep"
					:steps="steps"
				>
					<template #0>
						<configure-cookie-banner-details
							v-if="!isLoading"
							:basic-details="basicDetails"
							:banner-brand-id="bannerBrandId"
							@update:bannerBrandId="updateBrandId"
							@next="nextStep"
							@cancel="cancel"
						/>
					</template>
					<template #1>
						<configure-cookie-pre-banner
							v-if="!isLoading"
							:pre-banner="preBanner"
							@cancel="cancel"
							@next="nextStep"
							@back="previousStep"
						/>
					</template>
					<template #2>
						<configure-cookie-consent-banner
							v-if="!isLoading"
							:banner="banner"
							:banner-brand-id="bannerBrandId"
							@cancel="cancel"
							@next="nextStep"
							@back="previousStep"
						/>
					</template>
					<template #3>
						<configure-cookies
							v-if="!isLoading"
							:cookies="cookies"
							:banner-brand-id="bannerBrandId"
							:cis-cookies="cisCookies"
							@cancel="cancel"
							@next="nextStep"
							@back="previousStep"
						/>
					</template>
					<template #4>
						<configure-translations
							v-if="!isLoading"
							@cancel="cancel"
							@next="nextStep"
							@back="previousStep"
						/>
					</template>
					<template #5>
						<configure-installation
							v-if="!isLoading"
							:banner-brand-id="bannerBrandId"
							@cancel="cancel"
							@complete="completeConsentBanner"
							@back="previousStep"
						/>
					</template>
				</StepperItems>
			</div>
		</template>
	</ViewLayout>
</template>

<script>
import { mapActions } from 'vuex'
import ViewLayout from '../../../../../../../shared/layouts/view-layout.vue'
import Stepper from '../../../../../../../shared/components/stepper.vue'
import StepperItems from '../../../../../../../shared/components/stepper-items.vue'
import store from '../../../../../state/store.js'
import {
	getBasicDetails,
	getConsentBannerPreBanner,
	getConsentBannerConfiguration,
	getConsentBannerScripts,
	getConsentBannerCisCookies,
	getConsentBannerConfigurationStatus,
	getConsentBannerProfiles
} from '../../../../../services/ConsentBannerService.js'
import configureCookieBannerDetails from './configure-cookie-banner-details/configure-cookie-banner-details.vue'
import configureCookiePreBanner from './configure-cookie-banner-pre-banner/configure-cookie-banner-pre-banner.vue'
import configureCookieConsentBanner from './configure-cookie-consent-banner/configure-cookie-consent-banner.vue'
import configureCookies from './configure-cookies/configure-cookies.vue'
import configureTranslations from './configure-translations/configure-translations.vue'
import configureInstallation from './configure-installation/configure-installation.vue'

export default {
	page: {
		title: 'Configure Consent Banner',
		meta: [{ name: 'description', content: 'The Create Cookie Banner page.' }]
	},
	components: {
		ViewLayout,
		Stepper,
		StepperItems,
		'configure-cookie-banner-details': configureCookieBannerDetails,
		'configure-cookie-pre-banner': configureCookiePreBanner,
		'configure-cookie-consent-banner': configureCookieConsentBanner,
		'configure-cookies': configureCookies,
		'configure-translations': configureTranslations,
		'configure-installation': configureInstallation
	},
	async beforeRouteEnter (to, from, next) {
		const cookieBannerId = parseInt(to.params.cookieBannerId)
		if (!cookieBannerId) {
			return next(comp => {
				comp.isLoading = false
			})
		}
		try {
			const configurationStatusId = (await getConsentBannerConfigurationStatus(cookieBannerId)).data.configurationStatus - 1
			const basicDetails = (await getBasicDetails(cookieBannerId)).data
			const preBanner = (await getConsentBannerPreBanner(cookieBannerId)).data
			const banner = (await getConsentBannerConfiguration(cookieBannerId)).data
			const profiles = (await getConsentBannerProfiles(cookieBannerId)).data
			let cookieScripts = (await getConsentBannerScripts(cookieBannerId)).data
			let cisCookies = (await getConsentBannerCisCookies(cookieBannerId)).data

			if (cookieScripts === '') {
				cookieScripts = []
			}
			if (cisCookies === '') {
				cisCookies = {}
			}

			// set globally used settings
			store.dispatch(
				'consentBanners/setGlobalPreBannerHidden',
				preBanner.hidePreBanner
			)

			const cisDomain = basicDetails.cisClientDomain

			// check if string is empty
			if (cisDomain.length === 0) {
				store.dispatch('consentBanners/setCisEnabled', { cisEnabled: false })
			} else {
				store.dispatch('consentBanners/setCisEnabled', { cisEnabled: true })
			}

			store.dispatch('consentBanners/setBannerPreviewData', banner)

			next(comp => {
				comp.basicDetails = basicDetails
				comp.preBanner = preBanner
				comp.banner = banner
				comp.profiles = profiles
				comp.cookies = cookieScripts
				comp.cisCookies = cisCookies
				comp.bannerBrandId = basicDetails.brandId
				if (configurationStatusId !== 6) {
					comp.currentStep = configurationStatusId
				} else if (configurationStatusId === 6) {
					comp.currentStep = configurationStatusId - 1
					comp.inProgress = false
				}
				comp.steps.forEach(step => {
					if (comp.currentStep > step.slot) {
						step.complete = true
					}
				})
				comp.isLoading = false
			})
		} catch (error) {
			console.error(error)
			next({ name: '404' })
		}
	},
	data () {
		return {
			stepModel: 0,
			basicDetails: null,
			preBanner: null,
			banner: null,
			cisCookies: null,
			cookies: null,
			profiles: null,
			inProgress: true,
			isLoading: true,
			currentStep: 0,
			steps: [
				{ slot: 0, title: 'Details', stepNumber: '01', stepComplete: false },
				{ slot: 1, title: 'Pre-Banner', stepNumber: '02', stepComplete: false },
				{ slot: 2, title: 'Banner', stepNumber: '03', stepComplete: false },
				{ slot: 3, title: 'Cookies', stepNumber: '04', stepComplete: false },
				{ slot: 4, title: 'Translations', stepNumber: '05', stepComplete: false },
				{ slot: 5, title: 'Installation', stepNumber: '06', stepComplete: false }
			],
			bannerBrandId: null
		}
	},
	mounted () {
		if (this.profiles) {
			const allProfiles = this.profiles.profiles
			const gpcProfiles = allProfiles.filter(profile => {
				return profile.isGpc === 1 || profile.gpc === true
			})
			if (gpcProfiles.length > 0) {
				this.setGpcEnabled({ gpcEnabled: true })
			} else {
				this.setGpcEnabled({ gpcEnabled: false })
			}
		}
	},
	methods: {
		...mapActions('consentBanners', [
			'setGpcEnabled',
			'getConsentBannerConfigurationStatus',
			'getBasicDetails',
			'getConsentBannerPreBanner',
			'getGpcPreBanner',
			'getConsentBannerConfiguration',
			'getConsentBannerProfiles',
			'getConsentBannerScripts',
			'getConsentBannerCisCookies'
		]),
		nextStep () {
			this.steps.forEach((step, index) => {
				if (index <= this.currentStep) {
					step.stepComplete = true
				}
			})
			if (this.currentStep < 5) {
				this.currentStep += 1
			}
			this.scrollToTop()
		},
		previousStep () {
			this.steps.forEach((step, index) => {
				if (index >= this.currentStep) {
					step.stepComplete = false
				}
			})
			this.currentStep -= 1
			this.scrollToTop()
		},
		completeConsentBanner () {
			this.$router.push({ name: 'consent-banners' })
			this.$store.commit('consentBanners/RESET_ALL_TRANSLATIONS')
		},
		cancel () {
			this.$router.push({ name: 'consent-banners' })
			this.$store.commit('consentBanners/RESET_ALL_TRANSLATIONS')
		},
		scrollToTop () {
			window.scrollTo(0, 0)
		},
		async updateBrandId (value) {
			this.bannerBrandId = value
			await this.$nextTick()
		}
	}
}
</script>

<style lang="scss">
.v-application {
	.cassie-steps-container {
		border-radius: var(--cassie-tabs-container-border-radius);
		box-shadow: var(--cassie-tabs-container-box-shadow);
		.cassie-stepper-step {
			font-size: var(--cassie-tab-font-size);
			text-transform: var(--cassie-tab-text-transform);
			color: var(--cassie-tab-inactive-text-color);
			border-right: var(--cassie-tab-border-right);
			opacity: var(--cassie-tab-opacity);
			&:nth-last-child(1) {
				border-right: none;
			}
		}
		.cassie-stepper-step-complete.cassie-stepper-step-complete {
			font-size: var(--cassie-tab-font-size);
			color: var(--cassie-tab-step-complete-text);
			text-transform: var(--cassie-tab-text-transform);
			background-color: var(--cassie-tab-step-complete-background-color);
			border-right: var(--cassie-tab-border-right);
			border-radius: var(--cassie-tab-active-border-radius);
			opacity: var(--cassie-tab-opacity);
			&:nth-last-child(1) {
				border-right: none;
			}
		}
		.cassie-active-stepper-step {
			color: var(--cassie-tab-step-active-text-color);
			background-color: var(--cassie-tab-step-active-background-color);
			border-radius: var(--cassie-tab-active-border-radius);
		}
	}
}
</style>
