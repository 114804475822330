<template>
	<!-- eslint-disable max-lines -->
	<div>
		<ValidationForm
			#default="{ handleSubmit, invalid }"
			ref="validationForm"
		>
			<div class="cassie-vertical-md">
				<SectionCard v-if="!bannerHasCategories">
					<template #body>
						<v-alert
							light
							border="bottom"
							colored-border
							type="warning"
							elevation="2"
						>
							This banner does not currently contain any categories. Please go
							back and add a category from the 'Banner' step.
						</v-alert>
					</template>
				</SectionCard>
				<SectionCard
					v-for="category in currentBannerCategories"
					:key="category.cookieCategoryId"
				>
					<template #title>
						{{ category.cookieCategoryName }}
					</template>
					<template #subtitle>
						{{ category.cookieCategoryName }} Category Text
					</template>
					<template #title-action>
						<TextButton
							v-if="userCanCreateUpdate"
							@click="openCreateScriptModal(category.cookieCategoryId)"
						>
							<v-icon>
								mdi-plus
							</v-icon>
							Create Script
						</TextButton>
					</template>
					<template #body>
						<div class="d-flex flex-column cassie-vertical-md">
							<div class="d-flex flex-row cassie-horizontal-sm">
								<Dropdown
									v-model="selectedCookieScripts"
									:label="category.cookieCategoryName + ' Cookie Scripts'"
									:disabled="!userCanCreateUpdate"
									multiple
									select-all
									class="cassie-input-width-md"
									:items="
										availableCookieScripts.filter(
											(script) =>
												script.cookieCategoryId === category.cookieCategoryId
										)
									"
									item-text="cookieScriptName"
									item-value="cookieScriptChannelId"
									return-object
									@change="updateSelectAllChecked(availableCookieScripts.filter(
										(script) =>
											script.cookieCategoryId === category.cookieCategoryId
									))"
								>
									<template
										v-if="showSelectAll(category.cookieCategoryId)"
										#prepend-item
									>
										<v-list-item
											dense
											@click="toggleSelectAll(availableCookieScripts.filter(
												(script) =>
													script.cookieCategoryId === category.cookieCategoryId
											))"
										>
											<v-checkbox
												v-model="selectAllChecked"
												dense
											/>
											<v-list-item-title class="pb-2">
												Select All
											</v-list-item-title>
										</v-list-item>
										<v-divider />
									</template>
								</Dropdown>
								<PrimaryActionButton
									v-if="userCanCreateUpdate"
									@click="addScriptToBanner(formData.selectedScript)"
								>
									Add
								</PrimaryActionButton>
							</div>
							<v-alert
								v-if="selectedScripts(category.cookieCategoryId).length === 0"
								light
								class="mb-0"
							>
								<div
									class="grey--text lighten-4 text-center"
								>
									No Cookie Scripts have been added to this Category
								</div>
							</v-alert>
							<DataTable
								v-else
								:headers="scriptTableHeader"
								:sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc"
								:disable-sort="false"
								:items="selectedScripts(category.cookieCategoryId)"
								hide-default-footer
							>
								<template #item.isRunFirst="{ item }">
									<v-icon
										v-if="item.isRunFirst === true"
										small
									>
										mdi-check
									</v-icon>
									<v-icon
										v-else
										small
									>
										mdi-close
									</v-icon>
								</template>
								<template #item.gpcEnabled="{ item }">
									<v-icon
										v-if="item.gpcEnabled === true"
										small
									>
										mdi-check
									</v-icon>
									<v-icon
										v-else
										small
									>
										mdi-close
									</v-icon>
								</template>
								<template #item.action="{ item }">
									<IconButton
										:disabled="!userCanCreateUpdate"
										tooltip-text="Edit Script"
										@click="openEditScriptModal(item, 'Script')"
									>
										mdi-pencil
									</IconButton>
									<IconButton
										v-if="
											item.displayOrder !==
												selectedScripts(category.cookieCategoryId).length
										"
										:disabled="!userCanCreateUpdate"
										@click="moveScript(item, 'moveDown')"
									>
										mdi-arrow-down
									</IconButton>

									<IconButton
										v-if="item.displayOrder !== 1"
										:disabled="!userCanCreateUpdate"
										@click="moveScript(item, 'moveUp')"
									>
										mdi-arrow-up
									</IconButton>
									<IconButton
										tooltip-text="Remove Script"
										:disabled="!userCanCreateUpdate"
										@click="deleteScriptFromConsentBanner(item)"
									>
										mdi-close
									</IconButton>
								</template>
							</DataTable>
						</div>
					</template>
				</SectionCard>
				<SectionCard v-if="globalCisEnabled && bannerHasCategories">
					<template #title>
						Cassie Identity Service (CIS)
					</template>
					<template #subtitle>
						Please select which cookies you wish to have protected by
						Cassie Identity Service (CIS)
					</template>
					<template #body>
						<div class="d-flex flex-column cassie-vertical-sm">
							<div class="d-flex flex-row cassie-horizontal-sm">
								<Dropdown
									v-model="formData.selectedCisScript"
									label="Cookie Scripts"
									class="cassie-input-width-md"
									:disabled="!userCanCreateUpdate"
									:items="availableCisScripts"
									item-text="cookieScriptName"
									item-value="cookieScriptChannelId"
									return-object
									@change="populateCookiesInScript(formData.selectedCisScript)"
								/>
								<Dropdown
									v-if="
										availableCisCookies.length !== 0 &&
											formData.selectedCisScript.cookieScriptId > 0
									"
									v-model="formData.selectedCisCookie"
									label="Cookies"
									class="cassie-input-width-md"
									:disabled="
										!userCanCreateUpdate ||
											availableCisCookies.length === 0
									"
									:items="availableCisCookies"
								/>
								<Dropdown
									v-else
									disabled
									label="No Cookies are available to select."
									class="cassie-input-width-md"
								/>
								<PrimaryActionButton
									v-if="availableCisCookies.length !== 0"
									:disabled="
										!userCanCreateUpdate ||
											!formData.selectedCisCookie
									"
									@click="addCisCookieToBanner(formData.selectedCisScript)"
								>
									Add
								</PrimaryActionButton>
							</div>
							<v-alert
								v-if="formData.selectedCisCookies.length === 0"
								light
							>
								<div class="grey--text lighten-4 text-center">
									No Cookies are currently protected by Cassie Identity
									Service
								</div>
							</v-alert>
							<DataTable
								v-else
								:headers="cisCookiesTableHeader"
								:items="formData.selectedCisCookies"
								hide-default-footer
							>
								<template #item.action="{ item }">
									<v-icon
										small
										:disabled="!userCanCreateUpdate"
										@click="deleteCisCookie(item)"
									>
										mdi-close
									</v-icon>
								</template>
							</DataTable>
						</div>
					</template>
				</SectionCard>
				<SectionCard v-if="bannerHasCategories">
					<template #title>
						Customise Script's Run Order
					</template>
					<template #body>
						<div class="d-flex flex-row cassie-horizontal-sm">
							<v-expansion-panels accordion>
								<v-expansion-panel>
									<v-expansion-panel-header #default="{ open }">
										<span
											v-if="!open"
										>
											Show Run Order
										</span>
										<span
											v-else
										>
											Hide Run Order
										</span>
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<v-card-subtitle>
											Please identify the Run Order in which you wish your
											Cookie Scripts to execute.
										</v-card-subtitle>
										<v-alert
											v-if="formData.selectedScripts.length === 0"
										>
											<div class="grey--text lighten-4 text-center">
												No Cookies have been selected on this Consent
												Banner.
											</div>
										</v-alert>
										<DataTable
											v-else
											:headers="runOrderTableHeader"
											:sort-by="sortByRunOrder"
											:sort-desc="sortDesc"
											:disable-sort="false"
											hide-default-footer
											:items-per-page="50"
											:items="formData.selectedScripts"
										>
											<template #item.isRunFirst="{ item }">
												<v-icon
													v-if="item.isRunFirst === true"
													small
												>
													mdi-check
												</v-icon>
												<v-icon
													v-else
													small
												>
													mdi-close
												</v-icon>
											</template>
											<template #item.action="{ item }">
												<IconButton
													v-if="(
														item.runOrder !==
														formData.selectedScripts.length &&
														lastEssentialScriptRunFirst !== item.runOrder &&
														lastOtherScriptRunFirst !== item.runOrder &&
														lastEssentialScript !== item.runOrder
													) && userCanCreateUpdate
													"
													tooltip-text="Move Down Run Order"
													@click="changeRunOrder(item, 'moveDown')"
												>
													mdi-arrow-down
												</IconButton>

												<IconButton
													v-if="(
														item.runOrder !== 1 &&
														lastOtherScriptRunFirst + 1 !== item.runOrder &&
														lastEssentialScript + 1 !== item.runOrder &&
														lastEssentialScriptRunFirst + 1 !==
														item.runOrder
													) && userCanCreateUpdate
													"
													tooltip-text="Move Up Run Order"
													@click="changeRunOrder(item, 'moveUp')"
												>
													mdi-arrow-up
												</IconButton>
											</template>
										</DataTable>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</div>
					</template>
				</SectionCard>
				<SectionCard v-if="bannerHasCategories">
					<template #title>
						Preview
					</template>
					<template #body>
						<CookieBannerPreview
							v-if="bannerHasCategories"
							:appearance="cookiesPreview.selectedBannerAppearance"
							:colour-scheme="previewSettings.colourScheme"
							:settings="previewSettings"
							:header-html="selectedHeaderHTML"
							:footer-html="selectedFooterHTML"
						/>
					</template>
				</SectionCard>
			</div>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton @click="cancelCookies">
						Exit
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton
						v-if="lastSavedStep >= currentStep && userCanCreateUpdate"
						class="mr-2"
						:disabled="invalid"
						@click="handleSubmit(proceedFromCookies)"
					>
						Save Changes
					</PrimaryActionButton>
					<SecondaryActionButton @click="backToBanner">
						Back
					</SecondaryActionButton>
					<SecondaryActionButton
						v-if="lastSavedStep >= currentStep"
						class="ml-2"
						@click="nextStep"
					>
						Next
					</SecondaryActionButton>
					<PrimaryActionButton
						v-else-if="userCanCreateUpdate"
						:disabled="invalid"
						@click="handleSubmit(proceedFromCookies)"
					>
						Save and Continue
					</PrimaryActionButton>
				</template>
			</PageActionRow>
		</ValidationForm>
		<!-- Script form -->
		<ScriptForm
			v-if="showScriptModal"
			:form="formData.scriptForm"
			:selected-brand-id="bannerBrandId"
			:accessed-via-consent-banner="true"
			@openEditModal="openEditScriptModal"
			@cancelForm="closeCreateScriptModal"
			@createNewScript="createNewScript"
			@updateScript="updateScript"
		/>
	</div>
</template>

<script>
/* eslint-disable max-lines */
import { mapActions, mapGetters } from 'vuex'
import ValidationForm from '../../../../../../../../shared/components/validation-form.vue'
import ScriptForm from '../../../../../shared/script-form.vue'
import { showSnackbar } from '../../../../../../../../shared/state/snackbar.js'
import CookieBannerPreview from '../cookie-banner-preview/cookie-banner-preview.vue'
import SectionCard from '../../../../../../../../shared/components/section-card.vue'
import PageActionRow from '../../../../../../../../shared/components/page-action-row.vue'
import DataTable from '../../../../../../../../shared/components/data-table.vue'
import Dropdown from '../../../../../../../../shared/components/dropdown.vue'
import TextButton from '../../../../../../../../shared/components/text-button.vue'
import IconButton from '../../../../../../../../shared/components/icon-button.vue'
import PrimaryActionButton from '../../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../../shared/components/secondary-action-button.vue'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS } from '../../../../../../../../shared/permissions/admin-portal-permissions.js'
const UNIVERSAL_BRAND = 0

export default {
	components: {
		ValidationForm,
		ScriptForm,
		CookieBannerPreview,
		TextButton,
		DataTable,
		Dropdown,
		SectionCard,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		IconButton
	},
	props: {
		cookies: {
			type: Array,
			default: null
		},
		cisCookies: {
			type: Object,
			default: null
		},
		bannerBrandId: Number
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			UNIVERSAL_BRAND,
			currentStep: 4,
			showScriptModal: false,
			showCisCookies: false,
			showScriptRunOrder: false,
			isValid: false,
			selectedCookieScripts: [],
			selectAllChecked: false,
			formData: {
				scriptForm: {
					id: null,
					cookieScriptChannelId: null,
					type: '',
					brand: Number,
					category: Number,
					name: '',
					isRunFirst: false,
					gpcEnabled: false,
					html: '',
					informationUrl: '',
					cookieInScriptField: '',
					cookiesInScript: [],
					scriptExpiry: '',
					consentExpiry: '',
					scriptPlacement: '<head>',
					// head placement fields
					optInScript: '',
					optOutScript: '',
					// body placement fields
					bodyOptInDiv: '',
					bodyOptInScript: '',
					bodyOptOutDiv: '',
					bodyOptOutScript: '',
					createdByName: '',
					createdDate: '',
					editedByName: '',
					lastEditedDate: '',
					associatedBanners: [],
					modalAccessedFrom: 'configure-cookies'
				},
				selectedScript: {},
				selectedScripts: [],
				deletedScripts: [],
				selectedCisScript: {},
				selectedCisCookies: [],
				cookiesInScript: [],
				selectedCookiesInScript: [],
				selectedCisCookie: '',
				selectedEssentialScripts: [],
				selectedOtherScripts: []
			},
			scriptTableHeader: [
				{
					text: 'Id',
					value: 'cookieScriptId',
					sortable: false
				},
				{
					text: 'Cookie Script Name',
					value: 'cookieScriptName',
					sortable: false
				},
				{
					text: 'Run First?',
					value: 'isRunFirst',
					sortable: false
				},
				{
					text: 'GPC',
					value: 'gpcEnabled',
					sortable: false
				},
				{
					text: 'Type',
					value: 'type',
					sortable: false
				},
				{
					text: 'Display Order',
					value: 'displayOrder',
					sortable: false
				},
				{
					text: 'Action',
					value: 'action',
					sortable: false
				}
			],
			cisCookiesTableHeader: [
				{
					text: 'Cookie Script Name',
					value: 'cookieScriptName',
					sortable: false
				},
				{
					text: 'Cookie Name in Browser',
					value: 'cookieNameInBrowser',
					sortable: false
				},
				{
					text: 'Type',
					value: 'type',
					sortable: false
				},
				{
					text: 'Action',
					value: 'action',
					sortable: false
				}
			],
			runOrderTableHeader: [
				{
					text: 'Id',
					value: 'cookieScriptId'
				},
				{
					text: 'Cookie Script Name',
					value: 'cookieScriptName'
				},
				{
					text: 'Cookie Category Name',
					value: 'cookieCategoryName'
				},
				{
					text: 'Run First?',
					value: 'isRunFirst'
				},
				{
					text: 'Run Order',
					value: 'runOrder'
				},
				{
					text: 'Action',
					value: 'action'
				}
			],
			sortBy: 'displayOrder',
			sortByRunOrder: 'runOrder',
			sortDesc: false,
			loading: true,
			displayPreviewTabs: false,
			formUpdated: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('headers', ['headers']),
		...mapGetters('footers', ['footers']),
		...mapGetters('cookieCategories', ['cookieCategories']),
		...mapGetters('cookieScripts', ['cookieScripts']),
		...mapGetters('consentBanners', [
			'currentBannerCategories',
			'lastSavedStep',
			'configurationStatusId',
			'currentConsentBannerId',
			'globalCisEnabled',
			'cookiesPreview'
		]),
		isEditMode () {
			return this.cookies.length > 0
		},
		selectedScripts () {
			return cookieCategoryId =>
				this.formData.selectedScripts
					.filter(script => script.cookieCategoryId === cookieCategoryId)
					.map(script => {
						return {
							cookieCategoryId: script.cookieCategoryId,
							cookieCategoryName: script.cookieCategoryName,
							cookieScriptId: script.cookieScriptId,
							cookieScriptChannelId: script.cookieScriptChannelId,
							cookieScriptName: script.cookieScriptName,
							isRunFirst: script.isRunFirst,
							runOrder: script.runOrder,
							gpcEnabled: script.gpcEnabled,
							type: script.type,
							displayOrder: script.displayOrder,
							displayText: script.displayText
						}
					})
		},
		runOrderScripts () {
			const runOrderScripts = JSON.stringify(this.formData.selectedScripts)

			return JSON.parse(runOrderScripts)
		},
		availableCookieScripts () {
			let filteredCookieScripts = []

			if (this.bannerBrandId === UNIVERSAL_BRAND) {
				filteredCookieScripts = this.cookieScripts.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				filteredCookieScripts = this.cookieScripts.filter(({ brandId }) => brandId === this.bannerBrandId || brandId === UNIVERSAL_BRAND)
			}

			// Filter already selected categories from those available to select
			if (this.formData.selectedScripts.length === 0) return this.cookieScripts

			const selectedScriptIds = this.formData.selectedScripts.map(x => {
				return x.cookieScriptId
			})
			const availableScripts = filteredCookieScripts.filter(
				x => !selectedScriptIds.includes(x.cookieScriptId)
			)

			return availableScripts
		},
		availableCisCookies () {
			const availableCookies = this.formData.cookiesInScript.filter(
				cookie => !this.formData.selectedCookiesInScript.includes(cookie)
			)
			return availableCookies
		},

		availableCisScripts () {
			const availableCisScripts = this.formData.selectedScripts

			availableCisScripts.filter(
				script => script.selectedCookiesInScript !== ''
			)

			return availableCisScripts
		},
		lastEssentialScriptRunFirst () {
			const essentialRunFirstScripts = this.formData.selectedScripts.filter(
				essentialScript =>
					essentialScript.type === 'Essential' &&
					essentialScript.isRunFirst === true
			)
			const highestRunFirst = Math.max.apply(
				Math,
				essentialRunFirstScripts.map(function (script) {
					return script.runOrder
				})
			)

			return highestRunFirst
		},
		lastOtherScriptRunFirst () {
			const otherRunFirstScripts = this.formData.selectedScripts.filter(
				otherScript =>
					otherScript.type !== 'Essential' && otherScript.isRunFirst === true
			)
			const highestRunFirst = Math.max.apply(
				Math,
				otherRunFirstScripts.map(function (script) {
					return script.runOrder
				})
			)

			return highestRunFirst
		},
		lastEssentialScript () {
			const essentialScripts = this.formData.selectedScripts.filter(
				essentialScript => essentialScript.type === 'Essential'
			)
			const highestRunFirst = Math.max.apply(
				Math,
				essentialScripts.map(function (script) {
					return script.runOrder
				})
			)

			return highestRunFirst
		},
		previewSettings () {
			return {
				optOutText: this.cookiesPreview.optOutLabel,
				optInText: this.cookiesPreview.optInLabel,
				displayLogo: this.cookiesPreview.logoImgSrc,
				displayToggleLabels: this.cookiesPreview.displayToggleLabels
					? this.cookiesPreview.optOutLabel !== '' &&
					this.cookiesPreview.optInLabel !== ''
					: false,
				userConsentTabLabel: this.cookiesPreview.userConsentTabLabel,
				legitimateinterestTabLabel: this.cookiesPreview
					.legitimateInterestTabLabel,
				displayExpandCollapseLabels: this.cookiesPreview
					.displayExpandCollapseLabels,
				uploadedImageTag: this.cookiesPreview.logoImgSrc,
				submitPreferencesText: this.cookiesPreview.savePreferencesText,
				colourScheme: JSON.parse(this.cookiesPreview.bannerColourSchemeJson),
				categories: this.previewCategories,
				showTabs: this.displayPreviewTabs
			}
		},
		selectedHeaderHTML () {
			if (!this.cookiesPreview.headerId) return null
			return this.headers.find(x => x.id === this.cookiesPreview.headerId)
				.headerHtml
		},
		selectedFooterHTML () {
			if (!this.cookiesPreview.footerId) return null
			return this.footers.find(x => x.id === this.cookiesPreview.footerId)
				.footerHtml
		},
		previewCategories () {
			const categories = JSON.parse(
				JSON.stringify(this.currentBannerCategories)
			)

			categories.forEach(category => {
				category.name = category.cookieCategoryName
				category.isOptIn = false
				category.cookies = this.formData.selectedScripts.filter(
					matchingScript =>
						matchingScript.cookieCategoryId === category.cookieCategoryId
				)

				category.cookies.forEach(cookie => {
					cookie.name = cookie.cookieScriptName
					const matchingCookie = this.cookieScripts.find(
						script => script.cookieScriptId === cookie.cookieScriptId
					)

					if (
						matchingCookie.cookieScriptType === 'Legitimate Interest' &&
						this.displayPreviewTabs === false
					) {
						this.displayPreviewTabs = true
					}
					if (matchingCookie.cookieScriptType === 'Legitimate Interest') {
						cookie.isOptIn = true
					}
					cookie.description = matchingCookie.displayText
				})
				category.cookies.sort((a, b) => {
					return a.displayOrder - b.displayOrder
				})

				// get display text for description
				const matchingCategory = JSON.parse(
					JSON.stringify(this.cookieCategories)
				).find(x => x.categoryId === category.cookieCategoryId)
				category.description = matchingCategory?.displayText
				if (category.type === 'Essential' || matchingCategory?.isStrictlyCategory === true) {
					category.isStrictlyNecessary = true
					category.selected = true
				} else {
					category.selected = false
				}
			})

			categories.sort((a, b) => {
				return a.displayOrder - b.displayOrder
			})

			return categories
		},
		bannerHasCategories () {
			return this.currentBannerCategories.length > 0
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		}
	},
	async mounted () {
		await Promise.all([
			this.getCookieCategories(),
			this.getConsentBannerCategories(this.$route.params.cookieBannerId),
			this.getCookieScripts(),
			this.getHeaders(),
			this.getFooters()
		]).finally(() => {
			this.isEditMode && this.populateForm()
		})

		if (!this.globalGpcEnabled) {
			const gpcColumn = this.scriptTableHeader.filter(column => {
				return column.value === 'gpcEnabled'
			})

			const tableIndex = this.scriptTableHeader.indexOf(gpcColumn[0])

			this.scriptTableHeader.splice(tableIndex, 1)
		}
		this.loading = false
		this.$refs.validationForm.reset()
	},
	methods: {
		...mapActions('headers', ['getHeaders']),
		...mapActions('footers', ['getFooters']),
		...mapActions('cookieScripts', ['getCookieScripts', 'getCookieScript', 'createCookieScript', 'updateCookieScript']),
		...mapActions('cookieCategories', ['getCookieCategories']),
		...mapActions('consentBanners', [
			'getConsentBannerCategories',
			'updateBannerScripts',
			'updateCisCookies',
			'updateConfigurationStatusId'
		]),
		async proceedFromCookies () {
			this.formUpdated = true

			await this.submitBannerScripts()
			await this.setCisCookies()
		},
		async submitBannerScripts () {
			const bannerScripts = JSON.parse(
				JSON.stringify(this.formData.selectedScripts)
			)
			bannerScripts.forEach(script => {
				script.scriptExecutionOrderId = script.runOrder
				// unsure if below needs to be added
				// confirmed - we need to pass in the channelId and not the script id(awaiting api parameter name change)
				delete script.runOrder
				delete script.cookieCategoryId
				delete script.cookieCategoryName
				delete script.cookieScriptId
				delete script.cookieScriptName
				// unsure if below needs to be deleted
				delete script.cookiesInScript
				delete script.gpcEnabled
				delete script.isRunFirst
				delete script.type
			})
			const bannerScriptData = {
				consentBannerId: this.$route.params.cookieBannerId,
				scripts: bannerScripts
			}

			await this.updateBannerScripts(bannerScriptData)
		},
		async setCisCookies () {
			let browserCookies = []
			this.formData.selectedCisCookies.forEach(script => {
				browserCookies.push(script.cookieNameInBrowser)
			})
			if (browserCookies.length > 0) {
				browserCookies = browserCookies.join(',')
			} else {
				browserCookies = null
			}

			const cisCookiesData = {
				consentBannerId: this.$route.params.cookieBannerId,
				cisCookies: browserCookies
			}
			// dont execute if the last saved step is greater than the current step

			await this.updateCisCookies(cisCookiesData).then(() => {
				const updateBannerStatusObject = {
					consentBannerId: this.$route.params.cookieBannerId,
					configurationStatusId: 5
				}
				if (this.lastSavedStep <= this.currentStep) {
					this.updateConfigurationStatusId(updateBannerStatusObject)
				}
			})

			if (this.lastSavedStep >= this.currentStep) {
				this.showSnackbar('Cookies updated successfully')
			} else if (this.lastSavedStep < this.currentStep) {
				this.$emit('next')
			}
		},
		populateForm () {
			const reformattedSelected = this.cookies
			reformattedSelected.forEach(script => {
				script.type = script.cookieScriptType
				delete script.cookieScriptType
				script.runOrder = script.cookieScriptExecutionOrder
				delete script.cookieScriptExecutionOrder
				script.isRunFirst = script.isRunFirstCookieScript
				delete script.isRunFirstCookieScript
				script.displayOrder = script.cookieScriptDisplayOrderWithinCategory
				delete script.cookieScriptDisplayOrderWithinCategory
				if (script.namesOfCookiesInScript !== '') {
					script.cookiesInScript = script.namesOfCookiesInScript.split(',')
				}
			})

			if (this.cisCookies?.cookieScripts?.length > 0) {
				this.cisCookies.cookieScripts.forEach(script => {
					if (script.cookieNames.length !== 0) {
						script.cookieNames.forEach(cookie => {
							const cisCookie = {
								cookieNameInBrowser: cookie,
								cookieScriptName: script.cookieScriptName,
								type: script.cookieScriptType
							}
							this.formData.selectedCisCookies.push(cisCookie)
							this.formData.selectedCookiesInScript.push(
								cisCookie.cookieNameInBrowser
							)
						})
					}
				})
			}

			this.formData.selectedScripts = reformattedSelected

			this.formData.selectedScripts = JSON.parse(
				JSON.stringify(this.formData.selectedScripts)
			)
			this.$refs.validationForm.reset()
		},

		cancelCookies () {
			this.$router.push({ name: 'consent-banners' })
		},
		// Toggle scrollbar using class replicating swal2 behaviour
		preventScrolling () {
			document.documentElement.classList.add('prevent-scroll')
		},
		allowScrolling () {
			document.documentElement.classList.remove('prevent-scroll')
		},
		openCreateScriptModal (categoryId) {
			this.preventScrolling()
			this.showScriptModal = true
			this.formData.scriptForm.category = categoryId
		},

		openEditScriptModal (script) {
			if (!this.userCanCreateUpdate) {
				setTimeout(() => {
					this.showSnackbar({ text: 'You do not have permission to edit this form', color: 'red' })
				}, 1000)
			}
			this.getCookieScript(script.cookieScriptChannelId)
				.then(script => {
					this.formData.scriptForm.cookieScriptChannelId =
						script.cookieScriptChannelId
					this.formData.scriptForm.id = script.cookieScriptId
					this.formData.scriptForm.type = script.cookieScriptType
					this.formData.scriptForm.brand = script.brandId
					this.formData.scriptForm.category = script.cookieCategoryId
					this.formData.scriptForm.name = script.cookieScriptName
					this.formData.scriptForm.isRunFirst = script.isRunFirst
					this.formData.scriptForm.gpcEnabled = script.gpcEnabled
					this.formData.scriptForm.html = script.displayText
					this.formData.scriptForm.informationUrl = script.informationUrl
					this.convertNamesToArray(script.namesOfCookiesInScript)
					this.formData.scriptForm.scriptExpiry = script.scriptExpiryInDays
					this.formData.scriptForm.consentExpiry = script.consentExpiryInDays
					this.formData.scriptForm.scriptPlacement =
						script.scriptPlacementTypeId
					this.formData.scriptForm.headOptInScript = script.headOptInScript
					this.formData.scriptForm.headOptOutScript = script.headOptOutScript
					this.formData.scriptForm.bodyOptInDiv = script.bodyOptInScriptDivId
					this.formData.scriptForm.bodyOptOutDiv = script.bodyOptOutScriptDivId
					this.formData.scriptForm.bodyOptInScript = script.bodyOptInScript
					this.formData.scriptForm.bodyOptOutScript = script.bodyOptOutScript
					this.formData.scriptForm.createdByName = script.createdByName
					this.formData.scriptForm.lastEditedByName = script.lastEditedByName
					this.reformatDate(script.createdDate, script.lastEditedDate)
					this.preventScrolling()
					this.showScriptModal = true
				})
				.catch(errors => {
					this.errors = errors
				})
		},
		updateScript (form) {
			const essential = form.type === 'Essential'
			const legitimateInterest = form.type === 'Legitimate Interest'
			let cookiesInScript = form.cookiesInScript.join()
			cookiesInScript = cookiesInScript.toString()

			const headScriptPlacement = 1
			const bodyScriptPlacement = 2

			if (form.scriptPlacement === headScriptPlacement) {
				form.bodyOptInScript = ''
				form.bodyOptInDiv = ''
				form.bodyOptOutScript = ''
				form.bodyOptOutDiv = ''
			} else if (form.scriptPlacement === bodyScriptPlacement) {
				form.headOptInScript = ''
				form.headOptOutScript = ''
			}

			const updatedScript = {
				cookieScriptChannelId: form.cookieScriptChannelId,
				scriptName: form.name,
				scriptDescription: form.html,
				brandId: form.brand,
				isStrictlyNecessary: essential,
				displayText: form.html,
				parentCookieCategoryId: form.category,
				cookieInfoUrl: form.informationUrl,
				optInScript: form.headOptInScript,
				optInScriptBody: form.bodyOptInScript,
				optInDivBody: form.bodyOptInDiv,
				scriptExpiryInDays: form.scriptExpiry,
				consentExpiryInDays: form.consentExpiry,
				browserCookieNames: cookiesInScript,
				isRunFirst: form.isRunFirst,
				gpcEnabled: form.gpcEnabled,
				optOutScript: form.headOptOutScript,
				optOutScriptBody: form.bodyOptOutScript,
				optOutDivBody: form.bodyOptOutDiv,
				isLegitimateInterest: legitimateInterest,
				scriptPlacementTypeId: form.scriptPlacement
			}
			this.updateCookieScript(updatedScript)

			const oldRunFirst = this.formData.scriptForm.isRunFirst
			const newRunFirst = updatedScript.isRunFirst

			if (oldRunFirst !== newRunFirst) {
				this.updateRunOrderTable(updatedScript, this.formData.scriptForm.type)
			}
			// we need to update the script in the selectedScripts array

			const scriptInSelected = this.formData.selectedScripts.find(script => {
				return (
					script.cookieScriptChannelId === updatedScript.cookieScriptChannelId
				)
			})
			scriptInSelected.cookieScriptName = updatedScript.scriptName
			scriptInSelected.isRunFirst = updatedScript.isRunFirst
			scriptInSelected.gpcEnabled = updatedScript.gpcEnabled
			scriptInSelected.cookieCategoryId = updatedScript.parentCookieCategoryId

			this.showScriptModal = false
			this.allowScrolling()
			this.showSnackbar('Script - ' + form.name + ' updated successfully')

			this.isValid = true
			this.forceReactivity()
			this.clearScriptForm()
		},
		reformatDate (createdDate, lastEditedDate) {
			// created date

			let formattedCreatedDate = new Date(createdDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedCreatedDate.setHours(formattedCreatedDate.getHours() + 8)
			formattedCreatedDate = formattedCreatedDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.scriptForm.createdDate = formattedCreatedDate

			// edited date
			let formattedLastEditDate = new Date(lastEditedDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedLastEditDate.setHours(formattedLastEditDate.getHours() + 8)
			formattedLastEditDate = formattedLastEditDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.scriptForm.lastEditedDate = formattedLastEditDate
		},
		convertNamesToArray (cookieNames) {
			this.formData.scriptForm.cookiesInScript = []
			// singuilar get returns empty string if no names
			if (cookieNames.length !== 0) {
				const cookieArray = cookieNames.split(',')
				this.formData.scriptForm.cookiesInScript = cookieArray
			}
		},
		closeCreateScriptModal () {
			this.showScriptModal = false
			this.allowScrolling()
			this.clearScriptForm()
		},
		async createNewScript (form) {
			const essential = form.type === 'Essential'
			const legitimateInterest = form.type === 'Legitimate Interest'
			let cookiesInScript = form.cookiesInScript.join()
			cookiesInScript = cookiesInScript.toString()

			const headScriptPlacement = 1
			const bodyScriptPlacement = 2

			if (form.scriptPlacement === headScriptPlacement) {
				form.bodyOptInScript = ''
				form.bodyOptInDiv = ''
				form.bodyOptOutScript = ''
				form.bodyOptOutDiv = ''
			} else if (form.scriptPlacement === bodyScriptPlacement) {
				form.headOptInScript = ''
				form.headOptOutScript = ''
			}

			const newScript = {
				script: {
					scriptName: form.name,
					scriptDescription: form.html,
					brandId: form.brand,
					isStrictlyNecessary: essential,
					displayText: form.html,
					parentCookieCategoryId: form.category,
					cookieInfoUrl: form.informationUrl,
					optInScript: form.headOptInScript,
					optInScriptBody: form.bodyOptInScript,
					optInDivBody: form.bodyOptInDiv,
					scriptExpiryInDays: form.scriptExpiry,
					consentExpiryInDays: form.consentExpiry,
					browserCookieNames: cookiesInScript,
					isRunFirst: form.isRunFirst,
					gpcEnabled: form.gpcEnabled,
					optOutScript: form.headOptOutScript,
					optOutScriptBody: form.bodyOptOutScript,
					optOutDivBody: form.bodyOptOutDiv,
					isLegitimateInterest: legitimateInterest,
					scriptPlacementTypeId: form.scriptPlacement
				},
				type: form.type
			}

			await this.createCookieScript(newScript)
				.then(() => {
					this.showScriptModal = false
					this.showSnackbar('New Cookie Script successfully created')
					// Reset form and close overlay
					this.addScriptToBanner(newScript)
					this.clearScriptForm()
				})
				.catch(error => {
					this.showSnackbar({
						text: 'Error creating new Cookie Script - ' + error,
						color: 'red'
					})
				})
				.finally(() => {
					this.allowScrolling()
				})
		},
		clearScriptForm () {
			this.formData.scriptForm.id = null
			this.formData.scriptForm.type = ''
			this.formData.scriptForm.brand = Number
			this.formData.scriptForm.category = Number
			this.formData.scriptForm.name = ''
			this.formData.scriptForm.isRunFirst = false
			this.formData.scriptForm.gpcEnabled = false
			this.formData.scriptForm.html = ''
			this.formData.scriptForm.informationUrl = ''
			this.formData.scriptForm.cookieInScriptField = ''
			this.formData.scriptForm.cookiesInScript = []
			this.formData.scriptForm.scriptExpiry = 0
			this.formData.scriptForm.consentExpiry = 0
			this.formData.scriptForm.scriptPlacement = '<head>'
			this.formData.scriptForm.optInScript = ''
			this.formData.scriptForm.optOutScript = ''
			this.formData.scriptForm.bodyOptInDiv = ''
			this.formData.scriptForm.bodyOptInScript = ''
			this.formData.scriptForm.bodyOptOutDiv = ''
			this.formData.scriptForm.bodyOptOutScript = ''
			this.formData.scriptForm.createdByName = ''
			this.formData.scriptForm.createdDate = ''
			this.formData.scriptForm.editedByName = ''
			this.formData.scriptForm.lastEditedDate = ''
		},
		addScriptToBanner (selectedScript) {
			// new script
			if (selectedScript.script) {
				const newScript = {
					cookieCategoryId: selectedScript.script.parentCookieCategoryId,
					cookieCategoryName: selectedScript.script.cookieCategoryName,
					cookieScriptId: selectedScript.script.cookieScriptId,
					cookieScriptChannelId: selectedScript.script.cookieScriptChannelId,
					cookieScriptName: selectedScript.script.cookieScriptName,
					gpcEnabled: selectedScript.script.gpcEnabled,
					type: selectedScript.script.cookieScriptType,
					cookiesInScript: selectedScript.script.browserCookieNames.split(','),
					isRunFirst: selectedScript.script.isRunFirst,
					displayOrder: (this.formData.selectedScripts.filter(
						script =>
							script.cookieCategoryId ===
							selectedScript.script.parentCookieCategoryId
					).length += 1)
				}
				this.formData.selectedScripts.push(newScript)
				this.addScriptToRunFirstTable(newScript)
			} else {
				const selectedScripts = this.selectedCookieScripts.map(selectedScript => ({
					cookieCategoryId: selectedScript.cookieCategoryId,
					cookieCategoryName: selectedScript.cookieCategoryName,
					cookieScriptId: selectedScript.cookieScriptId,
					cookieScriptChannelId: selectedScript.cookieScriptChannelId,
					cookieScriptName: selectedScript.cookieScriptName,
					gpcEnabled: selectedScript.gpcEnabled,
					type: selectedScript.cookieScriptType,
					isRunFirst: selectedScript.isRunFirst,
					cookiesInScript: selectedScript.namesOfCookiesInScript.split(','),
					displayOrder: (this.formData.selectedScripts.filter(
						script =>
							script.cookieCategoryId === selectedScript.cookieCategoryId
					).length += 1)
				}))
				selectedScripts.forEach(selectedScript => {
					selectedScript.displayOrder = (this.formData.selectedScripts.filter(
						script =>
							script.cookieCategoryId === selectedScript.cookieCategoryId
					).length += 1)
					this.formData.selectedScripts.push(selectedScript)
					this.addScriptToRunFirstTable(selectedScript)
				})
				this.selectAllChecked = false
			}

			this.formData.selectedScript = {}
			this.isValid = true
			this.selectedCookieScripts = []
			this.forceReactivity()
		},
		toggleSelectAll (availableScripts) {
			if (this.selectedCookieScripts.length === availableScripts.length) {
				this.selectAllChecked = false
				this.selectedCookieScripts = []
			} else {
				this.selectAllChecked = true
				this.selectedCookieScripts = availableScripts
			}
		},
		updateSelectAllChecked (availableScripts) {
			if (this.selectedCookieScripts.length !== availableScripts.length) {
				this.selectAllChecked = false
			} else {
				this.selectAllChecked = true
			}
		},
		showSelectAll (categoryId) {
			const availableScripts = this.availableCookieScripts.filter(({ cookieCategoryId }) => cookieCategoryId === categoryId)
			const allScripts = this.cookieScripts.filter(({ cookieCategoryId }) => cookieCategoryId === categoryId)
			return availableScripts.length === allScripts.length
		},
		moveScript (script, direction) {
			const matchingCategory = this.formData.selectedScripts.filter(
				category => {
					return category.cookieCategoryId === script.cookieCategoryId
				}
			)
			if (direction === 'moveUp') {
				const previousScript = matchingCategory.find(previousScript => {
					return previousScript.displayOrder === script.displayOrder - 1
				})
				script.displayOrder -= 1
				previousScript.displayOrder += 1
				const scriptInSelected = this.formData.selectedScripts.find(
					selectedScript => {
						return (
							selectedScript.cookieScriptChannelId ===
							script.cookieScriptChannelId
						)
					}
				)
				scriptInSelected.displayOrder -= 1
			} else if (direction === 'moveDown') {
				const nextScript = matchingCategory.find(nextScript => {
					return nextScript.displayOrder === script.displayOrder + 1
				})
				nextScript.displayOrder -= 1
				script.displayOrder += 1

				// find matching script in selected script
				const scriptInSelected = this.formData.selectedScripts.find(
					selectedScript => {
						return (
							selectedScript.cookieScriptChannelId ===
							script.cookieScriptChannelId
						)
					}
				)
				scriptInSelected.displayOrder += 1
			}
		},

		deleteScriptFromConsentBanner (script) {
			// below is breaking the display order

			const scriptInSelected = this.formData.selectedScripts.find(
				selectedScript => {
					return selectedScript.cookieScriptId === script.cookieScriptId
				}
			)

			this.formData.selectedScripts.forEach(selectedScript => {
				if (selectedScript.runOrder > scriptInSelected.runOrder) {
					selectedScript.runOrder -= 1
				}
			})

			this.formData.deletedScripts.push(script)
			const deletedScript = this.formData.selectedScripts.findIndex(
				selectedScript =>
					selectedScript.cookieScriptId === script.cookieScriptId
			)

			// update display order for matching categories after the deleted to reduce by 1
			this.formData.selectedScripts.forEach(scr => {
				if (
					scr.cookieCategoryId === script.cookieCategoryId &&
					scr.displayOrder > script.displayOrder
				) {
					scr.displayOrder -= 1
				}
			})
			const matchingCisScripts = this.formData.selectedCisCookies.filter(
				cookie => {
					return cookie.cookieScriptName === script.cookieScriptName
				}
			)

			this.formData.selectedScripts.splice(deletedScript, 1)

			matchingCisScripts.forEach(cisCookie => {
				const matchingCookie = this.formData.selectedCisCookies.find(
					cookie => {
						return (
							cookie.cookieNameInBrowser === cisCookie.cookieNameInBrowser &&
							cookie.cookieScriptName === cisCookie.cookieScriptName
						)
					}
				)
				const cisIndex = this.formData.selectedCisCookies.indexOf(
					matchingCookie
				)
				this.formData.selectedCisCookies.splice(cisIndex, 1)
			})
		},
		populateCookiesInScript (selectedCisScript) {
			if (selectedCisScript.cookiesInScript[0] !== '') {
				this.formData.cookiesInScript = selectedCisScript.cookiesInScript
			}
		},
		addCisCookieToBanner (selectedCisScript) {
			const cisCookie = {
				cookieScriptName: selectedCisScript.cookieScriptName,
				cookieNameInBrowser: this.formData.selectedCisCookie,
				type: selectedCisScript.type
			}
			selectedCisScript.cookieNameInBrowser = this.formData.selectedCisCookie
			this.formData.selectedCisCookies.push(cisCookie)
			this.formData.selectedCookiesInScript.push(
				this.formData.selectedCisCookie
			)
			this.formData.selectedCisScript = {}
			this.formData.selectedCisCookie = ''
			this.isValid = true
		},
		deleteCisCookie (cisCookie) {
			const deletedCisCookie = this.formData.selectedCisCookies.findIndex(
				deletedCisCookie =>
					deletedCisCookie.cookieScriptId === cisCookie.cookieScriptId
			)
			this.formData.selectedCisCookies.splice(deletedCisCookie, 1)

			const deletedSelectedCookie = this.formData.selectedCookiesInScript.findIndex(
				deletedScript => deletedScript === cisCookie.cookieNameInBrowser
			)
			this.formData.selectedCookiesInScript.splice(deletedSelectedCookie, 1)
		},
		addScriptToRunFirstTable (script) {
			const essentialRunFirstScripts = this.formData.selectedScripts.filter(
				essentialScript =>
					essentialScript.type === 'Essential' &&
					essentialScript.isRunFirst === true
			)
			const essentialNonRunFirstScripts = this.formData.selectedScripts.filter(
				essentialScript =>
					essentialScript.type === 'Essential' &&
					essentialScript.isRunFirst === false
			)
			const allEssentialScripts = this.formData.selectedScripts.filter(
				essentialScript => essentialScript.type === 'Essential'
			)
			const otherRunFirstScripts = this.formData.selectedScripts.filter(
				essentialScript =>
					essentialScript.type !== 'Essential' &&
					essentialScript.isRunFirst === true
			)
			const otherNonRunFirstScripts = this.formData.selectedScripts.filter(
				essentialScript =>
					essentialScript.type !== 'Essential' &&
					essentialScript.isRunFirst === false
			)
			script.runOrder = this.formData.selectedScripts.length

			const essentialRunFirst =
				script.isRunFirst === true && script.type === 'Essential'
			const essentialNonRunFirst =
				script.isRunFirst === false && script.type === 'Essential'
			const otherRunFirst =
				script.isRunFirst === true && script.type !== 'Essential'

			// if essential run first, run order needs to be 1 and increment all other scripts by 1
			if (essentialRunFirst) {
				this.formData.selectedScripts.forEach(existingScript => {
					existingScript.runOrder += 1
				})
				script.runOrder = 1
				// if essential but not run first, run order needs to be 1 more than the last run first increment all other scripts (excluding essential run first) by 1
			} else if (essentialNonRunFirst) {
				essentialNonRunFirstScripts.forEach(existingScript => {
					existingScript.runOrder += 1
				})
				otherRunFirstScripts.forEach(existingScript => {
					existingScript.runOrder += 1
				})
				otherNonRunFirstScripts.forEach(existingScript => {
					existingScript.runOrder += 1
				})

				script.runOrder = essentialRunFirstScripts.length + 1
				// if other run first, run order order is last essential script run order + 1 increment all other scripts by 1
			} else if (otherRunFirst) {
				otherRunFirstScripts.forEach(existingScript => {
					existingScript.runOrder += 1
				})
				otherNonRunFirstScripts.forEach(existingScript => {
					existingScript.runOrder += 1
				})
				script.runOrder = allEssentialScripts.length + 1
			} else {
				script.runOrder = this.formData.selectedScripts.length
			}
		},
		updateRunOrderTable (script, type) {
			const matchingScript = this.formData.selectedScripts.find(match => {
				return match.cookieScriptChannelId === script.cookieScriptChannelId
			})
			const allEssentialScripts = this.formData.selectedScripts.filter(
				essentialScript => essentialScript.type === 'Essential'
			)

			const essentialRunFirst =
				script.isRunFirst === true && type === 'Essential'
			const essentialNonRunFirst =
				script.isRunFirst === false && type === 'Essential'
			const otherRunFirst = script.isRunFirst === true && type !== 'Essential'
			//
			if (essentialRunFirst) {
				this.formData.selectedScripts.forEach(existingScript => {
					if (existingScript.runOrder < matchingScript.runOrder) {
						existingScript.runOrder += 1
					}
				})
				matchingScript.runOrder = 1
			} else if (essentialNonRunFirst) {
				// decrease essential scripts that have a greater run order by 1 and put matchign script run order as the length of essential scripts
				allEssentialScripts.forEach(existingScript => {
					if (existingScript.runOrder > matchingScript.runOrder) {
						existingScript.runOrder -= 1
					}
				})
				matchingScript.runOrder = allEssentialScripts.length
			} else if (otherRunFirst) {
				// all scripts with greater run order than essential (allEssentialScripts.length) where the run order is less than the matching script - increase by 1
				this.formData.selectedScripts.forEach(existingScript => {
					if (
						existingScript.runOrder > allEssentialScripts.length &&
						existingScript.runOrder < matchingScript.runOrder
					) {
						existingScript.runOrder += 1
					}
				})

				// matching script run order is essential scripts.length + 1
				matchingScript.runOrder = allEssentialScripts.length + 1
			} else {
				this.formData.selectedScripts.forEach(existingScript => {
					if (existingScript.runOrder > matchingScript.runOrder) {
						existingScript.runOrder -= 1
					}
				})
				matchingScript.runOrder = this.formData.selectedScripts.length
			}
		},
		changeRunOrder (script, direction) {
			if (direction === 'moveUp') {
				// get previous script
				const previousScript = this.formData.selectedScripts.find(
					previous => {
						return previous.runOrder === script.runOrder - 1
					}
				)
				script.runOrder -= 1
				previousScript.runOrder += 1
			} else {
				// get next script
				const nextScript = this.formData.selectedScripts.find(next => {
					return next.runOrder === script.runOrder + 1
				})
				script.runOrder += 1
				nextScript.runOrder -= 1
			}
			this.forceReactivity()
		},
		forceReactivity () {
			this.selectedScripts()
			// fix reactivity in vue by triggering an array function
			this.formData.selectedScripts = this.formData.selectedScripts.map(
				x => x
			)
		},
		nextStep () {
			if (!this.formUpdated) {
				this.populateForm()
			}
			this.$emit('next')
			this.formUpdated = false
		},
		backToBanner () {
			if (!this.formUpdated) {
				this.populateForm()
			}
			this.$emit('back')
			this.formUpdated = false
		}
	}
}
</script>
