<template>
	<!-- eslint-disable max-lines -->
	<div>
		<ValidationForm
			#default="{ handleSubmit }"
			ref="validationForm"
		>
			<div class="cassie-vertical-md">
				<div
					v-if="formData.createdDate"
					class="text-caption"
				>
					Created By: {{ formData.createdByName }}
					{{ formData.createdDate }}
					<span
						v-if="lastSavedStep >= currentStep"
					>
						| Last Edited By: {{ formData.editedByName }} {{ formData.lastEditedDate }}
					</span>
				</div>
				<SectionCard>
					<template #title>
						Pre-Banner
					</template>
					<template #subtitle>
						When your Consent Banner is first displayed to a user it will
						appear as a Pre-Banner. This gives the user the ability to
						accept all cookies, reject all cookies or to open the Consent
						Banner in order to customise their consent.
					</template>
					<template
						#title-action
					>
						<Toggle
							class="mr-2"
							:disabled="!userCanCreateUpdate"
							:value.sync="formData.enablePreBanner"
							label="Show"
							label-left
						/>
					</template>
					<template #body>
						<div
							class="d-flex flex-row cassie-horizontal-sm"
						>
							<div
								class="px-2 pt-2 cassie-input-width-md "
							>
								<div class="text-subtitle-2">
									Appearance*
								</div>
								<RadioGroup
									:radio-group-value.sync="formData.preBannerLocation"
									:disabled="!userCanCreateUpdate"
									:radios="preBannerLocationRadios"
									image-radio-group
								/>
							</div>
							<v-divider vertical />
							<div
								class="px-2 pt-2 cassie-input-width-md cassie-vertical-md"
							>
								<div class="text-subtitle-2">
									Colour Scheme*
								</div>
								<RadioGroup
									:radio-group-value.sync="formData.selectedColorScheme"
									:disabled="!userCanCreateUpdate"
									:radios="preBannerColourSchemeRadios"
								/>
								<div
									v-if="isCustomScheme"
									class="cassie-pre-banner-custom-color-container"
								>
									<v-row>
										<v-col cols="12">
											<v-menu
												v-for="customiseButton in formData.customiseButtons"
												:key="customiseButton.id"
												offset-y
												:close-on-content-click="false"
												attach
											>
												<template #activator="{ on }">
													<div class="mb-2 d-flex d-inline-flex cassie-customise-button-container cassie-input-width-md">
														<v-btn
															max-width="30"
															max-height="30"
															elevation="1"
															fab
															class="mr-2"
															:color="customiseButton.color"
															v-on="on"
															@click="openColourPicker(customiseButton)"
														/>
														<span class="text-caption mt-1">
															{{
																customiseButton.text
															}}
														</span>
													</div>
												</template>
												<div class="cassie-colour-picker-container">
													<v-color-picker
														v-model="pickerColour"
														class="mx-auto"
														mode="hexa"
														hide-mode-switch
														@input="getColour($event, customiseButton)"
													/>
												</div>
											</v-menu>
										</v-col>
									</v-row>
								</div>
							</div>
						</div>
						<div
							class="d-flex flex-row cassie-horizontal-sm"
						>
							<FroalaEditor
								v-if="
									userCanCreateUpdate &&
										isEditMode && !loading
								"
								v-model="formData.preBannerText"
								class="cassie-input-width-xl"
								:rules="{required: true, max: 2000}"
								title="Pre-Banner Text*"
							/>
							<div
								v-else
								v-dompurify-html="formData.preBannerText"
								class="mt-2 cassie-disabled-textarea cassie-input-width-xl rounded-lg"
							/>
						</div>
						<div
							class="d-flex flex-row cassie-horizontal-sm"
						>
							<v-expansion-panels accordion>
								<v-expansion-panel>
									<v-expansion-panel-header>
										<span>
											Show Advanced Options
										</span>
									</v-expansion-panel-header>
									<v-expansion-panel-content
										class="pt-0"
									>
										<v-card
											flat
											color="grey lighten-5"
											class="cassie-horizontal-sm d-flex pa-3"
										>
											<div
												class="d-flex flex-column cassie-vertical-sm"
												style="width: 33%;"
											>
												<div
													class="text-subtitle-2"
												>
													Show 'Accept All' button
												</div>
												<RadioGroup
													:radio-group-value.sync="formData.showAcceptAllButton"
													:disabled="!userCanCreateUpdate"
													:radios="acceptAllRadios"
												/>
												<TextField
													v-if="formData.showAcceptAllButton"
													v-model="formData.acceptAllButtonText"
													:disabled="!userCanCreateUpdate"
													label="Accept All Button Text"
													:rules="{required: true, max: 50}"
												/>
											</div>
											<v-spacer />
											<div
												class="d-flex flex-column cassie-vertical-sm"
												style="width: 33%;"
											>
												<div
													class="text-subtitle-2"
												>
													Show 'Reject All' button
												</div>
												<RadioGroup
													:radio-group-value.sync="formData.showRejectAllButton"
													:disabled="!userCanCreateUpdate"
													:radios="rejectAllRadios"
												/>
												<TextField
													v-if="formData.showRejectAllButton"
													v-model="formData.rejectAllButtonText"
													:disabled="!userCanCreateUpdate"
													label="Reject All Button Text"
													:rules="{required: true, max: 50}"
												/>
											</div>
											<v-spacer />
											<div
												class="d-flex flex-column cassie-vertical-sm"
												style="width: 33%;"
											>
												<div
													class="text-subtitle-2"
												>
													Show 'Cookie Settings' button
												</div>
												<RadioGroup
													:radio-group-value.sync="formData.showCustomiseButton"
													:disabled="!userCanCreateUpdate"
													:radios="customiseButtonRadios"
												/>
												<TextField
													v-if="formData.showCustomiseButton"
													v-model="formData.customiseButtonText"
													:disabled="!userCanCreateUpdate"
													outlined
													dense
													label="Cookie Settings Button Text"
													:rules="{required: true, max: 50}"
												/>
											</div>
										</v-card>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</div>
					</template>
				</SectionCard>
				<!-- GPC pre banner card (only ) -->
				<GpcPreBannerCard
					v-if="globalGpcEnabled"
					:form-data.sync="gpcPreBannerFormData"
					:gpc-banner-content-title.sync="gpcPreBannerFormData.gpcBannerContentTitle"
					:gpc-banner-content.sync="gpcPreBannerFormData.gpcBannerContent"
					:accept-all-cookies-button-text.sync="gpcPreBannerFormData.acceptAllCookiesButtonText"
					:accept-all-cookies-exc-gpc-button-text.sync="gpcPreBannerFormData.acceptAllCookiesExcGpcButtonText"
					:back-button-text.sync="gpcPreBannerFormData.backButtonText"
					:full-access-permission="userCanCreateUpdate"
				/>
				<SectionCard>
					<template #title>
						Edit Cookie Selection Button
					</template>
					<template #subtitle>
						Enabling this feature will display a button which allows
						users to edit their Cookie Settings when revisiting the
						page.
					</template>
					<template
						#title-action
					>
						<Toggle
							class="mr-2"
							:disabled="!userCanCreateUpdate"
							:value.sync="formData.enableCookieSelectionButton"
							label-left
							label="Enabled"
						/>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						Preview
					</template>
					<template #body>
						<CookiePrebannerPreview
							v-if="
								formData.enablePreBanner || formData.enableCookieSelectionButton || globalGpcEnabled
							"
							:appearance="selectedAppearance"
							:pre-banner-text="formData.preBannerText"
							:hide-accept-all="!formData.showAcceptAllButton"
							:accept-all-text="formData.acceptAllButtonText"
							:hide-reject-all="!formData.showRejectAllButton"
							:reject-all-text="formData.rejectAllButtonText"
							:hide-view-options="!formData.showCustomiseButton"
							:view-options-text="formData.customiseButtonText"
							:colour-scheme="formData.selectedColourSchemeData"
							:gpc-banner-data="gpcPreBannerFormData"
							:gpc-appearance="selectedGpcAppearance"
							:hide-cookie-select="!formData.enableCookieSelectionButton"
							:hide-pre-banner-tab="!formData.enablePreBanner"
							:hide-gpc-banner-tab="!globalGpcEnabled"
						/>
					</template>
				</SectionCard>
			</div>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton @click="cancelPreBanner">
						Exit
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton
						v-if="lastSavedStep >= currentStep && userCanCreateUpdate"
						class="mr-2"
						@click="handleSubmit(proceedFromPreBanner)"
					>
						Save Changes
					</PrimaryActionButton>
					<SecondaryActionButton @click="backToDetails">
						Back
					</SecondaryActionButton>
					<SecondaryActionButton
						v-if="lastSavedStep >= currentStep"
						class="ml-2"
						@click="nextStep"
					>
						Next
					</SecondaryActionButton>
					<PrimaryActionButton
						v-else-if="userCanCreateUpdate"
						class="ml-2"
						@click="handleSubmit(proceedFromPreBanner)"
					>
						Save and Continue
					</PrimaryActionButton>
				</template>
			</PageActionRow>
		</ValidationForm>

		<v-bottom-sheet v-model="sheet">
			<v-sheet
				class="text-center"
				height="200px"
			>
				<v-btn
					class="mt-6"
					color="red white--text"
					append
					@click="sheet = !sheet"
				>
					close
					<v-icon right>
						mdi-close
					</v-icon>
				</v-btn>
				<div class="py-3">
					<v-alert
						class="my-2 mx-10"
						light
						border="bottom"
						colored-border
						type="warning"
						elevation="2"
					>
						We currently do not hold a colour scheme for your pre-banner please
						review carefully and make a selection as when you move from this
						page it will be applied to your pre-banner.
					</v-alert>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
/* eslint-disable max-lines */
import { mapActions, mapGetters } from 'vuex'
import FroalaEditor from '../../../../../shared/froala-editor.vue'
import { showSnackbar } from '../../../../../../../../shared/state/snackbar.js'
import ValidationForm from '../../../../../../../../shared/components/validation-form.vue'
import SectionCard from '../../../../../../../../shared/components/section-card.vue'
import PageActionRow from '../../../../../../../../shared/components/page-action-row.vue'
import Toggle from '../../../../../../../../shared/components/toggle.vue'
import PrimaryActionButton from '../../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../../shared/components/secondary-action-button.vue'
import TextField from '../../../../../../../../shared/components/text-field.vue'
import { getConsentBannerConfigurationStatus } from '../../../../../../services/ConsentBannerService.js'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS } from '../../../../../../../../shared/permissions/admin-portal-permissions.js'
import CookiePrebannerPreview from '../cookie-banner-preview/cookie-prebanner-preview.vue'
import { preBannerLocationRadios, PRE_BANNER_COLOUR_SCHEMES, preBannerColourSchemeRadios, acceptAllRadios, rejectAllRadios, customiseButtonRadios } from './configure-cookie-banner-pre-banner-enums.js'
import RadioGroup from '../../../../../../../../shared/components/radio-group.vue'
import GpcPreBannerCard from './gpc-pre-banner-card.vue'
const preBannerPositions = {
	top: 0,
	centre: 1,
	bottom: 2
}
const defaultPrebannerText =
	'We use cookies on our website. Some are necessary for our site to function, while others are optional but help improve your experience.'
const defaultGpcBannerTitle = 'GPC Detected'
const defaultGpcBannerText = 'We have detected a Global Privacy Control (GPC) signal, please confirm if you wish to accept all cookies including or excluding those flagged as GPC.'
const defaultGpcAcceptAllButtonText = 'Accept All Cookies'
const defaultGpcAcceptAllExcGpcButtonText = 'Accept All Excluding GPC'
const defaultGpcBackButtonText = 'Back'
const defaultCustomScheme = {
	BackgroundColour: '#5D707F',
	SecondaryColour: '#6D8A96',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#66CED6',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#A7A5C6',
	RejectAllButtonTextColour: '#FFFFFF',
	CustomiseAllButtonColour: '#8797B2',
	CustomiseAllButtonTextColour: '#FFFFFF'
}
const defaultGpcCustomScheme = {
	BackgroundColour: '#5D707F',
	SecondaryColour: '#6D8A96',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#66CED6',
	AcceptAllButtonTextColour: '#FFFFFF',
	AcceptAllExcGpcButtonColour: '#e31f26',
	AcceptAllExcGpcButtonTextColour: '#FFFFFF',
	BackButtonColour: '#e31f26',
	BackButtonTextColour: '#FFFFFF'
}
const colourSchemeEnum = {
	dark: 0,
	light: 1,
	custom: 2
}
const darkColourScheme = {
	identifier: colourSchemeEnum.dark,
	BackgroundColour: '#2e2e37',
	SecondaryColour: '#4f4f50',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#e31f26',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#e31f26',
	RejectAllButtonTextColour: '#FFFFFF',
	CustomiseAllButtonColour: '#e31f26',
	CustomiseAllButtonTextColour: '#FFFFFF'
}
const lightColourScheme = {
	identifier: colourSchemeEnum.light,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#4f4f50',
	TextColour: '#000000',
	AcceptAllButtonColour: '#0679ab',
	AcceptAllButtonTextColour: '#000000',
	RejectAllButtonColour: '#0679ab',
	RejectAllButtonTextColour: '#000000',
	CustomiseAllButtonColour: '#0679ab',
	CustomiseAllButtonTextColour: '#000000'
}
const darkGpcColourScheme = {
	identifier: colourSchemeEnum.dark,
	BackgroundColour: '#2e2e37',
	SecondaryColour: '#4f4f50',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#e31f26',
	AcceptAllButtonTextColour: '#FFFFFF',
	AcceptAllExcGpcButtonColour: '#e31f26',
	AcceptAllExcGpcButtonTextColour: '#FFFFFF',
	BackButtonColour: '#e31f26',
	BackButtonTextColour: '#FFFFFF'
}
const lightGpcColourScheme = {
	identifier: colourSchemeEnum.light,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#4f4f50',
	TextColour: '#000000',
	AcceptAllButtonColour: '#0679ab',
	AcceptAllButtonTextColour: '#000000',
	AcceptAllExcGpcButtonColour: '#0679ab',
	AcceptAllExcGpcButtonTextColour: '#000000',
	BackButtonColour: '#0679ab',
	BackButtonTextColour: '#000000'
}
export default {
	components: {
		ValidationForm,
		FroalaEditor,
		SectionCard,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		CookiePrebannerPreview,
		TextField,
		Toggle,
		RadioGroup,
		GpcPreBannerCard
	},
	props: {
		preBanner: {
			type: Object,
			default: null
		}
	},
	setup () {
		return {
			showSnackbar,
			preBannerLocationRadios,
			preBannerColourSchemeRadios,
			acceptAllRadios,
			rejectAllRadios,
			customiseButtonRadios,
			PRE_BANNER_COLOUR_SCHEMES
		}
	},
	data () {
		return {
			currentStep: 2,
			colourSchemes: [darkColourScheme, lightColourScheme],
			colourSchemeEnum: colourSchemeEnum,
			gpcColourSchemes: [darkGpcColourScheme, lightGpcColourScheme],
			formData: {
				selectedColorScheme: colourSchemeEnum.dark,
				enablePreBanner: true,
				preBannerLocation: preBannerPositions.bottom,
				selectedColourSchemeData: darkColourScheme,
				preBannerText: defaultPrebannerText,
				showAdvancedOptions: false,
				showAcceptAllButton: true,
				showRejectAllButton: true,
				showCustomiseButton: true,
				acceptAllButtonText: 'Accept All',
				rejectAllButtonText: 'Reject All',
				customiseButtonText: 'Cookie Settings',
				customiseButtons: [
					{
						id: 'BackgroundColour',
						text: 'Background Colour',
						color: defaultCustomScheme.BackgroundColour
					},
					{
						id: 'TextColour',
						text: 'Text Colour',
						color: defaultCustomScheme.TextColour
					},
					{
						id: 'AcceptAllButtonColour',
						text: 'Accept All Button Colour',
						color: defaultCustomScheme.AcceptAllButtonColour
					},
					{
						id: 'AcceptAllButtonTextColour',
						text: 'Accept All Button Text Colour',
						color: defaultCustomScheme.AcceptAllButtonTextColour
					},
					{
						id: 'RejectAllButtonColour',
						text: 'Reject All Button Colour',
						color: defaultCustomScheme.RejectAllButtonColour
					},
					{
						id: 'RejectAllButtonTextColour',
						text: 'Reject All Button Text Colour',
						color: defaultCustomScheme.RejectAllButtonTextColour
					},
					{
						id: 'CustomiseAllButtonColour',
						text: 'Customise Button Colour',
						color: defaultCustomScheme.CustomiseAllButtonColour
					},
					{
						id: 'CustomiseAllButtonTextColour',
						text: 'Customise Button Text Colour',
						color: defaultCustomScheme.CustomiseAllButtonTextColour
					},
					{
						id: 'SecondaryColour',
						text: 'Secondary Colour',
						color: defaultCustomScheme.SecondaryColour
					}
				],
				enableCookieSelectionButton: false,
				createdByName: '',
				editedByName: '',
				createdDate: '',
				lastEditedDate: ''
			},
			gpcPreBannerFormData: {
				consentBannerId: null,
				gpcBannerContentTitle: defaultGpcBannerTitle,
				gpcBannerContent: defaultGpcBannerText,
				acceptAllCookiesButtonText: defaultGpcAcceptAllButtonText,
				acceptAllCookiesExcGpcButtonText: defaultGpcAcceptAllExcGpcButtonText,
				backButtonText: defaultGpcBackButtonText,
				hideAcceptAllButton: false,
				hideacceptAllCookiesExcGpcButton: false,
				hideBackButton: false,
				gpcBannerLocation: preBannerPositions.bottom,
				colourSchemeJson: darkColourScheme,
				selectedColorScheme: colourSchemeEnum.dark,
				customiseButtons: [
					{
						id: 'BackgroundColour',
						text: 'Background Colour',
						color: defaultGpcCustomScheme.BackgroundColour
					},
					{
						id: 'TextColour',
						text: 'Text Colour',
						color: defaultGpcCustomScheme.TextColour
					},
					{
						id: 'AcceptAllButtonColour',
						text: 'Accept All Button Colour',
						color: defaultGpcCustomScheme.AcceptAllButtonColour
					},
					{
						id: 'AcceptAllButtonTextColour',
						text: 'Accept All Button Text Colour',
						color: defaultGpcCustomScheme.AcceptAllButtonTextColour
					},
					{
						id: 'AcceptAllExcGpcButtonColour',
						text: 'Accept All Exc. GPC Button Colour',
						color: defaultGpcCustomScheme.AcceptAllExcGpcButtonColour
					},
					{
						id: 'AcceptAllExcGpcButtonTextColour',
						text: 'Accept All Exc. GPC Button Text Colour',
						color: defaultGpcCustomScheme.AcceptAllExcGpcButtonTextColour
					},
					{
						id: 'BackButtonColour',
						text: 'Back Button Colour',
						color: defaultGpcCustomScheme.BackButtonColour
					},
					{
						id: 'BackButtonTextColour',
						text: 'Back Button Text Colour',
						color: defaultGpcCustomScheme.BackButtonTextColour
					},
					{
						id: 'SecondaryColour',
						text: 'Secondary Colour',
						color: defaultGpcCustomScheme.SecondaryColour
					}
				]
			},
			showColourPicker: false,
			pickerColour: '',
			isValid: false,
			editMode: false,
			loading: true,
			sheet: false,
			saving: false,
			defaultProfileId: Number,
			formUpdated: false,
			validationRules: {
				preBannerText: [
					preBannerTextValue => !!preBannerTextValue || 'Pre Banner Display Text is required',
					preBannerTextValue => preBannerTextValue.length <= 2000 || 'Pre Banner Display Text must be less than 2000 characters'
				]
			}
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('consentBanners', ['lastSavedStep', 'configurationStatusId', 'consentBannerProfiles', 'globalGpcEnabled', 'currentBannerGpcBanner']),
		isEditMode () {
			return this.configurationStatusId > 2
		},
		isCustomScheme () {
			return this.formData.selectedColorScheme === colourSchemeEnum.custom
		},
		isGpcCustomScheme () {
			return this.gpcPreBannerFormData.selectedColorScheme === colourSchemeEnum.custom
		},
		customScheme () {
			const customiseColors = this.formData.customiseButtons.reduce(
				(obj, item) => ({ ...obj, [item.id]: item.color }),
				{}
			)
			customiseColors.identifier = colourSchemeEnum.custom
			return customiseColors
		},
		gpcCustomScheme () {
			const customiseColors = this.gpcPreBannerFormData.customiseButtons.reduce(
				(obj, item) => ({ ...obj, [item.id]: item.color }),
				{}
			)
			customiseColors.identifier = colourSchemeEnum.custom
			return customiseColors
		},
		selectedAppearance () {
			switch (this.formData.preBannerLocation) {
				case 0:
					return 'top'
				case 1:
					return 'center'
				case 2:
					return 'bottom'
				default:
					return 'center'
			}
		},
		selectedGpcAppearance () {
			switch (this.gpcPreBannerFormData.gpcBannerLocation) {
				case 0:
					return 'top'
				case 1:
					return 'center'
				case 2:
					return 'bottom'
				default:
					return 'center'
			}
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		}
	},
	watch: {
		formData: {
			deep: true,
			handler (val) {
				if (!this.isCustomScheme) {
					const scheme = this.colourSchemes.find(
						scheme => scheme.identifier === this.formData.selectedColorScheme
					)
					val.selectedColourSchemeData = scheme
				} else {
					val.selectedColourSchemeData = this.customScheme
				}
				this.$emit('change', val)
			}
		},
		gpcPreBannerFormData: {
			deep: true,
			handler (val) {
				if (!this.isGpcCustomScheme) {
					const scheme = this.gpcColourSchemes.find(
						scheme => scheme.identifier === this.gpcPreBannerFormData.selectedColorScheme
					)
					val.colourSchemeJson = scheme
				} else {
					val.colourSchemeJson = this.gpcCustomScheme
				}
				this.$emit('change', val)
			}
		}
	},
	async created () {
		if (this.globalGpcEnabled) {
			await this.getGpcPreBanner(this.$route.params.cookieBannerId)
			if (this.currentBannerGpcBanner != null) {
				this.setGpcPreBannerFormData()
			}
		}
	},
	async mounted () {
		this.preventScrolling()
		// Navigating directly to a consent banner can bypass some state mutations
		// Set required values if needed
		if (this.currentConsentBannerId == null) {
			await this.setCurrentBannerId(this.$route.params.cookieBannerId)
		}
		if (this.configurationStatusId === 0) {
			const configurationStatusId = (
				await getConsentBannerConfigurationStatus(this.$route.params.cookieBannerId)
			).data.configurationStatus
			await this.setConfigurationStatusId(configurationStatusId)
		}
		this.$emit('change', this.formData)
		this.isEditMode && await this.populateForm()
		await new Promise(resolve => setTimeout(resolve, 100))
		this.allowScrolling()
		this.$refs.validationForm.reset()
	},
	methods: {
		...mapActions('consentBanners', [
			'getGpcPreBanner',
			'putGpcPreBanner',
			'getConsentBannerProfiles',
			'updateConsentBannerPreBanner',
			'publishConsentBannerProfile',
			'updateConfigurationStatusId',
			'setGlobalPreBannerHidden',
			'setCurrentBannerId',
			'setConfigurationStatusId'
		]),
		async getDefaultProfileId (consentBannerId) {
			await this.getConsentBannerProfiles(consentBannerId).then(() => {
				this.defaultProfileId = this.consentBannerProfiles.find(
					x => x.isDefault === 1
				).profileId
			})
		},
		async proceedFromPreBanner () {
			this.formUpdated = true
			const preBannerData = {
				consentBannerId: this.preBanner.consentBannerId,
				preBannerContent: this.formData.preBannerText,
				acceptAllCookiesButtonText: this.formData.acceptAllButtonText,
				rejectAllCookiesButtonText: this.formData.rejectAllButtonText,
				customiseCookiesButtonText: this.formData.customiseButtonText,
				hideRejectAllButton: !this.formData.showRejectAllButton,
				hideAcceptAllButton: !this.formData.showAcceptAllButton,
				hideCustomiseCookiesButton: !this.formData.showCustomiseButton,
				hidePreBanner: !this.formData.enablePreBanner,
				displayEditCookieSelectionButton: this.formData
					.enableCookieSelectionButton,
				preBannerLocation: this.formData.preBannerLocation,
				colourSchemeJson: JSON.stringify(
					this.formData.selectedColourSchemeData
				)
			}
			if (this.globalGpcEnabled) this.saveGpcPreBanner()
			this.preventScrolling()
			// Save to database and publish to AWS
			await this.updateConsentBannerPreBanner(preBannerData)
			const updateBannerStatusObject = {
				consentBannerId: this.$route.params.cookieBannerId,
				configurationStatusId: 3
			}
			await this.getDefaultProfileId(updateBannerStatusObject.consentBannerId)
			await this.publishConsentBannerProfile(this.defaultProfileId)
			if (this.configurationStatusId <= this.currentStep) {
				await this.updateConfigurationStatusId(updateBannerStatusObject)
			}
			await this.getGpcPreBanner(this.$route.params.cookieBannerId)
			this.setGpcPreBannerFormData()
			this.allowScrolling()
			// determine edit mode if last saved step is greater than or equal to current step
			if (this.lastSavedStep >= this.currentStep) {
				this.showSnackbar('Pre Banner updated successfully')
			} else if (this.lastSavedStep < this.currentStep) {
				this.$emit('next')
			}
			this.setGlobalPreBannerHidden(preBannerData.hidePreBanner)
		},
		async saveGpcPreBanner () {
			const gpcPreBanner = {
				consentBannerId: this.$route.params.cookieBannerId,
				gpcBannerContentTitle: this.gpcPreBannerFormData.gpcBannerContentTitle,
				gpcBannerContent: this.gpcPreBannerFormData.gpcBannerContent,
				acceptAllCookiesButtonText: this.gpcPreBannerFormData.acceptAllCookiesButtonText,
				acceptAllCookiesExcGpcButtonText: this.gpcPreBannerFormData.acceptAllCookiesExcGpcButtonText,
				backButtonText: this.gpcPreBannerFormData.backButtonText,
				hideAcceptAllButton: this.gpcPreBannerFormData.hideAcceptAllButton,
				hideAcceptAllCookiesExGpcButton: this.gpcPreBannerFormData.hideacceptAllCookiesExcGpcButton,
				hideBackButton: this.gpcPreBannerFormData.hideBackButton,
				gpcBannerLocation: this.gpcPreBannerFormData.gpcBannerLocation,
				colourSchemeJson: JSON.stringify(
					this.gpcPreBannerFormData.colourSchemeJson
				)

			}
			await this.putGpcPreBanner(gpcPreBanner)
		},
		populateForm () {
			this.formData.preBannerText =
				this.preBanner.preBannerText || defaultPrebannerText
			this.formData.acceptAllButtonText =
				this.preBanner.acceptAllCookiesText || this.formData.acceptAllButtonText
			this.formData.rejectAllButtonText =
				this.preBanner.rejectAllCookiesText || this.formData.rejectAllButtonText
			this.formData.customiseButtonText =
				this.preBanner.viewAllOptionsText || this.formData.customiseButtonText
			this.formData.showRejectAllButton = !this.preBanner.hideRejectAllButton
			this.formData.showAcceptAllButton = !this.preBanner.hideAcceptAllButton
			this.formData.showCustomiseButton = !this.preBanner.hideViewOptionsButton
			this.formData.enablePreBanner = !this.preBanner.hidePreBanner
			this.formData.enableCookieSelectionButton = this.preBanner.displayCookieSettingsButton
			this.formData.preBannerLocation = this.preBanner.selectedPreBannerAppearanceId
			this.formData.createdByName = this.preBanner.createdByName
			this.formData.editedByName = this.preBanner.editedByName
			this.reformatDate(
				this.preBanner.createdDate,
				this.preBanner.lastEditedDate
			)
			const preBannerPropScheme = JSON.parse(
				this.preBanner.preBannerColourSchemeJson
			)
			this.formData.selectedColourSchemeData = preBannerPropScheme
			this.formData.selectedColorScheme =
				preBannerPropScheme?.identifier ?? colourSchemeEnum.dark
			this.populateCustomColors(preBannerPropScheme)

			if (preBannerPropScheme === '') {
				this.sheet = true
			}
			this.loading = false
		},
		setGpcPreBannerFormData () {
			const parsedColourScheme = JSON.parse(this.currentBannerGpcBanner.gpcBannerColourSchemeJson)
			this.gpcPreBannerFormData = {
				consentBannerId: this.currentBannerGpcBanner.consentBannerId,
				gpcBannerContentTitle: this.currentBannerGpcBanner.gpcBannerTitle || defaultGpcBannerTitle,
				gpcBannerContent: this.currentBannerGpcBanner.gpcBannerText || defaultGpcBannerText,
				acceptAllCookiesButtonText: this.currentBannerGpcBanner.acceptAllCookiesText || defaultGpcAcceptAllButtonText,
				acceptAllCookiesExcGpcButtonText: this.currentBannerGpcBanner.acceptAllCookiesExcGpcButtonText || defaultGpcAcceptAllExcGpcButtonText,
				backButtonText: this.currentBannerGpcBanner.backText || defaultGpcBackButtonText,
				hideAcceptAllButton: this.currentBannerGpcBanner.hideAcceptAllButton,
				hideacceptAllCookiesExcGpcButton: this.currentBannerGpcBanner.hideAcceptAllCookiesExGpcButton,
				hideBackButton: this.currentBannerGpcBanner.hideBackButton,
				gpcBannerLocation: this.currentBannerGpcBanner.selectedGpcBannerAppearanceId,
				colourSchemeJson: parsedColourScheme,
				selectedColorScheme: parsedColourScheme?.identifier ?? colourSchemeEnum.dark,
				customiseButtons: this.gpcPreBannerFormData.customiseButtons
			}
			this.populateCustomGpcColors(parsedColourScheme)
		},
		populateCustomColors (preBannerColourScheme) {
			for (const key in preBannerColourScheme) {
				if (!Object.hasOwnProperty.call(preBannerColourScheme, key)) continue
				const color = preBannerColourScheme[key]
				const matchedColor = this.formData.customiseButtons.find(
					x => x.id.toLocaleLowerCase() === key.toLocaleLowerCase()
				)
				if (matchedColor) matchedColor.color = color
			}
		},
		populateCustomGpcColors (gpcColourScheme) {
			for (const key in gpcColourScheme) {
				if (!Object.hasOwnProperty.call(gpcColourScheme, key)) continue
				const color = gpcColourScheme[key]
				const matchedColor = this.gpcPreBannerFormData.customiseButtons.find(
					x => x.id.toLocaleLowerCase() === key.toLocaleLowerCase()
				)
				if (matchedColor) matchedColor.color = color
			}
		},
		reformatDate (createdDate, lastEditedDate) {
			// created date

			let formattedCreatedDate = new Date(createdDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedCreatedDate.setHours(formattedCreatedDate.getHours() + 8)
			const format = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			}
			formattedCreatedDate = formattedCreatedDate.toLocaleTimeString([], format)
			this.formData.createdDate = formattedCreatedDate

			// edited date
			let formattedLastEditDate = new Date(lastEditedDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedLastEditDate.setHours(formattedLastEditDate.getHours() + 8)
			formattedLastEditDate = formattedLastEditDate.toLocaleTimeString(
				[],
				format
			)
			this.formData.lastEditedDate = formattedLastEditDate
		},
		openColourPicker (element) {
			this.pickerColour = element.color
			this.showColourPicker = true
		},
		getColour (color, element) {
			const matchingColor = element.id
			element.color = color
			this.formData.selectedColourSchemeData[matchingColor] = color
		},
		cancelPreBanner () {
			this.$router.push({ name: 'consent-banners' })
		},
		preventScrolling () {
			document.documentElement.classList.add('prevent-scroll')
		},
		allowScrolling () {
			document.documentElement.classList.remove('prevent-scroll')
		},
		nextStep () {
			if (!this.formUpdated) {
				this.populateForm()
			}
			this.$emit('next')
			this.formUpdated = false
		},
		backToDetails () {
			if (!this.formUpdated) {
				this.populateForm()
			}
			this.$emit('back')
			this.formUpdated = false
		}
	}
}
</script>

<style lang="scss">

.pre-banner-appearance-radio {
	display: flex;
	flex-direction: column-reverse;
	padding: 5px;
}

.cassie-prebanner-appearance-image {
	width: 125px;

	@media screen and (min-width: var(--global-cassie-breakpoint-lg)) {
		width: 150px;
	}
	@media screen and (min-width: var(--global-cassie-breakpoint-xl)) {
		width: 200px;
	}
}

.cassie-colour-picker-container {
	background-color: white;
}

.cassie-radio-group {
	.v-messages.v-messages {
		display: none;
	}
}
</style>
