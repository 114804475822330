export const BANNER_LOCATIONS = {
	MIDDLE: 0,
	RIGHT: 1,
	LEFT: 2,
	PANELS: 3
}

export const BANNER_COLOUR_SCHEMES = {
	DARK: 0,
	LIGHT: 1,
	CUSTOM: 2
}

export const bannerLocationRadios = [
	{
		value: BANNER_LOCATIONS.LEFT,
		imageSrc: require('../../../../../../assets/images/banner-left.svg')
	},
	{
		value: BANNER_LOCATIONS.MIDDLE,
		imageSrc: require('../../../../../../assets/images/banner-middle.svg')
	},
	{
		value: BANNER_LOCATIONS.PANELS,
		imageSrc: require('../../../../../../assets/images/banner-panels.svg')
	},
	{
		value: BANNER_LOCATIONS.RIGHT,
		imageSrc: require('../../../../../../assets/images/banner-right.svg')
	}
]

export const bannerColourSchemeRadios = [
	{
		value: BANNER_COLOUR_SCHEMES.DARK,
		label: 'Dark'
	},
	{
		value: BANNER_COLOUR_SCHEMES.LIGHT,
		label: 'Light'
	},
	{
		value: BANNER_COLOUR_SCHEMES.CUSTOM,
		label: 'Custom'
	}
]

export const showLogoRadios = [
	{
		value: true,
		label: 'Show'
	},
	{
		value: false,
		label: 'Hide'
	}
]

export const customTabLabelRadios = [
	{
		value: true,
		label: 'Show'
	},
	{
		value: false,
		label: 'Hide'
	}
]

export const displayToggleLabelRadios = [
	{
		value: true,
		label: 'Show'
	},
	{
		value: false,
		label: 'Hide'
	}
]

export const displayExpandColapseLabelsRadios = [
	{
		value: true,
		label: 'Show'
	},
	{
		value: false,
		label: 'Hide'
	}
]

export const autoShowModalRadios = [
	{
		value: true,
		label: 'Enabled'
	},
	{
		value: false,
		label: 'Disabled'
	}
]
