<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal
			width="850px"
		>
			<template #modal-title>
				{{ isEditMode ? 'Edit' : 'Add' }} Translations for {{ formData.language ? formData.language.languageName: 'Single Language' }}
			</template>
			<template #modal-content>
				<div class="d-flex flex-column cassie-vertical-md">
					<div v-if="!isEditMode">
						<div class="text-subtitle-1">
							Pick language to translate into
						</div>
						<div class="d-flex flex-row cassie-horizontal-sm">
							<Dropdown
								v-model="formData.language"
								:disabled="isEditMode || !userCanCreateUpdate"
								label="Select Language*"
								class="cassie-input-width-md"
								:items="availableLanguages"
								item-text="languageName"
								required
								return-object
							/>
						</div>
					</div>

					<div class="cassie-vertical-md">
						<v-card
							v-if="formData.language"
							flat
						>
							<FroalaEditor
								v-model="formData.preBannerText"
								:disabled="!userCanCreateUpdate"
								class="cassie-input-width-xl"
								:rules="{required: true, max: 2000}"
								title="Pre banner Text*"
							/>
							<div
								v-if="globalGpcEnabled"
								class="d-flex flex-row cassie-vertical-md cassie-horizontal-md mt-5"
							>
								<TextField
									v-model="formData.gPCBannerTitle"
									:disabled="!userCanCreateUpdate"
									rules="required"
									label="GPC Banner Title *"
									class="cassie-input-width-md"
								/>
							</div>
							<FroalaEditor
								v-if="globalGpcEnabled"
								v-model="formData.gPCBannerText"
								:disabled="!userCanCreateUpdate"
								class="cassie-input-width-xl"
								:rules="{required: true, max: 2000}"
								title="GPC Banner Text *"
							/>
							<div class="d-flex flex-row cassie-vertical-md cassie-horizontal-md mt-5">
								<TextField
									v-model="formData.customiseButton"
									:disabled="!userCanCreateUpdate"
									rules="required"
									label="Customise Button"
									class="cassie-input-width-md"
								/>
								<TextField
									v-model="formData.acceptAllCookiesText"
									:disabled="!userCanCreateUpdate"
									rules="required"
									label="Accept All Text"
									class="cassie-input-width-md"
								/>
							</div>
							<div class="d-flex flex-row cassie-vertical-md cassie-horizontal-md">
								<TextField
									v-model="formData.userConsentTab"
									:disabled="!userCanCreateUpdate"
									rules="required"
									label="User Consent Tab"
									class="cassie-input-width-md"
								/>
								<TextField
									v-model="formData.rejectAll"
									:disabled="!userCanCreateUpdate"
									rules="required"
									label="Reject All Text"
									class="cassie-input-width-md"
								/>
							</div>
							<div class="d-flex flex-row cassie-vertical-md cassie-horizontal-md">
								<TextField
									v-model="formData.legitimateInterestTab"
									:disabled="!userCanCreateUpdate"
									rules="required"
									label="Legitimate Interest Tab"
									class="cassie-input-width-md"
								/>
								<TextField
									v-model="formData.savePreferences"
									:disabled="!userCanCreateUpdate"
									rules="required"
									label="Save Preferences Text"
									class="cassie-input-width-md"
								/>
							</div>
							<div
								v-if="globalGpcEnabled"
								class="d-flex flex-row cassie-vertical-md cassie-horizontal-md"
							>
								<TextField
									v-model="formData.gPCBackButtonText"
									:disabled="!userCanCreateUpdate"
									label="GPC Back Button Button"
									class="cassie-input-width-md"
								/>
								<TextField
									v-model="formData.gPCAcceptAllCookiesButtonText"
									:disabled="!userCanCreateUpdate"
									label="GPC Accept All Button"
									class="cassie-input-width-md"
								/>
							</div>
							<div
								v-if="globalGpcEnabled"
								class="d-flex flex-row cassie-vertical-md cassie-horizontal-md"
							>
								<TextField
									v-model="formData.gPCAcceptAllCookiesExGPCButtonText"
									:disabled="!userCanCreateUpdate"
									label="GPC Accept All Ex. GPC Button"
									class="cassie-input-width-md"
								/>
							</div>
							<div v-if="cookieCategoriesDropdownOptions.length > 0">
								<div class="text-subtitle-1 mb-4">
									Cookie Categories
								</div>
								<Dropdown
									v-model="selectedCookieCategory"
									:disabled="!userCanCreateUpdate"
									:items="cookieCategoriesDropdownOptions"
									return-object
								/>
								<TextField
									v-model="formData.translatedCookieCategories[selectedCookieCategory.value].translatedCategoryName"
									:disabled="!userCanCreateUpdate"
									rules="required"
									label="Cookie Category Translation"
									class="cassie-input-width-md mt-3 mb-3"
								/>
								<FroalaEditor
									v-model="formData.translatedCookieCategories[selectedCookieCategory.value].translatedCategoryDisplayText"
									:disabled="!userCanCreateUpdate"
									class="cassie-input-width-xl"
									:rules="{ max: 2000}"
									:title="selectedCookieCategory.text + ' Display Text'"
								/>
							</div>

							<div class="d-flex flex-row cassie-vertical-md cassie-horizontal-md" />
							<div v-if="cookieScriptsDropdownOptions.length > 0">
								<div class="text-subtitle-1 mt-4">
									Cookie Scripts
								</div>
								<Dropdown
									v-model="selectedCookieScript"
									:disabled="!userCanCreateUpdate"
									:items="cookieScriptsDropdownOptions"
								/>
								<div
									v-if="selectedCookieScript"
									class="d-flex flex-row cassie-vertical-md cassie-horizontal-md mt-3 mb-3"
								>
									<TextField
										v-model="formData.translatedScriptCategories[selectedCookieScript.value].translatedCookieScriptName"
										:disabled="!userCanCreateUpdate"
										label="Cookie Name"
										class="cassie-input-width-md"
									/>
									<TextField
										v-model="formData.translatedScriptCategories[selectedCookieScript.value].translatedCookieScriptUrl"
										:disabled="!userCanCreateUpdate"
										label="Cookie Url"
										class="cassie-input-width-md"
									/>
								</div>
								<FroalaEditor
									v-model="formData.translatedScriptCategories[selectedCookieScript.value].translatedCookieScriptDisplayText"
									:disabled="!userCanCreateUpdate"
									class="cassie-input-width-xl"
									:rules="{ max: 2000}"
									:title="selectedCookieCategory.text + ' Translation'"
								/>
							</div>
						</v-card>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('closeTranslationModal')">
					Cancel
				</SecondaryActionButton>
				<v-spacer />
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="handleSubmit(createTranslations)"
				>
					Save and Continue
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
/* eslint-disable max-lines */
import { mapActions, mapGetters } from 'vuex'
import Modal from '../../../../../../../../shared/components/modal.vue'
import TextField from '../../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../../shared/components/secondary-action-button.vue'
import FroalaEditor from '../../../../../shared/froala-editor.vue'
import ValidationForm from '../../../../../../../../shared/components/validation-form.vue'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS } from '../../../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		Modal,
		Dropdown,
		TextField,
		ValidationForm,
		FroalaEditor,
		SecondaryActionButton,
		PrimaryActionButton
	},
	props: {
		editTranslation: {
			type: Object,
			default: null
		}
	},
	data () {
		return {
			formData: {
				language: null,
				acceptAllCookiesText: '',
				preBannerText: '',
				gPCBannerTitle: '',
				gPCBannerText: '',
				gPCAcceptAllCookiesButtonText: '',
				gPCAcceptAllCookiesExGPCButtonText: '',
				gPCBackButtonText: '',
				customiseButton: '',
				userConsentTab: '',
				rejectAll: '',
				legitimateInterestTab: '',
				savePreferences: '',
				languages: [],
				translatedCookieCategories: {},
				translatedScriptCategories: {}
			},
			selectedCookieCategory: {},
			selectedCookieScript: null,
			defaultTranslationFields: [],
			defaultTranslationKeys: [
				'preBannerText',
				'acceptAllCookiesText',
				'rejectAllCookiesText',
				'customiseButtonText',
				'headerHtml',
				'logoImgSrc',
				'userConsentTabLabel',
				'legitimateInterestTabLabel',
				'optInLabel',
				'optOutLabel',
				'expandLabel',
				'collapseLabel',
				'savePreferencesText',
				'footerHtml'
			],
			formattedCategories: [],
			formattedScripts: [],
			csvData: {},
			displayUploadCSV: false,
			uploadedCsvFile: null,
			newTranslations: [],
			retainedIdForTranslation: 0,
			defaultTranslationText: 'Translate within quotes'
		}
	},
	computed: {
		...mapGetters('headers', ['headers']),
		...mapGetters('cookieCategories', ['cookieCategories']),
		...mapGetters('cookieScripts', ['cookieScripts']),
		cookieCategoriesDropdownOptions () {
			return this.currentBannerCategories.map(category => {
				return {
					text: category.cookieCategoryName,
					value: category.cookieCategoryId
				}
			})
		},
		isEditMode () {
			return this.editTranslation !== null
		},
		cookieScriptsDropdownOptions () {
			return this.currentBannerScripts.map(script => {
				return {
					text: script.cookieScriptName,
					value: script.cookieScriptChannelId
				}
			})
		},
		availableLanguages () {
			return this.assignedLanguages
				.filter(al => this.currentBannerExistingTranslations.find(et => et.languageId === al.languageID) == null).map(al => {
					return {
						languageName: al.languageName,
						languageId: al.languageID
					}
				}).filter(l => l.languageId !== 0)
		},
		...mapGetters('footers', ['footers']),
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('languages', ['assignedLanguages']),
		...mapGetters('consentBanners', ['currentBannerExistingTranslations', 'currentBannerTranslationDetails', 'currentBannerCategories', 'currentBannerScripts', 'globalGpcEnabled']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		}
	},
	created () {
		this.formData.translatedCookieCategories = this.currentBannerCategories.reduce((acc, category) => {
			acc[category.cookieCategoryId] = {
				translatedCategoryName: '',
				translatedCategoryDisplayText: ''

			}
			return acc
		}, {})
		this.selectedCookieCategory = { text: this.currentBannerCategories[0]?.cookieCategoryName, value: this.currentBannerCategories[0]?.cookieCategoryId }

		this.formData.translatedScriptCategories = this.currentBannerScripts.reduce((acc, script) => {
			acc[script.cookieScriptChannelId] = {
				translatedCookieScriptName: '',
				translatedCookieScriptDisplayText: '',
				translatedCookieScriptUrl: ''
			}
			return acc
		}, {})
		this.selectedCookieScript = {
			text: this.currentBannerScripts[0]?.cookieScriptName,
			value: this.currentBannerScripts[0]?.cookieScriptChannelId
		}
		if (this.isEditMode) {
			this.formData.language = {
				languageName: this.editTranslation.languageName,
				languageId: this.editTranslation.languageId
			}
			this.formData.acceptAllCookiesText = this.editTranslation.acceptAllCookiesText
			this.formData.preBannerText = this.editTranslation.preBannerText
			this.formData.gPCBannerTitle = this.editTranslation.gPCBannerTitle
			this.formData.gPCBannerText = this.editTranslation.gPCBannerText
			this.formData.gPCAcceptAllCookiesButtonText = this.editTranslation.gPCAcceptAllCookiesButtonText
			this.formData.gPCAcceptAllCookiesExGPCButtonText = this.editTranslation.gPCAcceptAllCookiesExGPCButtonText
			this.formData.gPCBackButtonText = this.editTranslation.gPCBackButtonText
			this.formData.customiseButton = this.editTranslation.viewAllOptionsText
			this.formData.userConsentTab = this.editTranslation.userConsentTabText
			this.formData.rejectAll = this.editTranslation.rejectAllCookiesText
			this.formData.legitimateInterestTab = this.editTranslation.legitimateInterestTabText
			this.formData.savePreferences = this.editTranslation.savePreferencesText

			if (this.editTranslation?.channelCookieCategoryTranslation?.length > 0) {
				this.editTranslation.channelCookieCategoryTranslation.forEach(category => {
					this.formData.translatedCookieCategories[category.cookieCategoryId] = {
						translatedCategoryName: category.categoryText,
						translatedCategoryDisplayText: category.cookieCategoryStatementText
					}
				})
			}
			if (this.editTranslation?.channelCookieScriptTranslation?.length > 0) {
				this.editTranslation.channelCookieScriptTranslation.forEach(script => {
					this.formData.translatedScriptCategories[script.cookieScriptChannelId] = {
						translatedCookieScriptName: script.cookieName,
						translatedCookieScriptDisplayText: script.cookieScriptText,
						translatedCookieScriptUrl: script.cookieUrl
					}
				})
			}
		}
	},
	methods: {
		...mapActions('consentBanners', [
			'getTranslation',
			'setTranslation',
			'getAllTranslations'
		]),
		...mapActions('cookieCategories', [
			'getCookieCategories'
		]),
		...mapActions('cookieScripts', [
			'getCookieScripts'
		]),
		async createTranslations () {
			const translatedCategories = Object.keys(this.formData.translatedCookieCategories)
				.map(key => {
					return {
						cookieCategoryId: Number(key),
						cookieCategoryName: this.formData.translatedCookieCategories[key].translatedCategoryName,
						cookieCategoryDisplayText: this.formData.translatedCookieCategories[key].translatedCategoryDisplayText
					}
				}).filter(category => category.cookieCategoryName !== '' &&
					category.cookieCategoryDisplayText !== '')
			const translatedScripts = Object.keys(this.formData.translatedScriptCategories)
				.map(key => {
					return {
						cookieScriptChannelId: Number(key),
						cookieScriptName: this.formData.translatedScriptCategories[key].translatedCookieScriptName,
						cookieScriptDisplayText: this.formData.translatedScriptCategories[key].translatedCookieScriptDisplayText,
						cookieUrl: this.formData.translatedScriptCategories[key].translatedCookieScriptUrl
					}
				}).filter(
					script => script.cookieScriptDisplayText !== '' &&
					script.cookieScriptName !== '' &&
					script.cookieUrl !== '')
			const createTranslationRequest = {
				consentBannerId: Number(this.$route.params.cookieBannerId),
				languageId: this.formData.language.languageId,
				preBannerText: this.formData.preBannerText,
				gPCBannerTitle: this.formData.gPCBannerTitle,
				gPCBannerText: this.formData.gPCBannerText,
				gPCAcceptAllCookiesButtonText: this.formData.gPCAcceptAllCookiesButtonText,
				gPCAcceptAllCookiesExGPCButtonText: this.formData.gPCAcceptAllCookiesExGPCButtonText,
				gPCBackButtonText: this.formData.gPCBackButtonText,
				acceptAllCookiesText: this.formData.acceptAllCookiesText,
				rejectAllCookiesText: this.formData.rejectAll,
				customiseButtonText: this.formData.customiseButton,
				userConsentTabLabel: this.formData.userConsentTab,
				legitimateInterestTabLabel: this.formData.legitimateInterestTab,
				savePreferencesText: this.formData.savePreferences,
				categories: translatedCategories,
				scripts: translatedScripts
			}
			await this.setTranslation(createTranslationRequest)

			// Save here
			await this.getAllTranslations(this.$route.params.cookieBannerId)
			this.$emit('closeTranslationModal')
		}
	}
}
</script>

<style lang="scss" scoped>
.cassie-overlay-card {
  max-height: 75vh;
  display: flex !important;
  flex-direction: column;
  overflow-y: auto !important;
}
</style>
