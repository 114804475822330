<template>
	<SectionCard>
		<template #title>
			GPC Banner
		</template>
		<template #subtitle>
			If the browser is transmitting a Global Privacy Control (GPC) signal, this banner will be displayed.
		</template>
		<template #body>
			<div class="cassie-vertical-md">
				<div class="d-flex flex-row cassie-horizontal-sm">
					<div
						class="px-2 pt-2 cassie-input-width-md "
					>
						<div class="text-subtitle-2">
							Appearance*
						</div>
						<RadioGroup
							:radio-group-value.sync="formData.gpcBannerLocation"
							:disabled="!fullAccessPermission"
							:radios="preBannerLocationRadios"
							image-radio-group
						/>
					</div>
					<v-divider vertical />
					<div
						class="px-2 pt-2 cassie-input-width-md cassie-vertical-md"
					>
						<div class="text-subtitle-2">
							Colour Scheme*
						</div>
						<RadioGroup
							:radio-group-value.sync="formData.selectedColorScheme"
							:disabled="!fullAccessPermission"
							:radios="preBannerColourSchemeRadios"
						/>
						<ColourPicker
							v-if="isCustomScheme && formData.customiseButtons"
							:elements.sync="formData.customiseButtons"
						/>
					</div>
				</div>
				<div class="d-flex flex-row">
					<TextField
						:disabled="!fullAccessPermission"
						:value.sync="formData.gpcBannerContentTitle"
						rules="required"
						class="cassie-input-width-md"
						label="GPC-Banner Title *"
						@input="$emit('update:gpcBannerContentTitle', $event)"
					/>
				</div>
				<div class="d-flex flex-row">
					<FroalaEditor
						v-if="fullAccessPermission"
						:value.sync="gpcBannerContent"
						class="cassie-input-width-xl"
						:rules="{required: true, max: 2000}"
						title="GPC-Banner Text*"
						@input="$emit('update:gpcBannerContent', $event)"
					/>
					<div
						v-else
						v-dompurify-html="gpcBannerContent"
						class="mt-2 cassie-disabled-textarea cassie-input-width-xl rounded-lg"
					/>
				</div>
				<v-expansion-panels accordion>
					<v-expansion-panel>
						<v-expansion-panel-header>
							<span>
								Show Advanced Options
							</span>
						</v-expansion-panel-header>
						<v-expansion-panel-content
							class="pt-0"
						>
							<v-card
								flat
								color="grey lighten-5"
								class="cassie-horizontal-sm d-flex pa-3"
							>
								<div
									class="d-flex flex-column cassie-vertical-sm"
									style="width: 33%;"
								>
									<div
										class="text-subtitle-2"
									>
										Show 'Accept All' button
									</div>
									<RadioGroup
										:radio-group-value.sync="formData.hideAcceptAllButton"
										:disabled="!fullAccessPermission"
										:radios="showAcceptAllRadios"
									/>
									<TextField
										v-if="!formData.hideAcceptAllButton"
										:value.sync="formData.acceptAllCookiesButtonText"
										:disabled="!fullAccessPermission"
										label="Accept All Button Text"
										rules="required"
										@input="$emit('update:acceptAllCookiesButtonText', $event)"
									/>
								</div>
								<v-spacer />
								<div
									class="d-flex flex-column cassie-vertical-sm"
									style="width: 33%;"
								>
									<div
										class="text-subtitle-2"
									>
										Show 'Accept All Exc. GPC' button
									</div>
									<RadioGroup
										:radio-group-value.sync="formData.hideacceptAllCookiesExcGpcButton"
										:disabled="!fullAccessPermission"
										:radios="showAcceptAllExcGpcRadios"
									/>
									<TextField
										v-if="!formData.hideacceptAllCookiesExcGpcButton"
										:value.sync="formData.acceptAllCookiesExcGpcButtonText"
										:disabled="!fullAccessPermission"
										label="Accept All Exc. GPC Button Text"
										rules="required"
										@input="$emit('update:acceptAllCookiesExcGpcButtonText', $event)"
									/>
								</div>
								<v-spacer />
								<div
									class="d-flex flex-column cassie-vertical-sm"
									style="width: 33%;"
								>
									<div
										class="text-subtitle-2"
									>
										Show 'Back' button
									</div>
									<RadioGroup
										:radio-group-value.sync="formData.hideBackButton"
										:disabled="!fullAccessPermission"
										:radios="showBackRadios"
									/>
									<TextField
										v-if="!formData.hideBackButton"
										:value.sync="formData.backButtonText"
										:disabled="!fullAccessPermission"
										outlined
										dense
										label="Back Button Text"
										rules="required"
										@input="$emit('update:backButtonText', $event)"
									/>
								</div>
							</v-card>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>
		</template>
	</SectionCard>
</template>
<script>
import SectionCard from '../../../../../../../../shared/components/section-card.vue'
import RadioGroup from '../../../../../../../../shared/components/radio-group.vue'
import ColourPicker from '../../../../../../../../shared/components/colour-picker.vue'
import TextField from '../../../../../../../../shared/components/text-field.vue'
import FroalaEditor from '../../../../../shared/froala-editor.vue'
import { preBannerLocationRadios, preBannerColourSchemeRadios } from './configure-cookie-banner-pre-banner-enums.js'

export default {
	components: { SectionCard, RadioGroup, ColourPicker, TextField, FroalaEditor },
	props: {
		formData: Object,
		gpcBannerContentTitle: String,
		gpcBannerContent: String,
		acceptAllCookiesButtonText: String,
		acceptAllCookiesExcGpcButtonText: String,
		backButtonText: String,
		fullAccessPermission: Boolean
	},
	data () {
		return {
			preBannerLocationRadios,
			preBannerColourSchemeRadios
		}
	},
	computed: {
		isCustomScheme () {
			return this.formData.selectedColorScheme === 2
		},
		showAcceptAllRadios () {
			return [
				{
					label: 'Show',
					value: false
				},
				{
					label: 'Hide',
					value: true
				}
			]
		},
		showAcceptAllExcGpcRadios () {
			return [
				{
					label: 'Show',
					value: false
				},
				{
					label: 'Hide',
					value: true
				}
			]
		},
		showBackRadios () {
			return [
				{
					label: 'Show',
					value: false
				},
				{
					label: 'Hide',
					value: true
				}
			]
		}
	}
}
</script>
