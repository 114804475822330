<template>
	<!-- eslint-disable max-lines-->
	<div>
		<ValidationForm
			#default="{ handleSubmit, invalid, dirty }"
			ref="validationForm"
		>
			<div class="cassie-vertical-md">
				<div
					v-if="formData.createdDate"
					class="text-caption"
				>
					Created By: {{ formData.createdByName }}
					{{ formData.createdDate }}
					<span
						v-if="
							formData.lastEditedDate && formData.editedByName !== ' '
						"
					>
						| Last Edited By: {{ formData.editedByName }} {{ formData.lastEditedDate }}
					</span>
				</div>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<div class="d-flex flex-row cassie-horizontal-sm">
							<TextField
								v-model="formData.bannerName"
								label="Consent Banner Name*"
								class="cassie-input-width-md"
								:disabled="!userCanCreateUpdate"
								:rules="{required: true, max: 120}"
								style="flex: none;"
							/>
							<Dropdown
								v-model="formData.brand"
								:label="brandFieldLabel"
								class="cassie-input-width-md"
								:disabled="!userCanCreateUpdate || isEditMode || !clientMultiBranded || singleBrand"
								:items="brandOptions"
								custom-sort
								item-text="brandName"
								item-value="brandId"
								style="flex: none;"
								rules="required"
								@input="$emit('update:bannerBrandId', $event)"
							/>
						</div>
						<div class="d-flex flex-row cassie-horizontal-sm">
							<Dropdown
								v-model="formData.accessKey"
								label="Select the Default Access Key*"
								class="cassie-input-width-md"
								:disabled="!userCanCreateUpdate"
								:items="filteredAccessKeys"
								item-text="accessKeyName"
								item-value="accessKeyId"
								rules="required"
								style="flex: none;"
							/>
						</div>
						<div class="d-flex flex-row cassie-horizontal-lg cassie-">
							<div class="cassie-vertical-sm">
								<span>Allowed Domains
									<v-tooltip
										slot="append"
										top
									>
										<template #activator="{ on }">
											<v-icon
												light
												v-on="on"
											>
												mdi-information
											</v-icon>
										</template>
										<span>Specify whether this Consent Banner can be used on all
											domains (potential security risk) or only on specific
											ones.</span>
									</v-tooltip></span>
								<RadioGroup
									:radio-group-value.sync="allowedDomains"
									:disabled="!userCanCreateUpdate"
									:radios="allowedDomainsRadios"
								/>
							</div>
							<div class="cassie-vertical-sm">
								<span>Global Privacy Control (GPC)*
									<v-tooltip
										slot="append"
										top
									>
										<template #activator="{ on }">
											<v-icon
												light
												v-on="on"
											>
												mdi-information
											</v-icon>
										</template>
										<span>Enable if you wish for this Consent Banner to detect GPC
											signals from user's browsers.</span>
									</v-tooltip></span>
								<RadioGroup
									:radio-group-value.sync="formData.gpcEnabled"
									:disabled="!userCanCreateUpdate"
									:radios="gpcEnabledRadios"
								/>
							</div>
							<div class="cassie-vertical-sm">
								<span>Cassie Identity Service (CIS)*
									<v-tooltip
										slot="append"
										top
									>
										<template #activator="{ on }">
											<v-icon
												light
												v-on="on"
											>
												mdi-information
											</v-icon>
										</template>
										<span>Cassie Identity Service allows a Consent Banner to
											recreate and re add cookies back into in the user's
											browser that they have opted into previously but have
											since been deleted from their browser (e.g. by ITP in
											Safari or ETP in Firefox).</span>
									</v-tooltip></span>
								<RadioGroup
									:radio-group-value.sync="showCIS"
									:disabled="!userCanCreateUpdate"
									:radios="showCISRadios"
								/>
							</div>
						</div>
						<div
							v-if="allowedDomains === ALLOWED_DOMAINS.CUSTOM_LIST && userCanCreateUpdate"
							class="d-flex flex-row cassie-horizontal-sm"
						>
							<validation-provider
								#default="{valid}"
								class="d-flex flex-row cassie-horizontal-md cassie-input-width-xl"
								tag="div"
								rules="isLink"
							>
								<TextField
									v-model="formData.addDomainValue"
									label="Add domain"
									class="cassie-input-width-md"
									@keydown.enter="addToDomainsList(formData.addDomainValue)"
								/>
								<PrimaryActionButton
									:disabled="!valid"
									@click="addToDomainsList(formData.addDomainValue)"
								>
									Add
								</PrimaryActionButton>
							</validation-provider>
						</div>
						<div
							v-if="allowedDomains === ALLOWED_DOMAINS.CUSTOM_LIST"
							class="d-flex flex-column cassie-horizontal-sm"
						>
							<v-simple-table dense>
								<template #default>
									<thead>
										<tr>
											<th
												scope="col"
												class="text-left"
											>
												Domain
											</th>
											<th
												scope="col"
												class="text-right"
											>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="domain in formData.domainList"
											:key="domain"
										>
											<td>
												{{ domain }}
											</td>
											<td class="text-right">
												<IconButton
													v-if="userCanCreateUpdate"
													tooltip-text="Remove Domain"
													@click="removeDomainFromTable(domain)"
												>
													mdi-trash-can
												</IconButton>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>
					</template>
				</SectionCard>
				<!-- check if cis is enabled-->
				<SectionCard v-if="cisVisibleInAdminPortal === '1' && showCIS">
					<template #title>
						Cassie Identity Service (CIS)
					</template>
					<template #body>
						<div class="d-flex flex-row cassie-horizontal-md cassie-input-width-xl">
							<div class="mt-2">
								{{ cisEnvironment }}
							</div>
							<validation-provider
								#default="{valid}"
								class="d-flex flex-row cassie-horizontal-sm cassie-input-width-lg"
								tag="div"
								:rules="{ isLink: true, required: true }"
							>
								<TextField
									v-model="formData.idsClientDomain"
									:disabled="!userCanCreateUpdate"
									class="cassie-input-width-md"
									label="Domain"
									tooltip-text="Please enter the domain that this Consent Banner will be used on e.g. syrenis.com"
									:rules="{ isLink: true, required: true }"
								/>
								<PrimaryActionButton
									:loading="testingConnection"
									:disabled="
										!userCanCreateUpdate ||
											!valid
									"
									@click="testConnection"
								>
									Test Connection
								</PrimaryActionButton>
							</validation-provider>
						</div>
					</template>
				</SectionCard>

				<SectionCard>
					<template #title>
						Associated Privacy Policy
					</template>
					<template #subtitle>
						Select the Privacy Policy that applies to this Consent Banner.
					</template>
					<template #body>
						<div class="d-flex flex-row cassie-horizontal-sm">
							<Dropdown
								v-model="formData.privacyPolicy"
								label="Privacy Policy*"
								:disabled="!userCanCreateUpdate || !formData.brand && formData.brand !== 0"
								:items="privacyPolicyOptions"
								custom-sort
								class="cassie-input-width-md"
								style="flex: none;"
								rules="required"
							/>
						</div>
					</template>
				</SectionCard>
			</div>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton @click="cancelDetails">
						Exit
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton
						v-if="isEditMode && userCanCreateUpdate"
						class="mr-2"
						:disabled="invalid ||
							!dirty ||
							(!userCanCreateUpdate && configurationStatusId > 1) || noCustomDomains"
						@click="handleSubmit(updateDetails)"
					>
						Save Changes
					</PrimaryActionButton>
					<SecondaryActionButton
						v-if="isEditMode"
						@click="nextStep"
					>
						Next
					</SecondaryActionButton>
					<PrimaryActionButton
						v-else-if="userCanCreateUpdate"
						:disabled="invalid ||	(!userCanCreateUpdate && configurationStatusId > 1) || noCustomDomains"
						@click="handleSubmit(proceedFromDetails)"
					>
						Save and Continue
					</PrimaryActionButton>
				</template>
			</PageActionRow>
		</ValidationForm>
		<v-overlay
			z-index="999"
			:value="saving"
		>
			<v-progress-circular
				indeterminate
				size="64"
			/>
		</v-overlay>
	</div>
</template>

<script>
/* eslint-disable max-lines */
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import ValidationForm from '../../../../../../../../shared/components/validation-form.vue'
import SectionCard from '../../../../../../../../shared/components/section-card.vue'
import { showSnackbar } from '../../../../../../../../shared/state/snackbar.js'
import TextField from '../../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../../shared/components/dropdown.vue'
import PageActionRow from '../../../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../../../../shared/components/icon-button.vue'
import RadioGroup from '../../../../../../../../shared/components/radio-group.vue'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS } from '../../../../../../../../shared/permissions/admin-portal-permissions.js'
import { getEnv } from '../../../../../../../../shared/utils/runtime-settings.js'
import { ALLOWED_DOMAINS } from './configure-cookie-banner-details-enums.js'
import { useLabels } from '../../../../../../../../shared/state/admin-portal-navigation.js'
import { defaultBrandInSelect, singleBrand, clientMultiBranded } from '../../../../../../../../shared/state/brands.js'
const UNIVERSAL_BRAND = 0
export default {
	components: {
		ValidationForm,
		SectionCard,
		TextField,
		Dropdown,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		RadioGroup,
		IconButton
	},
	props: {
		basicDetails: {
			type: Object,
			default: null
		},
		bannerBrandId: Number
	},
	page: {
		title: 'Configure Cookie Banner',
		meta: [
			{
				name: 'Configure Cookie Banner',
				content: 'Configure a Cookie Banner.'
			}
		]
	},
	setup () {
		return {
			showSnackbar,
			ALLOWED_DOMAINS,
			defaultBrandInSelect,
			singleBrand,
			clientMultiBranded
		}
	},
	data () {
		return {
			UNIVERSAL_BRAND,
			formData: {
				consentBannerId: null,
				bannerName: '',
				brand: null,
				accessKey: null,
				addDomainValue: '',
				domainList: [],
				idsClientDomain: '',
				privacyPolicy: null,
				gpcEnabled: false,
				createdByName: '',
				createdDate: '',
				editedByName: '',
				lastEditedDate: ''
			},
			loading: true,
			testingConnection: false,
			saving: false,
			allowedDomains: ALLOWED_DOMAINS.ALLOW_ALL,
			showCIS: false,
			cisEnvironment: getEnv('VUE_APP_CASSIE_IDENTITY_SERVICE_URL'),
			isValid: false,
			editMode: false,
			defaultProfileId: Number,
			formUpdated: false,
			allowedDomainsRadios: [
				{
					label: 'Allow All',
					value: ALLOWED_DOMAINS.ALLOW_ALL
				},
				{
					label: 'Custom List',
					value: ALLOWED_DOMAINS.CUSTOM_LIST
				}
			],
			gpcEnabledRadios: [
				{
					label: 'Enabled',
					value: true
				},
				{
					label: 'Disabled',
					value: false
				}
			],
			showCISRadios: [
				{
					label: 'Enabled',
					value: true
				},
				{
					label: 'Disabled',
					value: false
				}
			]
		}
	},
	computed: {
		...mapGetters('brands', ['brands']),
		...mapGetters('accessKeys', ['accessKeys']),
		...mapGetters('configKeys', ['cisVisibleInAdminPortal']),
		...mapGetters('auth', ['productAreaPermission', 'userBrands']),
		...mapGetters('privacyPolicies', ['privacyPolicies']),
		...mapGetters('consentBanners', ['configurationStatusId', 'consentBannerProfiles']),
		isEditMode () {
			return typeof this.basicDetails?.consentBannerId === 'number'
		},
		brandOptions () {
			return this.userBrands.includes(UNIVERSAL_BRAND)
				? this.brands
				: this.brands.filter(x => this.userBrands.includes(x.brandId) ||
				x.brandId === UNIVERSAL_BRAND).sort((a, b) => a.brandId - b.brandId)
		},
		privacyPolicyOptions () {
			let privacyPolicyOptions = []

			if (this.formData.brand === UNIVERSAL_BRAND) {
				privacyPolicyOptions = this.privacyPolicies.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				privacyPolicyOptions = this.privacyPolicies.filter(({ brandId }) => brandId === this.formData.brand || brandId === UNIVERSAL_BRAND)
			}

			if (!this.privacyPolicies) {
				this.setPrivacyToNone()
			}
			return privacyPolicyOptions
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		},
		noCustomDomains () {
			const selectedDomainType = this.allowedDomains

			const domainTableLength = this.formData.domainList.length
			let noDomainsInCustom = false

			if (selectedDomainType === ALLOWED_DOMAINS.CUSTOM_LIST && !domainTableLength) {
				noDomainsInCustom = true
			}

			return noDomainsInCustom
		},
		filteredAccessKeys () {
			let filteredAccessKeys = []
			if (this.bannerBrandId === UNIVERSAL_BRAND) {
				filteredAccessKeys = this.accessKeys.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				filteredAccessKeys = this.accessKeys.filter(({ brandId }) => brandId === this.bannerBrandId || brandId === UNIVERSAL_BRAND)
			}
			return filteredAccessKeys
		},
		brandFieldLabel () {
			return `Assign to ${useLabels('Brand')}*`
		}
	},
	async mounted () {
		this.preventScrolling()
		this.isEditMode && this.initializeForm()
		await Promise.all([
			this.getBrands(),
			this.getConsentBannerAccessKeys(),
			this.getConfigKeys(),
			this.getPrivacyPolicies()
		]).finally(() => {
			this.loading = false
		})
		this.allowScrolling()
		if (this.brands.length === 1) {
			this.formData.brand = this.brands[0].brandId
			this.$emit('update:bannerBrandId', this.formData.brand)
		}
		if (!this.isEditMode) this.setDefaultBrand()
		this.$refs.validationForm.reset()
	},
	methods: {
		...mapActions('brands', ['getBrands']),
		...mapActions('accessKeys', ['getConsentBannerAccessKeys']),
		...mapActions('configKeys', ['getConfigKeys']),
		...mapActions('privacyPolicies', ['getPrivacyPolicies']),
		...mapActions('consentBanners', [
			'getConsentBannerProfiles',
			'setGpcEnabled',
			'createConsentBanner',
			'publishConsentBannerProfile',
			'getConsentBannerProfiles',
			'updateConfigurationStatusId',
			'updateConsentBannerBasicDetails',
			'setCisEnabled',
			'getDefaultProfileId'
		]),
		async getDefaultProfileId (consentBannerId) {
			await this.getConsentBannerProfiles(consentBannerId).then(() => {
				this.defaultProfileId = this.consentBannerProfiles.find(
					x => x.isDefault === 1
				).profileId
			})
		},
		setDefaultBrand () {
			if (!clientMultiBranded.value) this.formData.brand = UNIVERSAL_BRAND
			const singleBrand = this.brandOptions.filter(({ brandId }) => brandId !== UNIVERSAL_BRAND).length === 1
			if (singleBrand) this.formData.brand = this.brandOptions.filter(({ brandId }) => brandId !== UNIVERSAL_BRAND)[0].brandId

			return null
		},
		setPrivacyToNone () {
			this.formData.privacyPolicy = 0
		},
		addToDomainsList (domainValue) {
			if (domainValue.length !== 0) {
				if (this.formData.domainList.includes(domainValue)) {
					this.showSnackbar({ text: 'Domain ' + domainValue + ' already exists, please enter another domain', color: 'red' })
					domainValue = ''
					this.formData.addDomainValue = ''
				} else {
					this.formData.domainList.push(domainValue)
					domainValue = ''
					this.formData.addDomainValue = ''
				}
			}
		},
		removeDomainFromTable (deletedDomain) {
			const index = this.formData.domainList.findIndex(
				domain => domain === deletedDomain
			)
			this.formData.domainList.splice(index, 1)
		},
		async testConnection () {
			this.testingConnection = true
			const idsUrl =
				this.cisEnvironment + this.formData.idsClientDomain + '/health'
			const api = axios.create()
			api
				.get(idsUrl, { timeout: 5 * 1000 })
				.then(_ => {
					this.showSnackbar('Connection Test Success: ' + this.cisEnvironment + this.formData.idsClientDomain)
				})
				.catch(_ => {
					this.showSnackbar({ text: 'Please check your CIS settings and try again.', color: 'red' })
				})
				.finally(() => {
					this.testingConnection = false
				})
		},
		cancelDetails () {
			// this is to make the dirty checking work
			this.$router.push({ name: 'consent-banners' })
		},
		async proceedFromDetails () {
			let domains = ''
			if (this.allowedDomains === ALLOWED_DOMAINS.ALLOW_ALL) {
				domains = 'all'
			} else if (this.allowedDomains === ALLOWED_DOMAINS.CUSTOM_LIST) {
				domains = this.formData.domainList.toString()
			}

			const bannerDomains = domains

			const newBanner = {
				consentBannerName: this.formData.bannerName,
				allowedDomains: bannerDomains,
				gpcEnabled: this.formData.gpcEnabled,
				brandId: this.formData.brand,
				privacyPolicyId: this.formData.privacyPolicy,
				idsEnvironmentUrl: this.showCIS ? this.cisEnvironment : '',
				idsClientDomain: this.formData.idsClientDomain,
				accessKeyId: this.formData.accessKey
			}
			this.saving = true

			if (newBanner.gpcEnabled === true) {
				this.setGpcEnabled({ gpcEnabled: true })
			} else {
				this.setGpcEnabled({ gpcEnabled: false })
			}

			if (this.formData.idsClientDomain.length === 0) {
				this.setCisEnabled({ cisEnabled: false })
			} else {
				this.setCisEnabled({ cisEnabled: true })
			}

			try {
				const createConsentBannerResult = await this.createConsentBanner(newBanner)
				const updateBannerStatusObject = {
					consentBannerId: createConsentBannerResult.consentBannerId,
					configurationStatusId: 2
				}
				await this.getDefaultProfileId(updateBannerStatusObject.consentBannerId)
				await this.publishConsentBannerProfile(this.defaultProfileId)
				await this.updateConfigurationStatusId(updateBannerStatusObject)
				this.$router.push({
					name: 'configure-cookie-banner',
					params: {
						cookieBannerId: createConsentBannerResult.consentBannerId
					}
				})
			} catch (error) {
				this.showSnackbar({
					text: 'Error updating Consent Banner Details - ' + error.errors.join(', '),
					color: 'red'
				})
			} finally {
				this.saving = false
			}
			// Save details in database and publish to AWS
		},
		initializeForm () {
			this.formData.consentBannerId = this.basicDetails.consentBannerId
			this.formData.bannerName = this.basicDetails.consentBannerName
			this.formData.brand = this.basicDetails.brandId
			this.formData.accessKey = this.basicDetails.accessKeyId
			this.convertDomainsToArray(this.basicDetails.allowedDomains)
			this.removeEnvironmentVariable(this.basicDetails.cisClientDomain)
			this.showCIS = this.basicDetails.cisClientDomain?.length !== 0
			this.formData.privacyPolicy = this.basicDetails.privacyPolicyId
			this.formData.gpcEnabled = this.basicDetails.gpcEnabled
			this.formData.createdByName = this.basicDetails.createdByName
			this.formData.editedByName = this.basicDetails.editedByName
			this.reformatDate(
				this.basicDetails.createdDate,
				this.basicDetails.lastEditedDate
			)
		},
		convertDomainsToArray (domains) {
			if (domains !== 'all') {
				this.allowedDomains = ALLOWED_DOMAINS.CUSTOM_LIST
			}
			this.formData.domainList = []
			// singuilar get returns empty string if no names
			if (domains.length !== 0) {
				const domainArray = domains.split(',')
				this.formData.domainList = domainArray
			}
		},
		removeEnvironmentVariable (cisDomain) {
			const clientCisDomain = cisDomain.split(this.$environment).pop()
			if (!clientCisDomain) {
				this.showCIS = false
			} else {
				this.showCIS = true
			}
			this.formData.idsClientDomain = clientCisDomain?.replace(this.cisEnvironment, '')
		},

		reformatDate (createdDate, lastEditedDate) {
			// created date
			let formattedCreatedDate = new Date(createdDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedCreatedDate.setHours(formattedCreatedDate.getHours() + 8)
			formattedCreatedDate = formattedCreatedDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.createdDate = formattedCreatedDate

			// edited date
			let formattedLastEditDate = new Date(lastEditedDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedLastEditDate.setHours(formattedLastEditDate.getHours() + 8)
			formattedLastEditDate = formattedLastEditDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.lastEditedDate = formattedLastEditDate
		},

		async updateDetails () {
			this.formUpdated = true
			let domains = ''

			if (this.allowedDomains === ALLOWED_DOMAINS.ALLOW_ALL) {
				domains = 'all'
			} else if (this.allowedDomains === ALLOWED_DOMAINS.CUSTOM_LIST) {
				domains = this.formData.domainList.toString()
			}

			const bannerDomains = domains
			const updatedBanner = {
				consentBannerId: this.basicDetails.consentBannerId,
				consentBannerName: this.formData.bannerName,
				brandId: this.formData.brand,
				accessKeyId: this.formData.accessKey,
				privacyPolicyId: this.formData.privacyPolicy,
				gpcEnabled: this.formData.gpcEnabled,
				allowedDomains: bannerDomains,
				idsEnvironmentUrl: this.showCIS ? this.cisEnvironment : '',
				idsClientDomain: this.formData.idsClientDomain
			}

			if (updatedBanner.gpcEnabled === true) {
				this.setGpcEnabled({ gpcEnabled: true })
			} else {
				this.setGpcEnabled({ gpcEnabled: false })
			}

			if (this.formData.idsClientDomain.length === 0) {
				this.setCisEnabled({ cisEnabled: false })
			} else {
				this.setCisEnabled({ cisEnabled: true })
			}

			// Update database and publish changes to AWS
			if (this.userCanCreateUpdate) {
				try {
					this.saving = true
					await this.updateConsentBannerBasicDetails(updatedBanner)
					await this.getDefaultProfileId(updatedBanner.consentBannerId)
					await this.publishConsentBannerProfile(this.defaultProfileId)
					this.getConsentBannerProfiles(this.$route.params.cookieBannerId)
					this.showSnackbar('Consent Banner Details updated successfully!')
				} catch (error) {
					this.showSnackbar({
						text: 'Error updating Consent Banner Details - ' + error.errors.join(', '),
						color: 'red'
					})
				} finally {
					this.saving = false
				}
			}
		},
		preventScrolling () {
			document.documentElement.classList.add('prevent-scroll')
		},
		allowScrolling () {
			document.documentElement.classList.remove('prevent-scroll')
		},

		nextStep () {
			if (!this.formUpdated) {
				this.initializeForm()
			}
			this.$emit('next')
			this.formUpdated = false
		}
	}
}
</script>

<style lang="scss">
.cassie-radio-group {
	.v-messages.v-messages {
		display: none;
	}
}
</style>
