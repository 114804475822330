<template>
	<!-- eslint-disable max-lines-->
	<div>
		<ValidationForm
			#default="{ handleSubmit }"
			ref="validationForm"
		>
			<div class="cassie-vertical-md">
				<SectionCard>
					<template #title>
						Profiles
					</template>
					<template #subtitle>
						<div class="cassie-vertical-md">
							Your Consent Banner can have multiple Profiles with different
							configurations selected. Here you can view
							<span v-if="userCanCreateUpdate">
								and edit
							</span>
							the associated Profiles.
							<div
								v-if="!formData.selectedProfileId > 0"
							>
								Please select a Profile from the list below to generate the
								Embed Code for your Consent Banner.
							</div>
						</div>
					</template>
					<template #title-action>
						<TextButton
							:disabled="!userCanCreateUpdate"
							@click="openCreateProfileModal"
						>
							<v-icon>
								mdi-plus
							</v-icon>
							Create Profile
						</TextButton>
					</template>
					<template #body>
						<div class="d-flex flex-column cassie-horizontal">
							<v-alert
								v-if="!formData.selectedProfiles.length"
								light
								border="bottom"
								colored-border
								type="warning"
								elevation="2"
							>
								There are currently no profiles for this consent banner.
							</v-alert>
							<DataTable
								:headers="profileHeadersComputed"
								:items="formData.selectedProfiles"
								:selected="row => row.profileId === formData.selectedProfileId"
								item-key="profileId"
								no-data-text="Loading Profiles... Please wait"
								hide-default-footer
								@click:row="generateEmbedCode"
							>
								<template #item.loadTemplateHtml="{ item }">
									<v-icon
										v-if="
											item.loadTemplateHtml === 1 ||
												item.loadTemplateHtml === true
										"
										small
									>
										mdi-check
									</v-icon>
									<v-icon
										v-else
										small
									>
										mdi-close
									</v-icon>
								</template>
								<template #item.loadTemplateCss="{ item }">
									<v-icon
										v-if="
											item.loadTemplateCss === 1 ||
												item.loadTemplateCss === true
										"
										small
									>
										mdi-check
									</v-icon>
									<v-icon
										v-else
										small
									>
										mdi-close
									</v-icon>
								</template>
								<template #item.loadTemplateJs="{ item }">
									<v-icon
										v-if="
											item.loadTemplateJs === 1 ||
												item.loadTemplateJs === true
										"
										small
									>
										mdi-check
									</v-icon>
									<v-icon
										v-else
										small
									>
										mdi-close
									</v-icon>
								</template>
								<template #item.isGpc="{ item }">
									<v-icon
										v-if="item.isGpc === 1 || item.isGpc === true"
										small
									>
										mdi-check
									</v-icon>
									<v-icon
										v-else
										small
									>
										mdi-close
									</v-icon>
								</template>
								<template #item.action="{ item }">
									<v-icon
										v-if="
											(item.isDefault === 0 || item.isDefault === false) &&
												userCanCreateUpdate
										"
										small
										class="mr-2"
										@click="openEditProfileModal(item)"
									>
										mdi-pencil
									</v-icon>
									<v-icon
										v-if="
											(item.isDefault === 0 || item.isDefault === false) &&
												userCanDelete
										"
										small
										class="mr-2"
										@click.stop.prevent="openDeleteProfileModal(item)"
									>
										mdi-trash-can
									</v-icon>
								</template>
							</DataTable>
						</div>
					</template>
				</SectionCard>
				<SectionCard v-if="formData.selectedProfileId > 0">
					<template #title>
						Embed code: {{ formData.selectedProfileName }}
					</template>
					<template #title-action>
						<div class="pr-5">
							<v-progress-circular
								v-if="generatingEmbedCode"
								:size="20"
								:width="3"
								color="blue darken-2"
								indeterminate
							/>
						</div>
					</template>
					<template #body>
						<div
							style="font-family:'Monaco', monospace"
						>
							<v-card
								width="100%"
								dark
								style="font-family:'Monaco', monospace"
							>
								<div
									v-if="generatingEmbedCode"
								>
									<span
										style="font-family:'Monaco', monospace"
									>Generating Embed Code...</span>
								</div>
								<div
									v-if="!generatingEmbedCode"
									style="font-family:'Monaco', monospace"
								>
									<Prism>
										{{ formData.embedCode }}
									</Prism>
								</div>
							</v-card>
						</div>
					</template>
				</SectionCard>
				<SectionCard
					v-if="formData.selectedProfileId > 0"
					embedded
				>
					<template #body>
						<v-card>
							<v-tabs
								v-model="tab"
								background-color="blue darken-2"
								color="white"
								class="cassie-scripts-type-tabs-container"
								active-class="cassie-scripts-type-tabs-active"
								slider-size="4"
								slider-color="#D8DBE3"
							>
								<v-tab class="cassie-scripts-type-tab ml-2">
									Events
								</v-tab>
								<v-tab class="cassie-scripts-type-tab">
									Consent Management
								</v-tab>
								<v-tab class="cassie-scripts-type-tab">
									Custom Templates
								</v-tab>
								<v-tab
									v-if="globalGpcEnabled"
									class="cassie-scripts-type-tab"
								>
									GPC Events
								</v-tab>
							</v-tabs>
							<v-tabs-items v-model="tab">
								<v-tab-item>
									<v-card flat>
										<v-card-subtitle>
											These events are supported to allow for capturing data from
											the cookie widget.
										</v-card-subtitle>
										<v-card v-model="StaticDataEvents">
											<v-expansion-panels accordion>
												<v-expansion-panel
													v-for="itm in StaticDataEvents"
													:key="itm.id"
												>
													<v-expansion-panel-header
														@click.native="forceUpdateForSyntaxHighlighting"
													>
														{{ itm.name }}
													</v-expansion-panel-header>
													<v-expansion-panel-content>
														<v-card
															dark
															style="font-family:'Monaco', monospace"
														>
															<div
																class="px-2"
																style="font-family:'Monaco', monospace"
															>
																<Prism>
																	{{ itm.value }}
																</Prism>
															</div>
														</v-card>
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
										</v-card>
									</v-card>
								</v-tab-item>
								<v-tab-item>
									<v-card flat>
										<v-card-subtitle>
											These functions are supported to allow for capturing data
											along with retrieving consents from the cookie widget.
										</v-card-subtitle>
										<v-card v-model="StaticDataConsentManagement">
											<v-expansion-panels accordion>
												<v-expansion-panel
													v-for="itm in StaticDataConsentManagement"
													:key="itm.id"
												>
													<v-expansion-panel-header
														@click.native="forceUpdateForSyntaxHighlighting"
													>
														{{ itm.name }}
													</v-expansion-panel-header>
													<v-expansion-panel-content>
														<v-card
															dark
															style="font-family:'Monaco', monospace"
														>
															<div
																class="px-2"
																style="font-family:'Monaco', monospace"
															>
																<Prism>
																	{{ itm.value }}
																</Prism>
															</div>
														</v-card>
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
										</v-card>
									</v-card>
								</v-tab-item>
								<v-tab-item>
									<v-card flat>
										<v-card-subtitle>
											If you have custom template for our widget you can
											optionally use our functions to raise an event.
										</v-card-subtitle>
										<v-card v-model="StaticDataCustomTemplates">
											<v-expansion-panels accordion>
												<v-expansion-panel
													v-for="itm in StaticDataCustomTemplates"
													:key="itm.id"
												>
													<v-expansion-panel-header
														@click.native="forceUpdateForSyntaxHighlighting"
													>
														{{ itm.name }}
													</v-expansion-panel-header>
													<v-expansion-panel-content>
														<v-card
															dark
															style="font-family:'Monaco', monospace"
														>
															<div
																class="px-2"
																style="font-family:'Monaco', monospace"
															>
																<Prism>
																	{{ itm.value }}
																</Prism>
															</div>
														</v-card>
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
										</v-card>
									</v-card>
								</v-tab-item>
								<v-tab-item v-if="globalGpcEnabled">
									<v-card flat>
										<v-card-subtitle>
											If you have Global Privacy Control enabled you can
											optionally use our functions to raise an event.
										</v-card-subtitle>
										<v-card v-model="StaticGPCEvents">
											<v-expansion-panels accordion>
												<v-expansion-panel
													v-for="itm in StaticGPCEvents"
													:key="itm.id"
												>
													<v-expansion-panel-header
														@click.native="forceUpdateForSyntaxHighlighting"
													>
														{{ itm.name }}
													</v-expansion-panel-header>
													<v-expansion-panel-content>
														<v-card
															dark
															style="font-family:'Monaco', monospace"
														>
															<div
																class="px-2"
																style="font-family:'Monaco', monospace"
															>
																<Prism>
																	{{ itm.value }}
																</Prism>
															</div>
														</v-card>
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
										</v-card>
									</v-card>
								</v-tab-item>
							</v-tabs-items>
						</v-card>
					</template>
				</SectionCard>
			</div>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton
						class="ml-2"
						@click="cancelInstallation"
					>
						Exit
					</SecondaryActionButton>
					<v-spacer />
					<SecondaryActionButton
						class="ml-2"
						@click="backToTranslations"
					>
						Back
					</SecondaryActionButton>
					<PrimaryActionButton
						v-if="lastSavedStep < currentStep && userCanCreateUpdate"
						class="ml-2"
						:loading="saving"
						@click="handleSubmit(publishAndComplete)"
					>
						Save and Continue
					</PrimaryActionButton>
				</template>
			</PageActionRow>
			<!-- Profile form -->
			<profile-form
				v-if="showProfileModal"
				:form="formData"
				:banner-brand-id="bannerBrandId"
				@cancelForm="cancelProfileForm"
				@createNewProfile="createNewProfile"
				@updateProfile="updateProfile"
			/>
		</ValidationForm>

		<!-- Delete Confirmation Overlay -->
		<delete-confirmation-modal
			:show="deleteOverlay"
			:title="'Delete ' + formData.selectedProfileName"
			:type="'Profile'"
			@cancelDelete="cancelDeleteProfileModal"
			@confirmDelete="deleteProfile"
		/>
	</div>
</template>

<script>
/* eslint-disable max-lines */
// We need to disable an eslint rule in this file to escape characters in the script block strings as it's detecting a false positive
/* eslint-disable no-useless-escape */
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import Prism from 'vue-prism-component'
import ValidationForm from '../../../../../../../../shared/components/validation-form.vue'
import DeleteConfirmationModal from '../../../../../shared/delete-confirmation-modal.vue'
import { showSnackbar } from '../../../../../../../../shared/state/snackbar.js'
import SectionCard from '../../../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../../../shared/components/data-table.vue'
import PageActionRow from '../../../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../../../shared/components/primary-action-button.vue'
import TextButton from '../../../../../../../../shared/components/text-button.vue'
import SecondaryActionButton from '../../../../../../../../shared/components/secondary-action-button.vue'
import { getConsentBannerConfigurationStatus } from '../../../../../../services/ConsentBannerService.js'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS, CAN_DELETE_CONSENT_BANNERS } from '../../../../../../../../shared/permissions/admin-portal-permissions.js'
import ProfileForm from './configure-installation-profile-form.vue'

export default {
	name: 'configure-installation',
	components: {
		ValidationForm,
		SectionCard,
		PageActionRow,
		PrimaryActionButton,
		TextButton,
		SecondaryActionButton,
		DataTable,
		'profile-form': ProfileForm,
		'delete-confirmation-modal': DeleteConfirmationModal,
		Prism
	},
	props: {
		bannerBrandId: Number
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			tab: null,
			tempData: null,
			currentStep: 6,
			saving: false,
			showProfileModal: false,
			savingSuccess: false,
			deleteOverlay: false,
			generatingEmbedCode: false,
			forceRegenEmbedCode: false,
			formData: {
				profileId: null,
				profileName: '',
				profileDescription: '',
				accessKey: Number,
				accessKeyName: '',
				loadTemplateHTML: true,
				loadTemplateCSS: true,
				loadTemplateJS: true,
				isGpc: false,
				createdByName: '',
				createdDate: '',
				editedByName: '',
				lastEditedDate: '',
				selectedPreBannerAppearance: null,
				selectedPreBannerColourScheme: null,
				selectedPreBannerColourSchemeData: null,
				selectedBannerAppearance: null,
				selectedBannerColourScheme: null,
				selectedBannerColourSchemeData: null,
				selectedGpcBannerAppearance: null,
				selectedGpcBannerColourScheme: null,
				selectedGpcBannerColourSchemeData: null,
				selectedProfiles: [],
				selectedProfile: null,
				selectedProfileId: 0,
				selectedProfileName: '',
				embedCode: null,
				consentBannerProfileHeader: [
					{
						text: 'Id',
						value: 'profileId',
						sortable: false
					},
					{
						text: 'Name',
						value: 'profileName',
						sortable: false
					},
					{
						text: 'Access Key',
						value: 'accessKeyName',
						sortable: false
					},
					{
						text: 'Load HTML',
						value: 'loadTemplateHtml',
						sortable: false
					},
					{
						text: 'Load CSS',
						value: 'loadTemplateCss',
						sortable: false
					},
					{
						text: 'Load JS',
						value: 'loadTemplateJs',
						sortable: false
					},
					{
						text: 'Is GPC',
						value: 'isGpc',
						sortable: false
					},
					{
						text: 'Action',
						value: 'action',
						sortable: false
					}
				]
			},
			StaticDataEvents: [
				{
					id: '1',
					name: 'CassieTemplateFilesLoaded',
					value:
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieTemplateFilesLoaded\',function() {\n// Your code here...\n});<\/script>'
				},
				{
					id: '2',
					name: 'CassieTemplateInitialized',
					value:
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieTemplateInitialized\', function(){\n// Your code here...\n});<\/script> '
				},
				{
					id: '3',
					name: 'CassieBannerVisibility',
					value:
					// eslint-disable-next-line max-len
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieBannerVisibility\', function(e){var visible = e.detail; // Returns true or false\n// Your code here...\n});<\/script> '
				},
				{
					id: '4',
					name: 'CassieModalVisibility',
					value:
					// eslint-disable-next-line max-len
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieModalVisibility\', function(e){var visible = e.detail; // Returns true or false\n// Your code here...\n});<\/script> '
				},
				{
					id: '5',
					name: 'CassieSubmitConsent',
					value:
					// eslint-disable-next-line max-len
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieSubmitConsent\', function(e){var preferences = e.detail; // Returns true or false\n// Your code here...\n});<\/script> '
				},
				{
					id: '6',
					name: 'CassieSubmittedConsent',
					value:
					// eslint-disable-next-line max-len
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieSubmittedConsent\', function(e){var preferences = e.detail; // Returns true or false\n// Your code here...\n});<\/script> '
				}
			],
			StaticDataConsentManagement: [
				{
					id: '1',
					name: 'Submit Consent',
					value:
					// eslint-disable-next-line max-len
						'<script type=\'text/javascript\'>\nvar preferences = CassieWidgetLoader.Widget.getPreferences(); // Returns [{channelId, statementId, isOptIn}]\n// Do some work to decide which ones are opt in or out.\n// Pass preferences and specify your source for your own purposes.\nCassieWidgetLoader.Widget.submitConsent(preferences, \'myCustomSource\');\n<\/script> '
				},
				{
					id: '2',
					name: 'Accept All',
					value:
						'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.acceptAll(\'myCustomAcceptAllSource\');\n<\/script> '
				},
				{
					id: '3',
					name: 'Reject All',
					value:
						'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.rejectAll(\'myCustomAcceptAllSource\');\n<\/script> '
				},
				{
					id: '4',
					name: 'Get Current Consent',
					value:
					// eslint-disable-next-line max-len
						'<script type=\'text/javascript\'>\nvar consent = CassieWidgetLoader.Widget.getCurrentConsent(); // Returns [{channelId, statementId, isOptIn}]\n// If there is no consent, the return is an empty array\n<\/script> '
				},
				{
					id: '5',
					name: 'Has Consent',
					value:
						'<script type=\'text/javascript\'>\nvar hasConsent = CassieWidgetLoader.Widget.hasConsent(); // Returns true or false\n<\/script> '
				}
			],
			StaticDataCustomTemplates: [
				{
					id: '1',
					name: 'Hide Banner',
					value:
						'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.hideBanner();\n<\/script> '
				},
				{
					id: '2',
					name: 'Show Banner',
					value:
						'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.showBanner();\n<\/script> '
				},
				{
					id: '3',
					name: 'Hide Modal',
					value:
						'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.hideModal();\n<\/script> '
				},
				{
					id: '4',
					name: 'Show Modal',
					value:
						'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.showModal();\n<\/script> '
				}
			],
			StaticGPCEvents: [
				{
					id: '1',
					name: 'CassieGPCBannerDisplayed',
					value:
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCBannerDisplayed\', function() {\n// Your code here...\n});<\/script>'
				},
				{
					id: '2',
					name: 'CassieGPCAcceptAllClick',
					value:
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCAcceptAllClick\', function(){\n// Your code here...\n});<\/script> '
				},
				{
					id: '3',
					name: 'CassieGPCAcceptAllExGPCCLick',
					value:
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCAcceptAllExGPCCLick\', function(){\n// Your code here...\n});<\/script> '
				},
				{
					id: '4',
					name: 'CassieGPCBackClick',
					value:
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCBackClick\', function(){\n// Your code here...\n});<\/script> '
				},
				{
					id: '5',
					name: 'CassieGPCOnClose',
					value:
						'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCOnClose\', function(){\n// Your code here...\n});<\/script> '
				}
			]
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('consentBanners', [
			'currentConsentBannerId',
			'configurationStatusId',
			'lastSavedStep',
			'consentBannerProfiles',
			'embedCode',
			'globalGpcEnabled'
		]),
		profileHeadersComputed () {
			// Hide the 'action' column if it would be empty
			const headersComputed = this.formData.consentBannerProfileHeader
			if (
				!this.userCanCreateUpdate &&
				!this.userCanDelete
			) {
				const actionHeader = headersComputed.findIndex(
					header => header.value === 'action'
				)
				headersComputed.splice(actionHeader, 1)
			}
			return headersComputed
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_CONSENT_BANNERS)
		}
	},
	async mounted () {
		this.preventScrolling()
		// Navigating directly to a consent banner can bypass some state mutations
		// Set required values if needed
		if (this.currentConsentBannerId == null) {
			this.setCurrentBannerId(this.$route.params.cookieBannerId)
		}
		if (this.configurationStatusId === 0) {
			const configurationStatusId = (
				await getConsentBannerConfigurationStatus(this.$route.params.cookieBannerId)
			).data.configurationStatus
			this.setConfigurationStatusId(configurationStatusId)
		}

		await Promise.all([
			this.getConsentBannerProfiles(this.$route.params.cookieBannerId)
		]).finally(() => {
			this.populateProfiles()
			this.allowScrolling()
		})

		// Auto-select the default profile if it's the only one
		if (this.formData.selectedProfiles.length === 1) {
			const generateEmbedCodeObject = {
				profileId: this.formData.selectedProfiles[0].profileId,
				profileName: this.formData.selectedProfiles[0].profileName
			}
			this.generateEmbedCode(generateEmbedCodeObject)
		}
		this.$refs.validationForm.reset()
	},
	methods: {
		...mapActions('consentBanners', [
			'setCurrentBannerId',
			'getConsentBannerConfigurationStatus',
			'getConsentBannerProfiles',
			'publishConsentBannerProfile',
			'updateConfigurationStatusId',
			'setGpcEnabled',
			'getConsentBannerProfile',
			'getEmbedCode',
			'createConsentBannerProfile',
			'updateConsentBannerProfile',
			'deleteConsentBannerProfile',
			'setConfigurationStatusId'
		]),
		async publishAndComplete () {
			this.saving = true
			const defaultProfileID = this.consentBannerProfiles.find(
				x => x.isDefault === 1
			).profileId
			await this.publishConsentBannerProfile(defaultProfileID).then(() => {
				this.saving = false
				this.completeInstallation()
				const updateBannerStatusObject = {
					consentBannerId: this.$route.params.cookieBannerId,
					configurationStatusId: 7
				}
				if (this.lastSavedStep < this.currentStep) {
					this.updateConfigurationStatusId(updateBannerStatusObject)
				}
			})
		},
		completeInstallation () {
			this.$emit('complete')
		},
		backToTranslations () {
			this.$emit('back')
		},
		cancelInstallation () {
			this.$router.push({ name: 'consent-banners' })
		},
		preventScrolling () {
			document.documentElement.classList.add('prevent-scroll')
		},
		allowScrolling () {
			document.documentElement.classList.remove('prevent-scroll')
		},
		populateProfiles () {
			const bannerProfiles = this.consentBannerProfiles
			this.formData.selectedProfiles.push(...bannerProfiles)

			const gpcProfiles = this.formData.selectedProfiles.filter(profile => {
				return profile.isGpc === 1 || profile.gpc === true
			})
			if (gpcProfiles.length > 0) {
				this.setGpcEnabled({ gpcEnabled: true })
			} else {
				this.setGpcEnabled({ gpcEnabled: false })
			}
		},
		openCreateProfileModal () {
			this.showProfileModal = true
			this.preventScrolling()
			this.editMode = false
			this.formData.profileId = null
		},
		async openEditProfileModal (profileObject) {
			this.preventScrolling()
			this.editMode = true
			const getProfileObject = {
				selectedProfileId: profileObject.profileId,
				selectedBannerId: this.$route.params.cookieBannerId
			}
			await this.getConsentBannerProfile(getProfileObject)
				.then(profile => {
					this.formData.profileId = profileObject.profileId
					this.formData.profileName = profile.profileName
					this.formData.profileDescription = profile.profileDescription
					this.formData.accessKey = profile.accessKeyId
					this.formData.loadTemplateHTML = profile.loadTemplateHtml === 1
					this.formData.loadTemplateCSS = profile.loadTemplateCss === 1
					this.formData.loadTemplateJS = profile.loadTemplateJs === 1
					this.formData.cassieCookiesExpiry = profile.cassieCookiesExpiry ?? 365
					this.formData.isGpc = profile.isGpc === 1
					this.formData.createdByName = profile.createdByName
					this.formData.createdDate = profile.createDate
					this.formData.editedByName = profile.editedByName
					this.formData.lastEditedDate = profile.lastEditedDate
					this.formData.selectedPreBannerAppearance = profile.selectedPreBannerAppearance
					this.formData.selectedBannerAppearance = profile.selectedWidgetAppearance
					this.formData.selectedGpcBannerAppearance = profile.selectedGpcBannerAppearance
					this.formData.selectedPreBannerColourSchemeData = JSON.parse(
						profile.selectedPreBannerColourSchemeJson
					)
					this.formData.selectedBannerColourSchemeData = JSON.parse(
						profile.selectedBannerColourSchemeJson
					)
					this.formData.selectedGpcBannerColourSchemeData = JSON.parse(profile.selectedGpcBannerColourSchemeJson)
				})
				.catch(errors => {
					this.errors = errors
				})
				.finally(() => {
					this.showProfileModal = true
				})
		},
		cancelProfileForm () {
			this.showProfileModal = false
			this.allowScrolling()
		},
		selectedProfileRow (item) {
			// Highlight selected row, unless only the default profile exists
			return item.profileId === this.formData.selectedProfileId &&
				this.formData.selectedProfiles.length > 1
				? 'selected-row'
				: ''
		},
		async generateEmbedCode (value) {
			// Don't call out again for same data if same row clicked
			// Do call if forced to by user updating selected profile
			if (
				this.formData.selectedProfileId === value.profileId &&
				this.forceRegenEmbedCode === false
			) {
				return
			}
			this.forceRegenEmbedCode = false
			this.generatingEmbedCode = true
			this.formData.selectedProfileId = value.profileId
			this.formData.selectedProfileName = value.profileName
			this.formData.embedCode = 'Generating Embed Code...'
			const getEmbedCodeObject = {
				profileId: this.formData.selectedProfileId,
				languageCode: '',
				consentBannerId: this.$route.params.cookieBannerId
			}
			await this.getEmbedCode(getEmbedCodeObject)
				.then(() => {
					this.formData.embedCode = this.embedCode
				})
				.catch(error => {
					console.error(error)
					this.formData.embedCode = 'Error generating Embed Code'
				})
				.finally(() => {
					this.generatingEmbedCode = false
				})
		},
		async createNewProfile (form) {
			const newProfile = {
				profileId: null,
				consentBannerId: this.$route.params.cookieBannerId,
				profileName: form.profileName,
				profileDescription: form.profileDescription,
				accessKeyId: form.accessKey,
				isDefault: false,
				loadTemplateCSS: form.loadTemplateCSS,
				loadTemplateHTML: form.loadTemplateHTML,
				loadTemplateJS: form.loadTemplateJS,
				cassieCookiesExpiry: form.cassieCookiesExpiry,
				selectedWidgetAppearanceId: form.selectedBannerAppearance,
				selectedPreBannerAppearance: form.selectedPreBannerAppearance,
				selectedGpcBannerAppearance: form.selectedGpcBannerAppearance,
				preBannerColourSchemeJson: JSON.stringify(
					form.selectedPreBannerColourSchemeData
				),
				bannerColourSchemeJson: JSON.stringify(
					form.selectedBannerColourSchemeData
				),
				isGpc: form.isGpc,
				gpcBannerColourSchemeJson: JSON.stringify(
					form.selectedGpcBannerColourSchemeData
				),
				accessKeyName: form.accessKeyName
			}

			// only set global gpc if new profile is gpc and global gpc isn't already enabled
			if (newProfile.isGpc === true && this.globalGpcEnabled === false) {
				this.setGpcEnabled({ gpcEnabled: true })
			}

			await this.createConsentBannerProfile(newProfile)
				.then(newProfile => {
					this.formData.profileId = newProfile.profileId
					this.savingSuccess = true
				})
				.catch(error => {
					console.error(error)
					this.showSnackbar({ text: 'Error creating Profile', color: 'red' })
					this.savingSuccess = false
				})
			await this.publishConsentBannerProfile(this.formData.profileId)
				.then(() => {
					this.showSnackbar('New Profile created successfully')
				})
				.catch(error => {
					console.error(error)
					this.showSnackbar('Error publishing Profile')
					this.savingSuccess = false
				})
			if (!this.savingSuccess) {
				return
			}
			this.showProfileModal = false
			this.allowScrolling()
			// Add new profile to data table, dealing with case-sensitive properties
			newProfile.profileId = this.formData.profileId
			newProfile.loadTemplateHtml = newProfile.loadTemplateHTML
			newProfile.loadTemplateCss = newProfile.loadTemplateCSS
			newProfile.loadTemplateJs = newProfile.loadTemplateJS
			this.formData.selectedProfiles.push(newProfile)
			// Select the new profile in the table and generate embed code
			const generateEmbedCodeObject = {
				profileId: this.formData.profileId,
				consentBannerId: this.$route.params.cookieBannerId
			}
			this.generateEmbedCode(generateEmbedCodeObject)
			this.savingSuccess = false
			this.formData.selectedProfiles = []
			await this.getConsentBannerProfiles(this.$route.params.cookieBannerId)
			this.populateProfiles()
		},
		async updateProfile (form) {
			const updatedProfile = {
				profileId: form.profileId,
				consentBannerId: this.$route.params.cookieBannerId,
				profileName: form.profileName,
				profileDescription: form.profileDescription,
				accessKeyId: form.accessKey,
				loadTemplateCss: form.loadTemplateCSS,
				loadTemplateHtml: form.loadTemplateHTML,
				loadTemplateJs: form.loadTemplateJS,
				cassieCookiesExpiry: form.cassieCookiesExpiry,
				selectedWidgetAppearanceId: form.selectedBannerAppearance,
				selectedPreBannerAppearanceId: form.selectedPreBannerAppearance,
				selectedGpcBannerAppearance: form.selectedGpcBannerAppearance,
				preBannerColourSchemeJson: JSON.stringify(
					form.selectedPreBannerColourSchemeData
				),
				bannerColourSchemeJson: JSON.stringify(
					form.selectedBannerColourSchemeData
				),
				gpcBannerColourSchemeJson: JSON.stringify(
					form.selectedGpcBannerColourSchemeData
				),
				isGpc: form.isGpc,
				accessKeyName: form.accessKeyName,
				isDefault: false
			}

			// only set global gpc if updated profile is gpc and global gpc isn't already enabled
			if (updatedProfile.isGpc === true && this.globalGpcEnabled === false) {
				this.setGpcEnabled({ gpcEnabled: true })
			}
			await this.updateConsentBannerProfile(updatedProfile)
				.then(() => {
					this.savingSuccess = true
				})
				.catch(error => {
					this.snackbarText = 'Error updating Profile'
					this.snackbarColor = 'error'
					console.error(error)
					this.showSnackbar = true
					this.savingSuccess = false
				})
			if (!this.savingSuccess) {
				this.resetSnackbar()
				return
			}
			await this.publishConsentBannerProfile(updatedProfile.profileId)
				.then(() => {
					this.showSnackbar('Profile updated successfully')
				})
				.catch(error => {
					console.error(error)
					this.showSnackbar('Error publishing Profile')
					this.savingSuccess = false
				})
			if (!this.savingSuccess) {
				return
			}
			this.showProfileModal = false
			this.editMode = false
			this.allowScrolling()
			// Update the profile in the table
			const index = this.formData.selectedProfiles.findIndex(
				profile => profile.profileId === updatedProfile.profileId
			)
			Vue.set(this.formData.selectedProfiles, index, updatedProfile)
			// Re-generate the Embed Code to account for updated profile
			this.forceRegenEmbedCode = true
			this.generateEmbedCode(updatedProfile)
			this.formData.selectedProfiles = []
			await this.getConsentBannerProfiles(this.$route.params.cookieBannerId)
			this.populateProfiles()
		},
		openDeleteProfileModal (item) {
			this.deleteOverlay = true
			this.formData.selectedProfileId = item.profileId
			this.formData.selectedProfileName = item.profileName
			this.preventScrolling()
		},
		cancelDeleteProfileModal () {
			this.deleteOverlay = false
			this.allowScrolling()
		},
		async deleteProfile () {
			const deleteProfileObject = {
				profileId: this.formData.selectedProfileId,
				consentBannerId: this.$route.params.cookieBannerId
			}
			await this.deleteConsentBannerProfile(deleteProfileObject)
				.then(() => {
					this.showSnackbar('Profile deleted successfully')
					this.formData.selectedProfileId = 0
					this.formData.selectedProfileName = ''
				})
				.catch(error => {
					console.error(error)
					this.showSnackbar('Error updating Profile')
				})
			this.deleteOverlay = false
			this.allowScrolling()
			// Delete the profile from the table
			const index = this.formData.selectedProfiles.findIndex(
				profile => profile.profileId === deleteProfileObject.profileId
			)
			this.formData.selectedProfiles.splice(index, 1)

			// check the profiles again for gpc
			const gpcProfiles = this.formData.selectedProfiles.filter(profile => {
				return profile.isGpc === 1 || profile.gpc === true
			})
			if (gpcProfiles.length > 0) {
				this.setGpcEnabled({ gpcEnabled: true })
			} else {
				this.setGpcEnabled({ gpcEnabled: false })
			}
		},
		// We need to remind Vue to update the DOM for the syntax highlighting to behave consistently
		syntaxHighlight (codeBlock) {
			this.$nextTick(function () {
			})
			return codeBlock
		},
		forceUpdateForSyntaxHighlighting () {
			this.$forceUpdate()
		}
	}
}
</script>

<style lang="scss" scoped>
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
	color: unset;
}
pre {
	display: block;
	font-family: monospace;
	white-space: pre;
	margin: 1em 0;
}
.cassie-scripts-type-tabs-container {
	border-radius: 5px;
}

.v-application {
	.v-tabs-bar__content {
		.cassie-scripts-type-tab,
		.v-tab {
			font-size: 12px;
			color: var(--cassie-tab-secondary-text-color);
			text-transform: var(--cassie-tab-text-transform);
		}
		.cassie-scripts-type-tab-active {
			background: var(--cassie-tab-secondary-background-color);
			border-radius: 5px;
		}
		.v-tab--active.v-tab:not(:focus):before,
		.cassie-scripts-type-tab-active::before {
			opacity: 0.12;
		}
	}
}
</style>
