<template>
	<Modal
		width="850px"
	>
		<template #modal-title>
			Translation
			<v-spacer />
			<IconButton
				@click="$emit('closeTranslationModal')"
			>
				mdi-close
			</IconButton>
		</template>
		<template #modal-content>
			<div class="d-flex flex-column cassie-vertical-md">
				<div class="text-subtitle-2">
					1. Please select the languages below that you wish to be able to use on this consent banner.
				</div>
				<div class="d-flex flex-row cassie-horizontal-sm">
					<Dropdown
						v-model="formData.language"
						label="Select Language*"
						class="cassie-input-width-md"
						:items="availableLanguages"
						item-text="languageName"
						return-object
					/>
					<PrimaryActionButton
						:disabled="!formData.language"
						@click="addLanguage(formData.language)"
					>
						Add Language
					</PrimaryActionButton>
				</div>
				<div class="cassie-vertical-md">
					<v-card
						v-if="formData.languages.length !== 0"
						outlined
					>
						<v-simple-table dense>
							<template #default>
								<thead>
									<tr>
										<th
											scope="col"
											class="text-left"
										>
											Language
										</th>
										<th
											scope="col"
											class="text-right"
										>
											<span
												v-if="userCanCreateUpdate"
											>Action</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="language in formData.languages"
										:key="language.languageName"
									>
										<td>
											{{ language.languageName }}
										</td>
										<td class="text-right">
											<v-btn
												v-if="
													userCanCreateUpdate &&
														!language.alreadySelected
												"
												icon
												@click="removeLanguage(language)"
											>
												<v-icon>
													mdi-trash-can
												</v-icon>
											</v-btn>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card>
					<v-divider v-if="formData.languages.length" />
					<div
						v-if="formData.languages.length"
						class="text-subtitle-2"
					>
						2. Please download and populate the Translations Template.
					</div>
					<PrimaryActionButton
						v-if="formData.languages.length"
						:disabled="!formData.languages.length"
						@click="generateCSVTemplate"
					>
						Download Template
					</PrimaryActionButton>
				</div>
				<v-divider v-if="formData.languages.length" />
				<div
					v-if="formData.languages.length"
					class="text-subtitle-2"
				>
					3. Please upload your completed Translations Template.
				</div>
				<div
					v-if="formData.languages.length"
					class="d-flex flex-row cassie-horizontal-sm"
				>
					<v-file-input
						ref="csvFile"
						v-model="uploadedCsvFile"
						accept=".csv"
						light
						outlined
						label="Select Translations Template*"
						dense
						@change="readCsvFile"
					/>
					<PrimaryActionButton
						:disabled="!uploadedCsvFile"
						@click="createTranslations"
					>
						Upload Template
					</PrimaryActionButton>
				</div>
			</div>
		</template>
	</Modal>
</template>

<script>
/* eslint-disable max-lines */
import { mapActions, mapGetters } from 'vuex'
import Modal from '../../../../../../../../shared/components/modal.vue'
import Dropdown from '../../../../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../../shared/components/icon-button.vue'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS } from '../../../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		Modal,
		Dropdown,
		IconButton,
		PrimaryActionButton
	},
	props: {
		preBanner: {
			type: Object,
			default: null
		},
		banner: {
			type: Object,
			default: null
		},
		bannerCategories: {
			type: Array,
			default: null
		},
		bannerScripts: {
			type: Array,
			default: null
		}
	},
	data () {
		return {
			formData: {
				language: '',
				languages: []
			},
			defaultTranslationFields: [],
			defaultTranslationKeys: [
				'preBannerText',
				'gPCBannerTitle',
				'gPCBannerText',
				'gPCAcceptAllCookiesButtonText',
				'gPCAcceptAllCookiesExGPCButtonText',
				'gPCBackButtonText',
				'acceptAllCookiesText',
				'rejectAllCookiesText',
				'customiseButtonText',
				'headerHtml',
				'logoImgSrc',
				'userConsentTabLabel',
				'legitimateInterestTabLabel',
				'optInLabel',
				'optOutLabel',
				'expandLabel',
				'collapseLabel',
				'savePreferencesText',
				'footerHtml'
			],
			formattedCategories: [],
			formattedScripts: [],
			csvData: {},
			displayUploadCSV: false,
			uploadedCsvFile: null,
			newTranslations: [],
			retainedIdForTranslation: 0,
			defaultTranslationText: 'Translate within quotes'
		}
	},
	computed: {
		...mapGetters('headers', ['headers']),
		...mapGetters('footers', ['footers']),
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('languages', ['assignedLanguages']),
		...mapGetters('consentBanners', ['currentBannerExistingTranslations', 'currentBannerTranslationDetails']),
		availableLanguages () {
			// Filter already selected categories from those available to select
			if (this.formData.languages.length === 0) return this.assignedLanguages

			const selectedLanguages = this.formData.languages.map(x => {
				return x.languageName
			})
			const availableLanguages = this.assignedLanguages.filter(
				x => !selectedLanguages.includes(x.languageName)
			)
			return availableLanguages
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		}
	},
	async mounted () {
		await this.populateAlreadySelectedLanguages()
		this.formatCategoriesAndScriptsForCSV()
	},
	methods: {
		...mapActions('consentBanners', [
			'getTranslation',
			'setTranslation',
			'getAllTranslations'
		]),
		...mapGetters('headers', ['getHeaders']),
		...mapGetters('footers', ['getFooters']),
		async populateAlreadySelectedLanguages () {
			for (const translation of this.currentBannerExistingTranslations) {
				const language = {
					languageName: translation.languageName,
					languageCode: translation.languageCode,
					languageID: translation.languageId,
					alreadySelected: true
				}
				this.formData.languages.push(language)

				const getTranslationObject = {
					consentBannerId: this.$route.params.cookieBannerId,
					languageId: language.languageID
				}
				await this.getTranslation(getTranslationObject)
			}
		},
		getTranslatedText (fieldName, languageId) {
			// Return the saved translation for a field/language combination
			const selectedLanguageTranslationDetails = this.currentBannerTranslationDetails.find(
				x => x.languageId === languageId
			)
			// Some field names don't match the database column names...
			if (fieldName === 'headerHtml') {
				// If the sublevel in the array is null, return the default text
				return selectedLanguageTranslationDetails.headerTranslation
					? selectedLanguageTranslationDetails.headerTranslation.headerHtml
					: this.defaultTranslationText
			}
			if (fieldName === 'footerHtml') {
				// If the sublevel in the array is null, return the default text
				return selectedLanguageTranslationDetails.footerTranslation
					? selectedLanguageTranslationDetails.footerTranslation.footerHtml
					: this.defaultTranslationText
			}
			if (fieldName === 'logoImgSrc') {
				return selectedLanguageTranslationDetails.imageUrl
			}
			if (fieldName === 'userConsentTabLabel') {
				return selectedLanguageTranslationDetails.userConsentTabText
			}
			if (fieldName === 'legitimateInterestTabLabel') {
				return selectedLanguageTranslationDetails.legitimateInterestTabText
			}
			if (fieldName === 'optInLabel') {
				return selectedLanguageTranslationDetails.consentOptInText
			}
			if (fieldName === 'optOutLabel') {
				return selectedLanguageTranslationDetails.consentOptOutText
			}
			if (fieldName === 'expandLabel') {
				return selectedLanguageTranslationDetails.viewCookiesText
			}
			if (fieldName === 'collapseLabel') {
				return selectedLanguageTranslationDetails.hideCookiesText
			}
			if (fieldName === 'customiseButtonText') {
				return selectedLanguageTranslationDetails.viewAllOptionsText
			}
			// Return string if field names match
			return selectedLanguageTranslationDetails[fieldName]
		},
		getCategoryTranslation (fieldName, languageId, categoryId) {
			const selectedLanguageTranslationDetails = this.currentBannerTranslationDetails.find(
				x => x.languageId === languageId
			)
			const selectedCategoryTranslationDetails = selectedLanguageTranslationDetails.channelCookieCategoryTranslation.find(
				y => y.cookieCategoryId === categoryId
			)
			// Will get an undefined here if a new cookie category has been added.
			if (selectedCategoryTranslationDetails === undefined) {
				return this.defaultTranslationText
			}
			// Some field names don't match the database column names...
			if (fieldName === 'cookieCategoryName') { return selectedCategoryTranslationDetails.categoryText }
			if (fieldName === 'cookieCategoryDisplayText') { return selectedCategoryTranslationDetails.cookieCategoryStatementText }
			// Return string if field names match
			return selectedCategoryTranslationDetails[fieldName]
		},
		getScriptTranslation (fieldName, languageId, scriptId) {
			const selectedLanguageTranslationDetails = this.currentBannerTranslationDetails.find(
				x => x.languageId === languageId
			)
			if (selectedLanguageTranslationDetails === undefined) {
				return this.defaultTranslationText
			}
			if (!selectedLanguageTranslationDetails.channelCookieScriptTranslation) {
				return this.defaultTranslationText
			}
			const selectedScriptTranslationDetails = selectedLanguageTranslationDetails.channelCookieScriptTranslation.find(
				y => y.cookieScriptChannelId === scriptId
			)
			// Will get an undefined here if a new cookie category has been added.
			if (selectedScriptTranslationDetails === undefined) {
				return this.defaultTranslationText
			}
			// Some field names don't match the database column names...
			if (fieldName === 'cookieScriptName') { return selectedScriptTranslationDetails.cookieName }
			if (fieldName === 'cookieScriptDisplayText') { return selectedScriptTranslationDetails.cookieScriptText }
			// Return string if field names match
			return selectedScriptTranslationDetails[fieldName]
		},
		formatCategoriesAndScriptsForCSV (categories, scripts) {
			// Pre Banner Data
			if (this.preBanner.preBannerText) {
				this.defaultTranslationFields.push([
					'preBannerText',
					'"""' + this.preBanner.preBannerText + '"""'
				])
			}
			if (this.preBanner.acceptAllCookiesText) {
				this.defaultTranslationFields.push([
					'acceptAllCookiesText',
					'"""' + this.preBanner.acceptAllCookiesText + '"""'
				])
			}
			if (this.preBanner.rejectAllCookiesText) {
				this.defaultTranslationFields.push([
					'rejectAllCookiesText',
					'"""' + this.preBanner.rejectAllCookiesText + '"""'
				])
			}
			if (this.preBanner.viewAllOptionsText) {
				this.defaultTranslationFields.push([
					'customiseButtonText',
					'"""' + this.preBanner.viewAllOptionsText + '"""'
				])
			}

			// Banner Data
			const matchingHeader = this.headers.find(
				x => x.id === this.banner.headerId
			)
			const matchingFooter = this.footers.find(
				x => x.id === this.banner.footerId
			)

			if (matchingHeader) {
				this.defaultTranslationFields.push([
					'headerHtml',
					'"""' + matchingHeader.headerHtml + '"""'
				])
			}
			if (matchingFooter) {
				this.defaultTranslationFields.push([
					'footerHtml',
					'"""' + matchingFooter.footerHtml + '"""'
				])
			}
			if (this.banner.logoImgSrc) {
				this.defaultTranslationFields.push([
					'logoImgSrc',
					'"""' + this.banner.logoImgSrc + '"""'
				])
			}
			if (this.banner.userConsentTabLabel) {
				this.defaultTranslationFields.push([
					'userConsentTabLabel',
					'"""' + this.banner.userConsentTabLabel + '"""'
				])
			}
			if (this.banner.legitimateInterestTabLabel) {
				this.defaultTranslationFields.push([
					'legitimateInterestTabLabel',
					'"""' + this.banner.legitimateInterestTabLabel + '"""'
				])
			}
			if (this.banner.optInLabel) {
				this.defaultTranslationFields.push([
					'optInLabel',
					'"""' + this.banner.optInLabel + '"""'
				])
			}
			if (this.banner.optOutLabel) {
				this.defaultTranslationFields.push([
					'optOutLabel',
					'"""' + this.banner.optOutLabel + '"""'
				])
			}
			if (this.banner.expandLabel) {
				this.defaultTranslationFields.push([
					'expandLabel',
					'"""' + this.banner.expandLabel + '"""'
				])
			}
			if (this.banner.collapseLabel) {
				this.defaultTranslationFields.push([
					'collapseLabel',
					'"""' + this.banner.collapseLabel + '"""'
				])
			}
			if (this.banner.savePreferencesText) {
				this.defaultTranslationFields.push([
					'savePreferencesText',
					'"""' + this.banner.savePreferencesText + '"""'
				])
			}
			if (this.bannerCategories.length > 0) {
				// Categories Data
				this.bannerCategories.forEach(category => {
					this.formattedCategories.push([
						'cookieCategoryId',
						category.cookieCategoryId
					])
					this.formattedCategories.push([
						'cookieCategoryName',
						'"""' + category.cookieCategoryName + '"""'
					])
					this.formattedCategories.push([
						'cookieCategoryDisplayText',
						'"""' + category.displayText + '"""'
					])
				})
			}
			if (this.bannerScripts.length > 0) {
				// Scripts Data
				this.bannerScripts.forEach(script => {
					this.formattedScripts.push([
						'cookieScriptChannelId',
						script.cookieScriptChannelId
					])
					this.formattedScripts.push([
						'cookieScriptName',
						'"""' + script.cookieScriptName + '"""'
					])
					this.formattedScripts.push([
						'cookieScriptDisplayText',
						'"""' + script.displayText + '"""'
					])
					this.formattedScripts.push([
						'cookieUrl',
						'"""' + script.infoURL + '"""'
					])
				})
			}
		},
		addLanguage (language) {
			this.formData.languages.push(language)
			this.formData.language = ''
		},
		removeLanguage (language) {
			const index = this.formData.languages.findIndex(
				lang => lang.languageName === language.languageName
			)
			this.formData.languages.splice(index, 1)
		},
		generateCSVTemplate () {
			// Split languages into arrays so they have separate rows in the csv
			const languageNames = []
			const languageCodes = ['languageCode', '-']
			const languageIds = ['languageId', '-']

			this.formData.languages.forEach(language => {
				languageNames.push(language.languageName)
				languageCodes.push(language.languageCode)
				languageIds.push(language.languageID)
			})
			// Concatenate into a string with comma separators
			let csv = 'FieldName,DefaultLanguage,' + languageNames.join(',') + '\n'
			csv += languageCodes.join(',')
			csv += '\n'
			csv += languageIds.join(',')
			csv += '\n'
			// Add a row for each field to be translated
			this.defaultTranslationFields.forEach(field => {
				csv += field
				languageNames.forEach(lang => {
					// If translation already exists, get the translated text
					const alreadySelected = this.formData.languages.find(
						x => x.languageName === lang
					).alreadySelected
					if (alreadySelected) {
						const languageId = this.formData.languages.find(
							x => x.languageName === lang
						).languageID
						const translation = this.getTranslatedText(field[0], languageId)
						csv += ',"""' + translation + '"""'
					} else {
						// Otherwise populate with default instructions
						csv += ',"""' + this.defaultTranslationText + '"""'
					}
				})
				csv += '\n'
			})
			// Add a row for each category Id, Name and Description
			this.formattedCategories.forEach(category => {
				csv += category
				languageNames.forEach(lang => {
					// on the category id row we autofill the id for each language
					if (category[0] === 'cookieCategoryId') {
						csv += ',' + category[1]
						// Need access to the Id outside of the loop for the associated name/decsription
						this.retainedIdForTranslation = category[1]
					} else {
						// If translation already exists, get the translated text
						const alreadySelected = this.formData.languages.find(
							x => x.languageName === lang
						).alreadySelected
						if (alreadySelected) {
							const languageId = this.formData.languages.find(
								x => x.languageName === lang
							).languageID
							const translation = this.getCategoryTranslation(
								category[0],
								languageId,
								this.retainedIdForTranslation
							)
							csv += ',"""' + translation + '"""'
						} else {
							// Otherwise populate with default instructions
							csv += ',"""' + this.defaultTranslationText + '"""'
						}
					}
				})
				csv += '\n'
			})
			// Add a row for each script Id, Name and Description
			this.formattedScripts.forEach(script => {
				csv += script
				languageNames.forEach(lang => {
					// on the script channel id row we autofill the id for each language
					if (script[0] === 'cookieScriptChannelId') {
						csv += ',' + script[1]
						// Need access to the Id outside of the loop for the associated name/decsription
						this.retainedIdForTranslation = script[1]
					} else {
						// If translation already exists, get the translated text
						const alreadySelected = this.formData.languages.find(
							x => x.languageName === lang
						).alreadySelected
						if (alreadySelected) {
							const languageId = this.formData.languages.find(
								x => x.languageName === lang
							).languageID
							const translation = this.getScriptTranslation(
								script[0],
								languageId,
								this.retainedIdForTranslation
							)
							csv += ',"""' + translation + '"""'
						} else {
							// Otherwise populate with default instructions
							csv += ',"""' + this.defaultTranslationText + '"""'
						}
					}
				})
				csv += '\n'
			})

			// Turn our big string into a csv and download it
			const hiddenElement = document.createElement('a')
			hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
			hiddenElement.target = '_blank'
			hiddenElement.download =
        'consent banner ' +
        this.$route.params.cookieBannerId +
        ' translation template.csv'
			hiddenElement.click()

			this.displayUploadCSV = true
		},
		readCsvFile (e) {
			this.uploadedCsvFile = e
			const reader = new FileReader()
			reader.readAsBinaryString(e)
			reader.onload = e => {
				let csv = reader.result
				// remove any line breaks occuring before quoted translation text.
				while (csv.search(/,"\r?\n/) !== -1) {
					csv = csv.replace(/,"\r?\n/, ',"')
				}
				const lines = csv.split((/\r?\n/g))
				const headers = lines[0].split(',')

				headers.splice(1, 1)
				if (!headers[0]) {
					headers.forEach(header => {})
				}

				const allRows = []
				lines.forEach(line => {
					if (line.length !== 0) {
						// Regex below splits at comma if not in double quotes
						const lineArray = Array.from(
							line.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)
						)
						lineArray.splice(1, 1)
						allRows.push(lineArray)
						// this.translationKeys.push(lineArray[0])
					}
				})
				this.formatTranslationsFromCSV(allRows)
			}
		},
		formatTranslationsFromCSV (rows) {
			const languages = []
			const categoryStorage = []
			const categoryId = 'cookieCategoryId'
			const scriptChannelId = 'cookieScriptChannelId'
			const scriptStorage = []

			rows.forEach((row, index) => {
				if (index === 0) {
					row.forEach((column, index) => {
						if (index === 0) return
						languages.push({
							languageCode: column,
							index,
							categories: [],
							scripts: []
						})
					})
					return
				}
				const key = row[0]
				languages.forEach(lang => {
					// Register Array for each Language
					if (categoryStorage[lang.index] == null) {
						categoryStorage[lang.index] = []
					}
					if (scriptStorage[lang.index] == null) {
						scriptStorage[lang.index] = []
					}
					const categoryName = 'cookieCategoryName'
					const categoryDisplayText = 'cookieCategoryDisplayText'
					const scriptName = 'cookieScriptName'
					const scriptDisplayText = 'cookieScriptName'
					const scriptUrl = 'cookieUrl'
					// const cookieScript = key.includes('CookieScript')
					const isCookieCategoryKey = key.includes('cookieCategory')
					const isCookieScriptKey = key.includes('cookieScript')
					const isCookieUrl = key.includes('cookieUrl')
					if (isCookieCategoryKey) {
						const languageCategories = categoryStorage[lang.index]
						// If empty push empty Category
						if (languageCategories.length === 0) {
							languageCategories.push({})
						}
						// Get last Category in the array
						const category = languageCategories[languageCategories.length - 1]
						// Populate Category
						category[key] = row[lang.index]
						// If all fields are field create new empty category to be filled.
						if (
							categoryId in category &&
              categoryName in category &&
              categoryDisplayText in category
						) {
							languageCategories.push({})
						}
					}	else if (isCookieScriptKey || isCookieUrl) {
						const languageScripts = scriptStorage[lang.index]
						// If empty push empty Script
						if (languageScripts.length === 0) {
							languageScripts.push({})
						}
						// Get last Script in the array
						const script = languageScripts[languageScripts.length - 1]
						// Populate Script
						script[key] = row[lang.index]
						// If all fields are field create new empty script to be filled.
						if (
							scriptChannelId in script &&
              scriptName in script &&
              scriptDisplayText in script &&
              scriptUrl in script
						) {
							languageScripts.push({})
						}
					} else {
						lang[key] = row[lang.index]
					}
				})
			})
			languages.forEach(e => {
				const languageCategories = categoryStorage[e.index]
				e.categories = languageCategories.filter(
					category => category.cookieCategoryId
				)
				const languageScripts = scriptStorage[e.index]
				e.scripts = languageScripts.filter(
					script => script.cookieScriptChannelId
				)

				JSON.stringify(e.categories)
				JSON.stringify(e.scripts)
				this.tidyStrings(e)
				delete e.index
			})
			this.newTranslations = languages
		},
		tidyStrings (language) {
			for (const property in language) {
				if (typeof language[property] === 'string') {
					language[property] = language[property].replace(
						// eslint-disable-next-line no-useless-escape
						/\"/g,
						''
					)
				}
			}

			language.categories.forEach(category => {
				for (const property in category) {
					if (typeof category[property] === 'string') {
						category[property] = category[property].replace(
							// eslint-disable-next-line no-useless-escape
							/\"/g,
							''
						)
					}
				}
			})
			language.scripts.forEach(script => {
				for (const property in script) {
					if (typeof script[property] === 'string') {
						script[property] = script[property].replace(
							// eslint-disable-next-line no-useless-escape
							/\"/g,
							''
						)
					}
				}
			})
		},
		async createTranslations () {
			for (const translation of this.newTranslations) {
				translation.consentBannerId = this.$route.params.cookieBannerId
				this.defaultTranslationKeys.forEach(key => {
					if (!(key in translation)) {
						translation[key] = ''
					}
				})
				await this.setTranslation(translation)
			}

			await this.getAllTranslations(this.$route.params.cookieBannerId)
			this.$emit('closeTranslationModal')
		}
	}
}
</script>

<style lang="scss" scoped>
.cassie-overlay-card {
  max-height: 75vh;
  display: flex !important;
  flex-direction: column;
  overflow-y: auto !important;
}
</style>
