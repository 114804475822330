<template>
	<!-- eslint-disable max-lines-->
	<div>
		<v-row class="mx-1">
			<v-col cols="7">
				<v-tabs
					v-show="previewTypes.length > 1"
					v-model="selectedPreview"
					background-color="blue darken-2"
					color="white"
					:class="$style.cassie_preview_type_tabs_container"
					:active-class="$style.cassie_preview_type_tab_active"
					:show-arrows="false"
					grow
					:value="selectedPreview"
				>
					<v-tab
						v-for="(previewType, index) in previewTypes"
						:key="index"
						:href="'#'+previewType.value"
						:class="$style.cassie_preview_type_tab"
					>
						{{ previewType.text }}
					</v-tab>
				</v-tabs>
			</v-col>
			<v-spacer />
			<v-col>
				<div class="float-right">
					<v-tabs
						hide-slider
						:class="$style.cassie_device_type_tabs_container"
						:active-class="$style.cassie_device_type_tab_active"
						height="30"
						color="blue darken-2"
					>
						<v-tab
							v-for="deviceType in deviceTypes"
							:key="deviceType"
							:class="$style.cassie_device_type_tab"
							class="px-1 font-weight-bold"
							@click="selectedDevice = deviceType.toLowerCase()"
						>
							{{ deviceType }}
						</v-tab>
					</v-tabs>
				</div>
			</v-col>
		</v-row>
		<v-tabs-items v-model="selectedPreview">
			<v-tab-item
				v-if="!hidePreBannerTab"
				value="0"
				transition="none"
			>
				<v-row>
					<v-col
						cols="12"
						class="px-6"
					>
						<div
							:class="[
								$style.cassie_preview_container,
								$style['cassie_preview_container--' + selectedDevice],
							]"
							class="d-flex position-relative justify-center mx-auto"
						>
							<div
								:class="[
									selectedAppearance,
									$style['cassie-pre-banner--' + selectedDevice],
								]"
								:style="{
									backgroundColor: colourScheme.BackgroundColour,
									borderColor: colourScheme.SecondaryColour,
								}"
								class="d-flex  flex-wrap"
							>
								<p
									v-dompurify-html="preBannerText"
									:class="[
										$style['cassie-pre-banner-text'],
										$style['cassie-pre-banner-text' + '--' + appearance],
										$style['cassie-pre-banner-text' + '--' + selectedDevice],
									]"
									:style="{
										color: colourScheme.TextColour,
									}"
								/>
								<div
									:class="[
										$style[
											'cassie-pre-banner-button-container' + '--' + appearance
										],
										$style[
											'cassie-pre-banner-button-container' + '--' + selectedDevice
										],
									]"
								>
									<button
										v-if="!hideAcceptAll"
										id="cassie_accept_all_pre_banner"
										:class="[
											$style['cassie-accept-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + appearance],
											$style['cassie-pre-banner-button' + '--' + selectedDevice],
										]"
										:style="{
											color: colourScheme.AcceptAllButtonTextColour,
											backgroundColor: colourScheme.AcceptAllButtonColour,
											borderColor: colourScheme.AcceptAllButtonColour,
											poinerEvents: 'none',
										}"
										disabled
									>
										{{ acceptAllText }}
									</button>
									<button
										v-if="!hideRejectAll"
										id="cassie_reject_all_pre_banner"
										:class="[
											$style['cassie-accept-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + appearance],
										]"
										:style="{
											color: colourScheme.RejectAllButtonTextColour,
											backgroundColor: colourScheme.RejectAllButtonColour,
											borderColor: colourScheme.RejectAllButtonColour,
										}"
										disabled
									>
										{{ rejectAllText }}
									</button>
									<button
										v-show="!hideViewOptions"
										:class="[
											$style['cassie-view-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + appearance],
										]"
										:style="{
											color: colourScheme.CustomiseAllButtonTextColour,
											backgroundColor: colourScheme.CustomiseAllButtonColour,
											borderColor: colourScheme.CustomiseAllButtonColour,
										}"
										disabled
									>
										<span :class="$style['cassie-view-all--button--icon']">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												enable-background="new 0 0 24 24"
												height="16"
												viewBox="0 0 24 24"
												width="16"
											>
												<g>
													<path
														d="M0,0h24v24H0V0z"
														fill="none"
													/>
													<path
														class="cassie-view-all--button--icon"
														d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
													/>
												</g>
											</svg>
										</span>
										<span
											:id="$style.cassie_view_all_pre_banner"
										>{{ viewOptionsText }}
										</span>
									</button>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item
				v-if="!hideGpcBannerTab"
				value="1"
				transition="none"
			>
				<v-row>
					<v-col
						cols="12"
						class="px-6"
					>
						<div
							:class="[
								$style.cassie_preview_container,
								$style['cassie_preview_container--' + selectedDevice],
							]"
							class="d-flex position-relative justify-center mx-auto"
						>
							<div
								:class="[
									selectedGpcAppearance,
									$style['cassie-pre-banner--' + selectedDevice],
								]"
								:style="{
									backgroundColor: gpcBannerData.colourSchemeJson.BackgroundColour,
									borderColor: gpcBannerData.colourSchemeJson.SecondaryColour,
								}"
								class="d-flex flex-wrap"
							>
								<div
									:class="[
										$style['cassie-pre-banner-text'],
										$style['cassie-pre-banner-text' + '--' + gpcAppearance],
										$style['cassie-pre-banner-text' + '--' + selectedDevice],
										'pl-0'
									]"
									:style="{
										color: gpcBannerData.colourSchemeJson.TextColour,
									}"
								>
									<p
										v-dompurify-html="gpcBannerData.gpcBannerContentTitle"
										:class="[
											$style['cassie-pre-banner-text'],
											$style['cassie-pre-banner-text' + '--' + gpcAppearance],
											$style['cassie-pre-banner-text' + '--' + selectedDevice],
											'font-weight-bold'
										]"
										:style="{
											color: gpcBannerData.colourSchemeJson.TextColour,
										}"
									/>
									<p
										v-dompurify-html="gpcBannerData.gpcBannerContent"
										:class="[
											$style['cassie-pre-banner-text'],
											$style['cassie-pre-banner-text' + '--' + gpcAppearance],
											$style['cassie-pre-banner-text' + '--' + selectedDevice]
										]"
										:style="{
											color: gpcBannerData.colourSchemeJson.TextColour,
										}"
									/>
								</div>
								<div
									:class="[
										$style[
											'cassie-pre-banner-button-container' + '--' + gpcAppearance
										],
										$style[
											'cassie-pre-banner-button-container' + '--' + selectedDevice
										],
									]"
								>
									<button
										v-if="!gpcBannerData.hideAcceptAllButton"
										id="cassie_accept_all_pre_banner"
										:class="[
											$style['cassie-accept-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + gpcAppearance],
											$style['cassie-pre-banner-button' + '--' + selectedDevice],
										]"
										:style="{
											color: gpcBannerData.colourSchemeJson.AcceptAllButtonTextColour,
											backgroundColor: gpcBannerData.colourSchemeJson.AcceptAllButtonColour,
											borderColor: gpcBannerData.colourSchemeJson.SecondaryColour,
											poinerEvents: 'none',
										}"
										disabled
									>
										{{ gpcBannerData.acceptAllCookiesButtonText }}
									</button>
									<button
										v-if="!gpcBannerData.hideacceptAllCookiesExcGpcButton"
										id="cassie_reject_all_pre_banner"
										:class="[
											$style['cassie-accept-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + gpcAppearance],
										]"
										:style="{
											color: gpcBannerData.colourSchemeJson.AcceptAllExcGpcButtonTextColour,
											backgroundColor: gpcBannerData.colourSchemeJson.AcceptAllExcGpcButtonColour,
											borderColor: gpcBannerData.colourSchemeJson.SecondaryColour,
										}"
										disabled
									>
										{{ gpcBannerData.acceptAllCookiesExcGpcButtonText }}
									</button>
									<button
										v-if="!gpcBannerData.hideBackButton"
										:class="[
											$style['cassie-view-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + gpcAppearance],
										]"
										:style="{
											color: gpcBannerData.colourSchemeJson.BackButtonTextColour,
											backgroundColor: gpcBannerData.colourSchemeJson.BackButtonColour,
											borderColor: gpcBannerData.colourSchemeJson.SecondaryColour,
										}"
										disabled
									>
										<span
											:id="$style.cassie_view_all_pre_banner"
										>{{ gpcBannerData.backButtonText }}
										</span>
									</button>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item
				v-if="!hideCookieSelect"
				value="2"
				transition="none"
			>
				<v-row>
					<v-col
						cols="12"
						class="px-6"
					>
						<div
							:class="[
								$style.cassie_preview_container,
								$style['cassie_preview_container--' + selectedDevice],
							]"
							class="d-flex position-relative align-end mx-auto"
						>
							<button
								id="cassie_manage_cookies_button"
								:class="[$style['cassie-manage-cookies--button']]"
								:style="{
									color: colourScheme.TextColour,
									backgroundColor: colourScheme.BackgroundColour,
									borderColor: colourScheme.SecondaryColour,
								}"
								disabled
							>
								Open Cookie Settings
							</button>
						</div>
					</v-col>
				</v-row>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
/* eslint-disable max-lines */
export default {
	name: 'cookie-pre-banner-preview',
	props: {
		appearance: {
			type: String,
			default: 'center',
			validator: value => {
				return ['center', 'bottom', 'top'].indexOf(value) > -1
			}
		},
		gpcAppearance: {
			type: String,
			default: 'center',
			validator: value => {
				return ['center', 'bottom', 'top'].indexOf(value) > -1
			}
		},
		preBannerText: {
			type: String,
			default: ''
		},
		hideAcceptAll: {
			type: Boolean,
			default: false
		},
		hideRejectAll: {
			type: Boolean,
			default: false
		},
		hideViewOptions: {
			type: Boolean,
			default: false
		},
		acceptAllText: {
			type: String,
			default: 'Accept All'
		},
		rejectAllText: {
			type: String,
			default: 'Reject All'
		},
		viewOptionsText: {
			type: String,
			default: 'View Options'
		},
		colourScheme: {
			type: Object,
			default: null
		},
		gpcBannerData: {
			type: Object,
			default: null
		},
		hideCookieSelect: {
			type: Boolean,
			default: false
		},
		hidePreBannerTab: {
			type: Boolean,
			default: false
		},
		hideGpcBannerTab: {
			type: Boolean,
			default: true
		}
	},
	data () {
		return {
			deviceTypes: ['Desktop', 'Tablet', 'Mobile'],
			selectedDevice: 'desktop',
			selectedPreview: '0',
			preBannerPreviewTypes: [
				{ text: 'Pre-Banner Preview', value: '0' },
				{ text: 'GPC Banner Preview', value: '1' },
				{ text: 'Cookie Selection Button Preview', value: '2' }
			]
		}
	},
	computed: {
		selectedAppearance () {
			let selectedAppearance = ''
			switch (this.appearance) {
				case 'bottom':
					selectedAppearance = this.$style['cassie-pre-banner--bottom']
					break
				case 'center':
					selectedAppearance = this.$style['cassie-pre-banner--center']
					break
				case 'top':
					selectedAppearance = this.$style['cassie-pre-banner--top']
					break
				default:
					selectedAppearance = this.$style['cassie-pre-banner--center']
					break
			}
			return selectedAppearance
		},
		selectedGpcAppearance () {
			let selectedAppearance = ''
			switch (this.gpcBannerData.gpcBannerLocation) {
				case 0:
					selectedAppearance = this.$style['cassie-pre-banner--top']
					break
				case 1:
					selectedAppearance = this.$style['cassie-pre-banner--center']
					break
				case 2:
					selectedAppearance = this.$style['cassie-pre-banner--bottom']
					break
				default:
					selectedAppearance = this.$style['cassie-pre-banner--center']
					break
			}
			return selectedAppearance
		},
		previewTypes () {
			const preBannerPreview = '0'
			const gpcBannerPreview = '1'
			const cookieButtonPreview = '2'
			let previewTypes = this.preBannerPreviewTypes
			if (this.hidePreBannerTab) {
				previewTypes = previewTypes.filter(
					previewType => previewType.value !== preBannerPreview
				)
			}
			if (this.hideGpcBannerTab) {
				previewTypes = previewTypes.filter(
					previewType => previewType.value !== gpcBannerPreview
				)
			}
			if (this.hideCookieSelect) {
				previewTypes = previewTypes.filter(
					previewType => previewType.value !== cookieButtonPreview
				)
			}
			return previewTypes
		}
	}
}
</script>

<style lang="scss" module>
.cassie-pre-banner-button {
  width: 70%;
  padding: 10px 0;
  margin: 8px 0;
  font-family: 'poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: var(--cassie-preview-text-color);
  overflow-wrap: break-word;
  cursor: pointer;
  border-color: var(--cassie-preview-primary-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}
.cassie-pre-banner-button--bottom {
  width: 30%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 2%;
  margin-left: 2%;
}
.cassie-pre-banner-button--top {
  width: 30%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 2%;
  margin-left: 2%;
}
.cassie-pre-banner .cassie-view-all {
  margin-top: 20px;
  background-color: var(--cassie-preview-primary-color);
  border: 0;
  border-color: var(--cassie-preview-primary-color);
  border-bottom: 1px solid;
}
.cassie-view-all--button--icon {
  vertical-align: middle;
  fill: #fff;
}
.cassie-pre-banner .cassie-accept-all {
  color: #fff;
  background-color: #071725;
  border: 1px solid;
  border-color: var(--cassie-preview-primary-color);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
}
.cassie-pre-banner--bottom {
  align-self: flex-end;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--cassie-preview-secondary-color);
  border-right-color: var(--cassie-preview-primary-color);
  border-right-style: solid;
  border-right-width: 15px;
  border-left-color: var(--cassie-preview-primary-color);
  border-left-style: solid;
  border-left-width: 15px;
}
.cassie-pre-banner--top {
  align-self: flex-start;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--cassie-preview-secondary-color);
  border-right-color: var(--cassie-preview-primary-color);
  border-right-style: solid;
  border-right-width: 15px;
  border-left-color: var(--cassie-preview-primary-color);
  border-left-style: solid;
  border-left-width: 15px;
}
.cassie-pre-banner-text {
  align-self: center;
  font-size: 13px;
  color: var(--cassie-preview-text-color);
}
.cassie-pre-banner-text--mobile {
  flex: 100%;
}
.cassie-pre-banner-text--tablet {
  flex: 100%;
}
.cassie-pre-banner-text--desktop {
  flex: 60%;
}
.cassie-pre-banner-text--bottom {
  margin-bottom: 0 !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
}
.cassie-pre-banner-button-container--mobile {
  flex: 100%;
}
.cassie-pre-banner-button-container--tablet {
  flex: 100%;
}
.cassie-pre-banner-button-container--desktop {
  flex: 40%;
}
.cassie-pre-banner-button-container--top {
  display: flex;
  flex-direction: row;
  width: 40%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--cassie-preview-text-color);
}
.cassie-pre-banner-button-container--bottom {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--cassie-preview-text-color);
}
.cassie-pre-banner--center {
  flex-wrap: nowrap;
  align-self: center;
  justify-content: center;
  width: 100%;
  min-height: 15rem;
  padding: 20px 0;
  margin: 0 30%;
  background-color: var(--cassie-preview-secondary-color);
  border-right: 15px solid;
  border-right-color: var(--cassie-preview-primary-color);
  border-left: 15px solid;
  border-left-color: var(--cassie-preview-primary-color);
  border-radius: 10px;
}
.cassie-pre-banner--mobile {
  margin: 0;
}
.cassie-pre-banner-text--center {
  padding-left: 2rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--cassie-preview-text-color);
}
.flex-wrap {
  flex-wrap: wrap;
}
.cassie-pre-banner-text--desktop {
  width: 60%;
}
.cassie-pre-banner-text--mobile {
  width: 100%;
}
.cassie-pre-banner-text--tablet {
  width: 100%;
}
.cassie-pre-banner-button-container--center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 40%;
  margin: 0;
}

.cassie_preview_type_tabs_container {
  border-radius: 5px;
}
.cassie_preview_type_tab {
  font-size: 10px;
  color: white !important;
  text-transform: var(--cassie-tab-text-transform);
}

.cassie_preview_type_tab_active {
  background: var(--cassie-tab-active-preview);
  border-radius: 5px;
}
.cassie_preview_type_tab_active::before {
  opacity: 0.12;
}

.cassie_device_type_tab_active {
  color: white !important;
  background: var(--global-color-blue-darken-2);
}

.cassie_device_type_tab {
  margin: 0 10px;
  font-size: 10px;
  color: var(--global-color-blue-darken-2);
  border: 1px solid var(--global-color-blue-darken-2);
  border-radius: 5px;
}
p {
  margin-bottom: 0%;
}
.cassie_preview_container {
  width: 100%;
  height: 500px;
	background-color: rgba(0,0,0,.7);
  box-shadow: inset 0 0 0 2000px rgba(179, 173, 176, 0.3);
}
.cassie_preview_container--tablet {
  width: 100%;
  max-width: 1024px;
}
.cassie_preview_container--mobile {
  width: 100%;
  max-width: 414px;
}

.cassie-manage-cookies--button {
  max-width: 150px;
  min-height: 44px;
  max-height: 50px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  padding: 0 10px;
  margin: 10px;
}
</style>
