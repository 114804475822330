var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationForm',{ref:"validationForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('div',{staticClass:"cassie-vertical-md"},[(!_vm.bannerHasCategories)?_c('SectionCard',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-alert',{attrs:{"light":"","border":"bottom","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" This banner does not currently contain any categories. Please go back and add a category from the 'Banner' step. ")])]},proxy:true}],null,true)}):_vm._e(),_vm._l((_vm.currentBannerCategories),function(category){return _c('SectionCard',{key:category.cookieCategoryId,scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(category.cookieCategoryName)+" ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(category.cookieCategoryName)+" Category Text ")]},proxy:true},{key:"title-action",fn:function(){return [(_vm.userCanCreateUpdate)?_c('TextButton',{on:{"click":function($event){return _vm.openCreateScriptModal(category.cookieCategoryId)}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Create Script ")],1):_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"d-flex flex-column cassie-vertical-md"},[_c('div',{staticClass:"d-flex flex-row cassie-horizontal-sm"},[_c('Dropdown',{staticClass:"cassie-input-width-md",attrs:{"label":category.cookieCategoryName + ' Cookie Scripts',"disabled":!_vm.userCanCreateUpdate,"multiple":"","select-all":"","items":_vm.availableCookieScripts.filter(
										function (script) { return script.cookieCategoryId === category.cookieCategoryId; }
									),"item-text":"cookieScriptName","item-value":"cookieScriptChannelId","return-object":""},on:{"change":function($event){_vm.updateSelectAllChecked(_vm.availableCookieScripts.filter(
									function (script) { return script.cookieCategoryId === category.cookieCategoryId; }
								))}},scopedSlots:_vm._u([(_vm.showSelectAll(category.cookieCategoryId))?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){_vm.toggleSelectAll(_vm.availableCookieScripts.filter(
											function (script) { return script.cookieCategoryId === category.cookieCategoryId; }
										))}}},[_c('v-checkbox',{attrs:{"dense":""},model:{value:(_vm.selectAllChecked),callback:function ($$v) {_vm.selectAllChecked=$$v},expression:"selectAllChecked"}}),_c('v-list-item-title',{staticClass:"pb-2"},[_vm._v(" Select All ")])],1),_c('v-divider')]},proxy:true}:null],null,true),model:{value:(_vm.selectedCookieScripts),callback:function ($$v) {_vm.selectedCookieScripts=$$v},expression:"selectedCookieScripts"}}),(_vm.userCanCreateUpdate)?_c('PrimaryActionButton',{on:{"click":function($event){return _vm.addScriptToBanner(_vm.formData.selectedScript)}}},[_vm._v(" Add ")]):_vm._e()],1),(_vm.selectedScripts(category.cookieCategoryId).length === 0)?_c('v-alert',{staticClass:"mb-0",attrs:{"light":""}},[_c('div',{staticClass:"grey--text lighten-4 text-center"},[_vm._v(" No Cookie Scripts have been added to this Category ")])]):_c('DataTable',{attrs:{"headers":_vm.scriptTableHeader,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disable-sort":false,"items":_vm.selectedScripts(category.cookieCategoryId),"hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.isRunFirst",fn:function(ref){
										var item = ref.item;
return [(item.isRunFirst === true)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])]}},{key:"item.gpcEnabled",fn:function(ref){
										var item = ref.item;
return [(item.gpcEnabled === true)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])]}},{key:"item.action",fn:function(ref){
										var item = ref.item;
return [_c('IconButton',{attrs:{"disabled":!_vm.userCanCreateUpdate,"tooltip-text":"Edit Script"},on:{"click":function($event){return _vm.openEditScriptModal(item, 'Script')}}},[_vm._v(" mdi-pencil ")]),(
										item.displayOrder !==
											_vm.selectedScripts(category.cookieCategoryId).length
									)?_c('IconButton',{attrs:{"disabled":!_vm.userCanCreateUpdate},on:{"click":function($event){return _vm.moveScript(item, 'moveDown')}}},[_vm._v(" mdi-arrow-down ")]):_vm._e(),(item.displayOrder !== 1)?_c('IconButton',{attrs:{"disabled":!_vm.userCanCreateUpdate},on:{"click":function($event){return _vm.moveScript(item, 'moveUp')}}},[_vm._v(" mdi-arrow-up ")]):_vm._e(),_c('IconButton',{attrs:{"tooltip-text":"Remove Script","disabled":!_vm.userCanCreateUpdate},on:{"click":function($event){return _vm.deleteScriptFromConsentBanner(item)}}},[_vm._v(" mdi-close ")])]}}],null,true)})],1)]},proxy:true}],null,true)})}),(_vm.globalCisEnabled && _vm.bannerHasCategories)?_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Cassie Identity Service (CIS) ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" Please select which cookies you wish to have protected by Cassie Identity Service (CIS) ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"d-flex flex-column cassie-vertical-sm"},[_c('div',{staticClass:"d-flex flex-row cassie-horizontal-sm"},[_c('Dropdown',{staticClass:"cassie-input-width-md",attrs:{"label":"Cookie Scripts","disabled":!_vm.userCanCreateUpdate,"items":_vm.availableCisScripts,"item-text":"cookieScriptName","item-value":"cookieScriptChannelId","return-object":""},on:{"change":function($event){return _vm.populateCookiesInScript(_vm.formData.selectedCisScript)}},model:{value:(_vm.formData.selectedCisScript),callback:function ($$v) {_vm.$set(_vm.formData, "selectedCisScript", $$v)},expression:"formData.selectedCisScript"}}),(
									_vm.availableCisCookies.length !== 0 &&
										_vm.formData.selectedCisScript.cookieScriptId > 0
								)?_c('Dropdown',{staticClass:"cassie-input-width-md",attrs:{"label":"Cookies","disabled":!_vm.userCanCreateUpdate ||
										_vm.availableCisCookies.length === 0,"items":_vm.availableCisCookies},model:{value:(_vm.formData.selectedCisCookie),callback:function ($$v) {_vm.$set(_vm.formData, "selectedCisCookie", $$v)},expression:"formData.selectedCisCookie"}}):_c('Dropdown',{staticClass:"cassie-input-width-md",attrs:{"disabled":"","label":"No Cookies are available to select."}}),(_vm.availableCisCookies.length !== 0)?_c('PrimaryActionButton',{attrs:{"disabled":!_vm.userCanCreateUpdate ||
										!_vm.formData.selectedCisCookie},on:{"click":function($event){return _vm.addCisCookieToBanner(_vm.formData.selectedCisScript)}}},[_vm._v(" Add ")]):_vm._e()],1),(_vm.formData.selectedCisCookies.length === 0)?_c('v-alert',{attrs:{"light":""}},[_c('div',{staticClass:"grey--text lighten-4 text-center"},[_vm._v(" No Cookies are currently protected by Cassie Identity Service ")])]):_c('DataTable',{attrs:{"headers":_vm.cisCookiesTableHeader,"items":_vm.formData.selectedCisCookies,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
										var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","disabled":!_vm.userCanCreateUpdate},on:{"click":function($event){return _vm.deleteCisCookie(item)}}},[_vm._v(" mdi-close ")])]}}],null,true)})],1)]},proxy:true}],null,true)}):_vm._e(),(_vm.bannerHasCategories)?_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Customise Script's Run Order ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"d-flex flex-row cassie-horizontal-sm"},[_c('v-expansion-panels',{attrs:{"accordion":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var open = ref.open;
return [(!open)?_c('span',[_vm._v(" Show Run Order ")]):_c('span',[_vm._v(" Hide Run Order ")])]}}],null,true)}),_c('v-expansion-panel-content',[_c('v-card-subtitle',[_vm._v(" Please identify the Run Order in which you wish your Cookie Scripts to execute. ")]),(_vm.formData.selectedScripts.length === 0)?_c('v-alert',[_c('div',{staticClass:"grey--text lighten-4 text-center"},[_vm._v(" No Cookies have been selected on this Consent Banner. ")])]):_c('DataTable',{attrs:{"headers":_vm.runOrderTableHeader,"sort-by":_vm.sortByRunOrder,"sort-desc":_vm.sortDesc,"disable-sort":false,"hide-default-footer":"","items-per-page":50,"items":_vm.formData.selectedScripts},scopedSlots:_vm._u([{key:"item.isRunFirst",fn:function(ref){
										var item = ref.item;
return [(item.isRunFirst === true)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])]}},{key:"item.action",fn:function(ref){
										var item = ref.item;
return [((
													item.runOrder !==
													_vm.formData.selectedScripts.length &&
													_vm.lastEssentialScriptRunFirst !== item.runOrder &&
													_vm.lastOtherScriptRunFirst !== item.runOrder &&
													_vm.lastEssentialScript !== item.runOrder
												) && _vm.userCanCreateUpdate
												)?_c('IconButton',{attrs:{"tooltip-text":"Move Down Run Order"},on:{"click":function($event){return _vm.changeRunOrder(item, 'moveDown')}}},[_vm._v(" mdi-arrow-down ")]):_vm._e(),((
													item.runOrder !== 1 &&
													_vm.lastOtherScriptRunFirst + 1 !== item.runOrder &&
													_vm.lastEssentialScript + 1 !== item.runOrder &&
													_vm.lastEssentialScriptRunFirst + 1 !==
													item.runOrder
												) && _vm.userCanCreateUpdate
												)?_c('IconButton',{attrs:{"tooltip-text":"Move Up Run Order"},on:{"click":function($event){return _vm.changeRunOrder(item, 'moveUp')}}},[_vm._v(" mdi-arrow-up ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)]},proxy:true}],null,true)}):_vm._e(),(_vm.bannerHasCategories)?_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Preview ")]},proxy:true},{key:"body",fn:function(){return [(_vm.bannerHasCategories)?_c('CookieBannerPreview',{attrs:{"appearance":_vm.cookiesPreview.selectedBannerAppearance,"colour-scheme":_vm.previewSettings.colourScheme,"settings":_vm.previewSettings,"header-html":_vm.selectedHeaderHTML,"footer-html":_vm.selectedFooterHTML}}):_vm._e()]},proxy:true}],null,true)}):_vm._e()],2),_c('PageActionRow',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('SecondaryActionButton',{on:{"click":_vm.cancelCookies}},[_vm._v(" Exit ")]),_c('v-spacer'),(_vm.lastSavedStep >= _vm.currentStep && _vm.userCanCreateUpdate)?_c('PrimaryActionButton',{staticClass:"mr-2",attrs:{"disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.proceedFromCookies)}}},[_vm._v(" Save Changes ")]):_vm._e(),_c('SecondaryActionButton',{on:{"click":_vm.backToBanner}},[_vm._v(" Back ")]),(_vm.lastSavedStep >= _vm.currentStep)?_c('SecondaryActionButton',{staticClass:"ml-2",on:{"click":_vm.nextStep}},[_vm._v(" Next ")]):(_vm.userCanCreateUpdate)?_c('PrimaryActionButton',{attrs:{"disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.proceedFromCookies)}}},[_vm._v(" Save and Continue ")]):_vm._e()]},proxy:true}],null,true)})]}}])}),(_vm.showScriptModal)?_c('ScriptForm',{attrs:{"form":_vm.formData.scriptForm,"selected-brand-id":_vm.bannerBrandId,"accessed-via-consent-banner":true},on:{"openEditModal":_vm.openEditScriptModal,"cancelForm":_vm.closeCreateScriptModal,"createNewScript":_vm.createNewScript,"updateScript":_vm.updateScript}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }