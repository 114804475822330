<template>
	<!-- eslint-disable max-lines-->
	<div
		:style="{ '--cookie-modal-active-tab': colourScheme.SliderBackgroundOn }"
	>
		<v-row class="mx-1">
			<v-spacer />
			<v-col>
				<div class="float-right">
					<v-tabs
						hide-slider
						class="cassie-device-type-tabs-container"
						active-class="cassie-device-type-tab-active"
						height="30"
						color="blue darken-2"
					>
						<v-tab
							v-for="deviceType in deviceTypes"
							:key="deviceType"
							class="px-1 font-weight-bold cassie-device-type-tab"
							@click="selectedDevice = deviceType.toLowerCase()"
						>
							{{ deviceType }}
						</v-tab>
					</v-tabs>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col
				cols="12"
				class="px-6"
			>
				<div
					class="cassie_preview_container position-relative"
					:class="'cassie_preview_container--' + selectedDevice"
					:style="{
						'--cookie-modal-secondary-color': colourScheme.SecondaryColour,
						'--cookie-modal-background-color': colourScheme.BackgroundColour,
						'--cookie-modal-text-color': colourScheme.TextColour,
						'--cassie-submit-preferences-button-color':
							colourScheme.SubmitPreferencesButtonColour,
						'--cassie-submit-preferences-button-text-color':
							colourScheme.SubmitPreferencesButtonTextColour,
					}"
				>
					<div
						class="cassie-cookie-modal"
						:class="[
							'cassie-cookie-modal--' + selectedAppearance,
							'cassie-cookie-modal--' +
								selectedAppearance +
								'--' +
								selectedDevice,
						]"
					>
						<div class="cookie-modal cookie-modal--mobile">
							<div class="cassie-cookie-modal-header">
								<div
									v-show="settings.displayLogo"
									:class="[
										'cassie-modal-header',
										'cassie-modal-header--' + selectedAppearance,
									]"
								>
									<div
										v-dompurify-html="settings.uploadedImageTag"
										:class="[
											'cassie-cookie-modal--header-image',
											'cassie-cookie-modal--header-image--' +
												selectedAppearance,
										]"
									/>
									<span class="cassie-close-modal--container" />
								</div><p
									v-dompurify-html="headerHtml"
									:class="'cassie-cookie-modal--header--content'"
								/>
								<div
									id="cassie_accept_all_cookies"
									:class="'cassie-cookie-modal--accept-all'"
								>
									<span
										id="cassie_accept_all_cookies_text"
										class="cassie-cookie-modal--accept-all--text"
									>Accept All Cookies</span>
									<!-- Main Accept All Cookies Accept/Reject Switch -->
									<BaseSwitch
										v-model="optInAll"
										:slider-background-off="colourScheme.SliderBackgroundOff"
										:slider-background-on="colourScheme.SliderBackgroundOn"
										:slider-color="colourScheme.SliderToggle"
									>
										<template v-if="settings.displayToggleLabels">
											{{
												optInAll ? settings.optInText : settings.optOutText
											}}
										</template>
									</BaseSwitch>
								</div>
								<div
									v-if="settings.showTabs"
									class="d-flex space-between"
								>
									<div
										:class="{ 'tab--active': selectedUserConsentTab }"
										class="tab"
										@click="selectedUserConsentTab = true"
									>
										{{ settings.userConsentTabLabel }}
									</div>
									<div
										:class="{ 'tab--active': !selectedUserConsentTab }"
										class="tab"
										@click="selectedUserConsentTab = false"
									>
										{{ settings.legitimateinterestTabLabel }}
									</div>
								</div>
							</div><div
								class="cassie-cookie-modal--main"
								:class="'cassie-cookie-modal--main--' + selectedAppearance"
								bis_skin_checked="1"
							>
								<div
									id="cassie_cookie_modal_cookies_container"
									class="cassie-cookie-modal--cookies--container"
									bis_skin_checked="1"
								>
									<div
										class="cassie-cookie-modal--tabs--container"
										bis_skin_checked="1"
										style="display: none;"
									>
										<button
											id="cassie_consent_button"
											class="cassie-tab--button cassie-active--button"
										>
											User Consent
										</button>
										<button
											id="cassie_legitimate_interests_button"
											class="cassie-tab--button"
										>
											Legitimate Interest
										</button>
									</div><div
										id="cassie_consent_tab_cookies"
										class="cassie-cookie-modal--tab-group"
										bis_skin_checked="1"
									>
										<div v-if="appearance === bannerPositions.panels">
											<cookie-category-panels
												v-for="cookieCategory in settings.categories"
												:key="cookieCategory.id"
												:cookie-category="cookieCategory"
												:colour-scheme="colourScheme"
												:settings="settings"
												:active-tab-user-consent="selectedUserConsentTab"
												:opt-in-all="optInAll"
												@click="selectCookieCategory"
												@changeOptIn="categoryOptIn"
											/>
										</div>
										<cookie-category
											v-for="cookieCategory in settings.categories"
											v-else
											:key="cookieCategory.id"
											:cookie-category="cookieCategory"
											:colour-scheme="colourScheme"
											:settings="settings"
											:ripple="false"
											:active-tab-user-consent="selectedUserConsentTab"
											:opt-in-all="optInAll"
										/>
									</div>
									<div
										id="cassie_legitimate_interests_tab_cookies"
										class="cassie-cookie-modal--tab-group"
										bis_skin_checked="1"
										style="display: none;"
									/>
								</div>
							</div><div
								v-dompurify-html="footerHtml"
								:class="'footer'"
							/>
						</div>

						<div
							v-if="appearance === bannerPositions.panels"
							class="details"
							:class="'details--' + selectedDevice"
						>
							<div class="details-header">
								{{
									selectedCookieCategory.name
								}}
							</div>
							<ul
								class="cookie-list"
								:class="'cookie-list--' + selectedDevice"
							>
								<li
									v-for="cookie in selectedCookieCategory.cookies"
									:key="cookie.id"
									class="cookie"
								>
									<template
										v-if="
											(cookie.type !== 'Legitimate Interest' &&
												selectedUserConsentTab) ||
												(cookie.type === 'Legitimate Interest' &&
													!selectedUserConsentTab)
										"
									>
										<div class="cookie-header">
											<div
												class="cookie-name"
											>
												{{ cookie.name }}
												<span
													v-if="cookie.gpcEnabled && globalGpcEnabled"
													class="cassie--gpc--flag"
												>GPC</span>
											</div>
											<BaseSwitch
												v-if="!selectedCookieCategory.isStrictlyNecessary"
												v-model="cookie.isOptIn"
												:slider-background-off="colourScheme.SliderBackgroundOff"
												:slider-background-on="colourScheme.SliderBackgroundOn"
												:slider-color="colourScheme.SliderToggle"
											>
												<template v-if="settings.displayToggleLabels">
													{{
														cookie.isOptIn
															? settings.optInText
															: settings.optOutText
													}}
												</template>
											</BaseSwitch>
										</div>
										<div
											v-dompurify-html="cookie.description"
											class="cookie-description"
										/>
									</template>
								</li>
							</ul>
							<div
								v-show="appearance === bannerPositions.panels"
								class="footer-extra-image align-self-end"
								:class="'footer-extra-image--' + selectedAppearance"
							>
								<v-img
									v-if="!isLightTheme"
									max-width="75"
									src="../../../../../../../../shared/assets/cassie-logo-white.svg"
								/>
								<v-img
									v-else
									max-width="75"
									src="../../../../../../../../shared/assets/cassie-logo-black.svg"
								/>
							</div>
						</div>
						<div
							class="d-flex space-between footer-extra"
							:class="'footer-extra--' + selectedAppearance"
						>
							<button
								:class="'save-preferences'"
								@click.prevent.stop=""
							>
								{{ settings.submitPreferencesText }}
							</button>

							<div
								v-show="appearance !== bannerPositions.panels"
								class="footer-extra-image"
							>
								<v-img
									v-if="!isLightTheme"
									class="float-right align-end"
									max-width="75"
									src="../../../../../../../../shared/assets/cassie-logo-white.svg"
								/>
								<v-img
									v-else
									class="float-right align-end"
									max-width="75"
									src="../../../../../../../../shared/assets/cassie-logo-black.svg"
								/>
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
/* eslint-disable max-lines */
import bannerPositions from '../../../../../../enums/bannerPositions.js'
import BaseSwitch from '../../../../../shared/base-switch.vue'
import CookieCategory from './cookie-category.vue'
import CookieCategoryPanels from './cookie-category-panels.vue'
export default {
	name: 'cookie-banner-preview',
	components: {
		'cookie-category': CookieCategory,
		'cookie-category-panels': CookieCategoryPanels,
		BaseSwitch
	},
	props: {
		appearance: {
			type: Number,
			default: bannerPositions.left,
			validator: value => {
				for (const key in bannerPositions) {
					if (Object.hasOwnProperty.call(bannerPositions, key)) {
						const position = bannerPositions[key]
						if (position === value) return true
					}
				}
				return false
			}
		},
		headerHtml: {
			type: String,
			default: '<p>Empty Header</p>'
		},
		footerHtml: {
			type: String,
			default: ''
		},
		colourScheme: {
			type: Object,
			default: () => {
				return {
					SliderBackgroundOn: 'grey',
					SliderBackgroundOff: 'grey',
					SliderToggle: 'white',
					BackgroundColour: '#071725'
				}
			}
		},
		settings: {
			type: Object,
			default: () => {
				return {
					optInText: 'Opt in',
					optOutText: 'Opt out'
				}
			}
		}
	},
	data () {
		return {
			deviceTypes: ['Desktop', 'Tablet', 'Mobile'],
			selectedDevice: 'desktop',
			optInAll: false,
			selectedUserConsentTab: true,
			tempCookieCategories: [],
			bannerPositions: bannerPositions,
			selectedCookieCategory: {},
			cookieCategories: [
				{
					name: 'Strictly Necessary',
					isStrictlyNecessary: true,
					selected: true,
					isOptIn: false,
					description:
					// eslint-disable-next-line max-len
            'These cookies are necessary for website functions such as setting your privacy preferences, logging in or completing forms. By using this site, you agree we may place these cookies on your device.',
					cookies: [
						{
							name: 'Site Features',
							isOptIn: false,
							description:
                'These are the minimum necessary cookies we require for the functionality of our site.'
						},
						{
							name: 'Cassie Cookie',
							description:
                'Cassie enables us to capture users preferences compliantly under regulatory frameworks.'
						}
					]
				},
				{
					name: 'Tracking Cookies',
					isStrictlyNecessary: false,
					isOptIn: false,
					selected: false,
					description:
            'We use tracking cookies to improve our understanding of users visiting our website.',
					cookies: [
						{
							name: 'Tracking Pixel',
							isOptIn: false,
							description:
                'These are the minimum necessary cookies we require for the functionality of our site.'
						},
						{
							name: 'Cassie Beacon',
							description:
                'Cassie enables us to capture users preferences compliantly under regulatory frameworks.'
						}
					]
				}
			]
		}
	},
	computed: {
		selectedAppearance () {
			switch (this.appearance) {
				case bannerPositions.left:
					return 'left'
				case bannerPositions.right:
					return 'right'
				case bannerPositions.middle:
					return 'middle'
				case bannerPositions.panels:
					return 'panels'
				default:
					return 'left'
			}
		},
		isLightTheme () {
			return this.colourScheme.BackgroundColour === '#FFFFFF'
		},
		hasConsentCookies () {
			const cookies = this.cookieCategory.cookies.filter(cookie => {
				return cookie.type !== 'Legitimate Interest'
			})

			return cookies
		},
		hasLegitimateInterestCookies () {
			const cookies = this.cookieCategory.cookies.filter(cookie => {
				return cookie.type === 'Legitimate Interest'
			})

			return cookies
		}
	},
	watch: {
		optInAll (val) {
			if (!val && (this.tempCookieCategories.length > 0)) {
				this.cookieCategories = JSON.parse(
					JSON.stringify(this.tempCookieCategories)
				)
				return
			}
			this.cookieCategories = this.cookieCategories.map(category => {
				category.isOptIn = val
				category.cookies = category.cookies.map(cookie => {
					cookie.isOptIn = val
					return cookie
				})
				return category
			})
		},
		selectedUserConsentTab (val) {
			if (!val) {
				this.tempCookieCategories = JSON.parse(
					JSON.stringify(this.cookieCategories)
				)
				this.optInAll = true
			} else {
				this.optInAll = false
			}
		}
	},
	created () {
		this.selectedCookieCategory = this.cookieCategories.find(x => x.selected)
	},
	methods: {
		selectCookieCategory (cookieCategory) {
			this.cookieCategories.forEach(x => {
				x.selected = false
				if (x.name === cookieCategory.name) {
					x.selected = true
				}
			})
			this.selectedCookieCategory = cookieCategory
		},
		categoryOptIn (val) {
			this.cookieCategories.forEach(x => {
				if (x.name === val.name) {
					x.cookies = x.cookies.map(c => {
						c.isOptIn = val.isOptIn
						return c
					})
				}
			})
		}
	}
}
</script>

<style lang="scss">

.cassie-device-type-tab-active {
	color: white !important;
}
.cookie-modal {
  margin-right: 1rem;
  // width: 100%;
  flex-grow: 3;
  position: relative;
  &--mobile {
    flex-grow: 1;
    margin-bottom: 1rem;
  }
}
.footer-extra {
	justify-content: space-between;
  &--panels {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}
.details {
  margin-left: auto;
  flex-grow: 4;
  border-left: 3px solid white;
  padding-left: 1rem;
  width: 60%;
  position: relative;
  margin-right: auto;
  &--mobile {
    margin: 0;
    width: 100%;
    border: none;
    padding-left: 0;
  }
  &--desktop {
    padding-top: 1rem;
  }
  &--tablet {
    padding-top: 1rem;
  }
  .footer-extra-image {
    position: absolute;
    bottom: 0;
    right: 0;
    &--panels {
      left: 3;
      right: initial;
    }

    img {
      bottom: 0;
    }
  }
  > .details-header {
    margin-bottom: 1rem;
    font-size: 18px;
  }
}
.footer-extra-image ::v-deep img {
  max-height: 100%;
  max-width: 100%;
}
.cookie-list {
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  border: 2px solid var(--cookie-modal-text-color);
  border-radius: 5px;
  &--mobile {
    margin-left: 0;
  }
  > .cookie {
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    list-style: none;
    border-bottom: 2px solid var(--cookie-modal-text-color);
    margin-bottom: 5px;
    .cookie-name {
      font-weight: 500;
    }
    .cookie-header {
      display: flex;
      justify-content: space-between;
    }
    .cookie-description {
      font-size: 13px;
      margin-top: 0.5rem;
    }
  }
  > .cookie:last-child {
    border-bottom: none;
  }
}
.footer {
  width: 100%;
  font-size: 12px;
  line-height: 1.6;
  &-extra {
    margin-top: 1rem;
    &-image {
      position: relative;
      width: 30%;
      a {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        margin: 0;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}
.save-preferences {
  width: 10rem;
  max-height: 50px;
  min-height: 50px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--cassie-submit-preferences-button-text-color);
  cursor: pointer;
  background-color: var(--cassie-submit-preferences-button-color);
  border-color: var(--cassie-submit-preferences-button-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-image: initial;
}
.cassie-cookie-modal {
  position: relative;
  z-index: 192;
  display: flex;
  flex-direction: column;
  flex-wrap: initial;
  width: 25rem;
  height: 100%;
  padding: 20px;
  overflow: hidden scroll;
  color: var(--cookie-modal-text-color);
  background-color: var(--cookie-modal-background-color);
  inset: 0;
  &--left {
    border-right: var(--cassie-submit-preferences-button-color);
    border-right-width: 1rem;
    border-right-style: solid;
    align-self: flex-start;
  }
  &--right {
    align-self: flex-end;
    border-left: var(--cassie-submit-preferences-button-color);
    border-left-width: 1rem;
    border-left-style: solid;
  }
  &--panels {
    width: 55rem;
    max-width: 100%;
    height: 500px;
    flex-direction: row;
    margin: auto;
    &--mobile {
      height: 100%;
      flex-direction: column;
    }
  }
  &--middle {
    width: 33rem;
    max-width: 100%;
    height: 500px;
    &--mobile {
      height: 100%;
    }

    margin: auto;
  }
}
.cassie-cookie-modal--accept-all {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.cassie-toggle-switch--checkbox {
  position: relative;
  right: 48px;
  bottom: 27px;
  z-index: 1;
  width: 90px;
  height: 24px;
  cursor: pointer;
  opacity: 0;
}
.cassie-cookie-modal--header--content {
  width: 100%;
  padding-top: 10px;
  margin: 0 0 10px;
  font-size: 12px;
  line-height: 1.6;
  overflow-wrap: break-word;
  border-color: var(--cookie-modal-secondary-color);
  border-top-style: solid;
  border-top-width: 2px;
}

.cassie-cookie--child--heading {
  width: 60%;
  margin: 0;
  font-size: 12px;
  line-height: 1.6;
}
.cassie_preview_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 680px;
  margin: auto;
  background-color: rgba(0,0,0,.7);
  box-shadow: inset 0 0 0 2000px rgba(179, 173, 176, 0.3);
}
.cassie-toggle-switch--status {
  position: relative;
  top: -2px;
  right: 50px;
  font-size: 12px;
}
.cassie-cookie--child--description {
  width: 75%;
  margin: 5px 0;
  font-size: 12px;
  line-height: 1.6;
}

.cassie-cookie-modal--main {
  display: flex;
  flex-shrink: 0;
  align-self: flex-start;
  width: 100%;
  border-color: var(--cookie-modal-secondary-color);
  border-width: 2px 2px 0;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: initial;
  border-left-style: solid;
  border-image: initial;
  &--panels {
    border: none;
    display: block;
  }
}

.cassie-cookie-modal--cookies--container {
  width: 100%;
}
.cassie-modal-header {
  width: 50%;
  &--middle {
    margin: auto;
  }
  // &--panels {
  //   margin: auto;
  // }
}
.cassie-toggle-switch--checkbox {
  position: relative;
  right: 48px;
  bottom: 27px;
  z-index: 1;
  width: 90px;
  height: 24px;
  cursor: pointer;
  opacity: 0;
}
.cassie-toggle-switch {
  position: relative;
  width: 50px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}
.tab {
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1.1rem;
  cursor: pointer;
  border-bottom-color: var(--cookie-modal-text-color);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  &--active {
    border-bottom-color: var(--cookie-modal-active-tab);
    transition: 0.2s border-bottom-color ease-in-out;
  }
}
.cassie-cookie-modal--footer-image {
  width: 7rem;
}
.cassie_preview_container--tablet {
  width: 100%;
  max-width: 1024px;
}
.cassie-cookie-modal--header-image > img {
  max-height: 50%;
  max-width: 50%;
}
.cassie-cookie-modal--header-image {
  margin: 10px 0;
  &--middle {
    max-width: 50%;
  }
  &--panels {
    max-width: 50%;
  }
}
.cassie_preview_container--mobile {
  width: 100%;
  max-width: 414px;
}

.cassie--gpc--flag {
  color: var(--cassie-submit-preferences-button-text-color);
  background-color: var(--cassie-submit-preferences-button-color);
  font-size: 8px;
  border-radius: 10px;
  padding: 2px 5px;
  margin-bottom: 2px;
  margin-left: 5px;
  font-weight: bold;
}
</style>
