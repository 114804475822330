<template>
	<!-- eslint-disable max-lines -->
	<div>
		<ValidationForm
			#default="{ handleSubmit }"
			ref="validationForm"
		>
			<div class="cassie-vertical-md">
				<div
					v-if="formData.createdDate"
					class="text-caption"
				>
					Created By: {{ formData.createdByName }}
					{{ formData.createdDate }}
					<span
						v-if="lastSavedStep >= currentStep"
					>
						| Last Edited By: {{ formData.editedByName }} {{ formData.lastEditedDate }}
					</span>
				</div>
				<SectionCard>
					<template #title>
						Banner
					</template>
					<template #subtitle>
						Your Consent Banner allows users to customise their consent
						and see what scripts will be loaded into their browser.
					</template>
					<template #body>
						<div
							class="d-flex flex-row cassie-horizontal-sm"
						>
							<div
								class="pa-2"
								style="width: 60%;"
							>
								<div class="text-subtitle-2">
									Appearance*
								</div>
								<RadioGroup
									:radio-group-value.sync="formData.bannerLocation"
									:disabled="!userCanCreateUpdate"
									:radios="bannerLocationRadios"
									image-radio-group
								/>
							</div>
							<v-divider vertical />
							<div
								class="pa-2 cassie-vertical-md"
								style="width: 40%;"
							>
								<div class="text-subtitle-2">
									Colour Scheme*
								</div>
								<RadioGroup
									:radio-group-value.sync="formData.selectedColourScheme"
									:disabled="!userCanCreateUpdate"
									:radios="bannerColourSchemeRadios"
								/>
								<div
									v-if="
										formData.selectedColourScheme ===
											colourSchemeEnum.custom
									"
									class="cassie-banner-custom-color-container"
								>
									<v-row>
										<v-col cols="12">
											<v-menu
												v-for="customiseButton in formData.customiseButtons"
												:key="customiseButton.id"
												offset-y
												:close-on-content-click="false"
												attach
											>
												<template
													v-if="
														(formData.bannerLocation === 3 &&
															customiseButton.displayInPanels) ||
															(formData.bannerLocation !== 3 &&
																customiseButton.displayInTemplate2)
													"
													#activator="{ on }"
												>
													<div class="mb-2 cassie-customise-button-container">
														<v-btn
															max-width="30"
															max-height="30"
															elevation="1"
															fab
															class="mr-2"
															:color="customiseButton.color"
															v-on="on"
															@click="openColourPicker(customiseButton)"
														/>
														<span class="text-caption mt-1">
															{{
																customiseButton.text
															}}
														</span>
													</div>
												</template>
												<div class="cassie-colour-picker-container">
													<v-color-picker
														v-model="pickerColour"
														class="mx-auto"
														mode="hexa"
														hide-mode-switch
														@input="getColour($event, customiseButton)"
													/>
												</div>
											</v-menu>
										</v-col>
									</v-row>
								</div>
							</div>
						</div>
						<div
							class="d-flex flex-column cassie-horizontal-sm cassie-vertical-sm"
							style="width: 60%"
						>
							<div class="text-subtitle-2">
								Display a Logo?
							</div>
							<div class="cassie-vertical-md">
								<RadioGroup
									:radio-group-value.sync="formData.displayLogo"
									:disabled="!userCanCreateUpdate"
									:radios="showLogoRadios"
								/>
								<v-card
									v-if="formData.displayLogo"
									flat
									color="grey lighten-5"
									class="pa-3"
								>
									<div class="d-flex flex-row">
										<div
											class="d-flex flex-column"
										>
											<v-card-title class="text-subtitle-2">
												Upload Logo*
											</v-card-title>
											<v-card-subtitle class="black--text">
												Maximum file size 10mb. Allowed file types: JPG,
												JPEG, GIF, PNG.
											</v-card-subtitle>
										</div>
										<v-spacer />
										<div
											class="d-flex flex-column"
										>
											<div
												v-dompurify-html="formData.uploadedImageTag"
												class="cassie-banner-logo"
											/>
										</div>
									</div>
									<div
										class="d-flex flex-row cassie-horizontal-md"
									>
										<validation-provider
											#default="{ errors }"
											rules="image"
											tag="div"
											style="flex: 1 1 auto;"
										>
											<v-file-input
												v-model="formData.uploadedImage"
												accept="image/png, image/jpeg, image/gif"
												label="File input"
												class="pt-0"
												prepend-icon="mdi-camera"
												:disabled="!userCanCreateUpdate"
												:error-messages="errors.length ? [errors[0]] : []"
												@change="selectLogo"
												@click:clear="clearLogo"
											/>
										</validation-provider>
										<PrimaryActionButton
											:disabled="
												!formData.isValidImage ||
													!userCanCreateUpdate
											"
											:loading="uploading"
											@click="uploadLogo"
										>
											Upload
										</PrimaryActionButton>
									</div>
								</v-card>
							</div>
						</div>
						<div class="d-flex flex-row cassie-horizontal-sm">
							<TextField
								v-model="formData.submitPreferencesText"
								label="Submit Preferences Button Text*"
								class="cassie-input-width-sm"
								:disabled="!userCanCreateUpdate"
								:rules="{required: true, max: 50}"
							/>
						</div>
						<div
							class="d-flex flex-row cassie-horizontal-sm"
						>
							<v-expansion-panels accordion>
								<v-expansion-panel>
									<v-expansion-panel-header>
										<span>
											Show Advanced Options
										</span>
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<v-card
											flat
											color="grey lighten-5"
											class="cassie-vertical-sm d-flex flex-column pa-3"
										>
											<div class="cassie-vertical-sm">
												<div
													class="d-flex flex-column cassie-vertical-sm"
												>
													<div
														class="text-subtitle-2"
													>
														Custom Tab Labels
													</div>
													<RadioGroup
														:radio-group-value.sync="formData.showCustomTabLabels"
														:disabled="!userCanCreateUpdate"
														:radios="customTabLabelRadios"
													/>
												</div>
												<div
													v-if="formData.showCustomTabLabels"
													class="d-flex flex-row cassie-horizontal-sm cassie-vertical-sm"
												>
													<TextField
														v-model="formData.userConsentTabLabel"
														label="User Consent Tab Label*"
														class="cassie-input-width-sm"
														:disabled="!userCanCreateUpdate"
														:rules="{required: true, max: 50}"
													/>
													<TextField
														v-model="formData.legitimateinterestTabLabel"
														label="Legitimate Interest Tab Label*"
														class="cassie-input-width-sm"
														:disabled="!userCanCreateUpdate"
														:rules="{required: true, max: 50}"
													/>
												</div>
											</div>
											<div class="cassie-vertical-sm">
												<div
													class="d-flex flex-column cassie-vertical-sm"
												>
													<div
														class="text-subtitle-2"
													>
														Display Toggle Labels
													</div>
													<RadioGroup
														:radio-group-value.sync="formData.displayToggleLabels"
														:disabled="!userCanCreateUpdate"
														:radios="displayToggleLabelRadios"
													/>
												</div>
												<div
													v-if="formData.displayToggleLabels"
													class="d-flex flex-row cassie-horizontal-sm"
												>
													<TextField
														v-model="formData.optInLabel"
														outlined
														dense
														label="Opt In Label"
														class="cassie-input-width-sm"
														:disabled="!userCanCreateUpdate"
														:rules="{max:50}"
													/>
													<TextField
														v-model="formData.optOutLabel"
														label="Opt Out Label"
														class="cassie-input-width-sm"
														:disabled="!userCanCreateUpdate"
														:rules="{max:50}"
													/>
												</div>
											</div>
											<div class="cassie-vertical-sm">
												<div
													class="d-flex flex-column cassie-vertical-sm"
												>
													<div
														class="text-subtitle-2"
													>
														Display Expand / Collapse Category Labels
													</div>
													<RadioGroup
														:radio-group-value.sync="formData.displayExpandCollapseLabels"
														:disabled="!userCanCreateUpdate"
														:radios="displayExpandColapseLabelsRadios"
													/>
												</div>
												<div
													v-if="formData.displayExpandCollapseLabels"
													class="d-flex flex-row cassie-horizontal-sm"
												>
													<TextField
														v-model="formData.expandLabel"
														label="Expand Label"
														:disabled="!userCanCreateUpdate"
														class="cassie-input-width-sm mb-4"
														:rules="{max:50}"
													/>
													<TextField
														v-model="formData.collapseLabel"
														label="Collapse Label"
														class="cassie-input-width-sm mb-4"
														:disabled="!userCanCreateUpdate"
														:rules="{max:50}"
													/>
												</div>
											</div>
											<div
												v-if="preBannerHidden"
												class="d-flex flex-column cassie-vertical-sm"
											>
												<div
													class="text-subtitle-2"
												>
													Auto Show Modal
												</div>
												<RadioGroup
													:radio-group-value.sync="formData.autoShowModal"
													:disabled="!userCanCreateUpdate"
													:radios="autoShowModalRadios"
												/>
											</div>
										</v-card>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</div>
					</template>
				</SectionCard>

				<SectionCard>
					<template #title>
						Header and Footer
					</template>
					<template #subtitle>
						Select, create or edit custom Headers and Footers for your Consent Banner.
					</template>
					<template #body>
						<div class="d-flex flex-row cassie-horizontal-sm">
							<Dropdown
								v-model="formData.selectedHeaderId"
								label="Header*"
								:disabled="!userCanCreateUpdate"
								:items="headerOptions"
								class="cassie-input-width-md"
								item-text="headerName"
								item-value="id"
								style="flex: none;"
								rules="required"
							/>
							<TextButton
								:disabled="!userCanCreateUpdate"
								@click="openCreateModal('Header')"
							>
								<v-icon>
									mdi-plus
								</v-icon>
								Create Header
							</TextButton>
							<TextButton
								v-if="formData.selectedHeaderId > 0"
								:disabled="!userCanCreateUpdate"
								@click="openEditModal('Header')"
							>
								<v-icon>
									mdi-pencil
								</v-icon>
								Edit Header
							</TextButton>
						</div>
						<div class="d-flex flex-row cassie-horizontal-sm">
							<Dropdown
								v-model="formData.selectedFooterId"
								label="Footer*"
								:disabled="!userCanCreateUpdate"
								:items="footerOptions"
								item-text="footerName"
								class="cassie-input-width-md"
								item-value="id"
								style="flex: none;"
								rules="required"
							/>
							<TextButton
								:disabled="!userCanCreateUpdate"
								@click="openCreateModal('Footer')"
							>
								<v-icon>
									mdi-plus
								</v-icon>
								Create Footer
							</TextButton>
							<TextButton
								v-if="formData.selectedFooterId > 0"
								:disabled="!userCanCreateUpdate"
								@click="openEditModal('Footer')"
							>
								<v-icon>
									mdi-pencil
								</v-icon>
								Edit Footer
							</TextButton>
						</div>
					</template>
				</SectionCard>

				<SectionCard>
					<template #title>
						Cookie Categories
					</template>
					<template #subtitle>
						Please select all of the Cookie Categories you wish to see on
						your Consent Banner.
					</template>
					<template #title-action>
						<TextButton
							:disabled="!userCanCreateUpdate"
							@click="openCreateCategoryModal"
						>
							<v-icon>
								mdi-plus
							</v-icon> Create Category
						</TextButton>
					</template>
					<template #body>
						<div class="d-flex flex-column cassie-vertical-md">
							<div
								v-if="userCanCreateUpdate"
								class="d-flex flex-row cassie-horizontal-sm"
							>
								<Dropdown
									v-model="selectedCookieCategories"
									label="Cookie Categories"
									:items="availableCookieCategories"
									item-text="categoryName"
									item-value="categoryId"
									class="cassie-input-width-md"
									multiple
									select-all
									return-object
									@change="updateSelectAllChecked"
								>
									<template
										v-if="showSelectAll"
										#prepend-item
									>
										<v-list-item
											dense
											@click="toggleSelectAll"
										>
											<v-checkbox
												v-model="selectAllChecked"
												dense
											/>
											<v-list-item-title class="pb-2">
												Select All
											</v-list-item-title>
										</v-list-item>
										<v-divider />
									</template>
								</Dropdown>
								<PrimaryActionButton
									:disabled="selectedCookieCategories.length === 0"
									@click="addCookieCategoriesToBanner"
								>
									Add
								</PrimaryActionButton>
							</div>
							<v-alert
								v-if="formData.selectedCategories.length === 0"
								light
								border="bottom"
								colored-border
								type="warning"
								elevation="2"
							>
								This banner does not currently contain any categories. Please
								add a category from the list above.
							</v-alert>
							<DataTable
								v-else
								:headers="categoryTableHeader"
								:items="formData.selectedCategories"
								:sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc"
								:disable-sort="false"
								hide-default-footer
								@click:row="if (userCanCreateUpdate) {openEditCategoryModal($event, 'Category')}"
							>
								<template #item.cookieCategoryName="{ item }">
									{{ getCategoryName(item.cookieCategoryId) }}
								</template>
								<template #item.type="{ item }">
									{{ getCategoryType(item.cookieCategoryId) }}
								</template>
								<template #item.action="{ item }">
									<IconButton
										tooltip-text="Edit Category"
										:disabled="!userCanCreateUpdate"
										@click="openEditCategoryModal(item, 'Category')"
									>
										mdi-pencil
									</IconButton>
									<IconButton
										v-if="
											item.type !== 'Essential' &&
												item.displayOrder !==
												formData.selectedCategories.length
										"
										:disabled="!userCanCreateUpdate"
										@click.stop.prevent="moveCategory(item, 'moveDown')"
									>
										mdi-arrow-down
									</IconButton>

									<IconButton
										v-if="
											item.type !== 'Essential' && isEssentialCategorySelected
												? item.displayOrder > 2
												: item.displayOrder > 1
										"
										:disabled="!userCanCreateUpdate"
										@click.stop.prevent="moveCategory(item, 'moveUp')"
									>
										mdi-arrow-up
									</IconButton>
									<IconButton
										:disabled="!userCanCreateUpdate"
										tooltip-text="Remove Category"
										@click.stop.prevent="deleteCategoryFromConsentBanner(item)"
									>
										mdi-close
									</IconButton>
								</template>
							</DataTable>
						</div>
					</template>
				</SectionCard>

				<SectionCard>
					<template #title>
						Preview
					</template>
					<template #body>
						<CookieBannerPreview
							:appearance="formData.bannerLocation"
							:colour-scheme="selectedColourSchemeData"
							:settings="previewSettings"
							:header-html="selectedHeaderHTML"
							:footer-html="selectedFooterHTML"
						/>
					</template>
				</SectionCard>
			</div>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton @click="cancelBanner">
						Exit
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton
						v-if="lastSavedStep >= currentStep && userCanCreateUpdate"
						class="mr-2"
						@click="handleSubmit(proceedFromBanner)"
					>
						Save Changes
					</PrimaryActionButton>
					<SecondaryActionButton @click="backToPreBanner">
						Back
					</SecondaryActionButton>
					<SecondaryActionButton
						v-if="lastSavedStep >= currentStep"
						class="ml-2"
						@click="nextStep"
					>
						Next
					</SecondaryActionButton>
					<PrimaryActionButton
						v-else-if="userCanCreateUpdate"
						@click="handleSubmit(proceedFromBanner)"
					>
						Save and Continue
					</PrimaryActionButton>
				</template>
			</PageActionRow>
		</ValidationForm>

		<v-bottom-sheet v-model="sheet">
			<v-sheet
				class="text-center"
				height="200px"
			>
				<v-btn
					class="mt-6"
					color="red white--text"
					append
					@click="sheet = !sheet"
				>
					close
					<v-icon right>
						mdi-close
					</v-icon>
				</v-btn>
				<div class="py-3">
					<v-alert
						class="my-2 mx-10"
						light
						border="bottom"
						colored-border
						type="warning"
						elevation="2"
					>
						We currently do not hold a colour scheme for your banner. Please
						review carefully and make a selection, as when you move from this
						page it will be applied to your banner.
					</v-alert>
				</div>
			</v-sheet>
		</v-bottom-sheet>
		<!-- Header & Footer form -->
		<HeaderFooterFormModal
			v-if="showModal"
			:type-id="headersAndFootersTypeIdEnum.CONSENT_BANNERS"
			:form-to-edit="editMode ? formData : null"
			:selected-brand-id="bannerBrandId"
			:form-type="selectedFormType"
			@close="cancelForm"
			@updated="loadHeadersAndFooters"
			@set-selected-header:headerId="setSelectedHeaderFooter('headerId', $event)"
			@set-selected-footer:footerId="setSelectedHeaderFooter('footerId', $event)"
		/>

		<!-- Category form -->
		<CategoryForm
			v-if="showCategoryModal"
			:form="formData.category"
			:selected-brand-id="bannerBrandId"
			@openEditModal="openEditCategoryModal"
			@cancelForm="cancelCategoryForm"
			@createNewCategory="createNewCategory"
			@updateCategory="updateCategory"
		/>
	</div>
</template>

<script>
/* eslint-disable max-lines */
import { mapActions, mapGetters } from 'vuex'
import { HEADER, FOOTER } from '../../../../../../../../shared/utils/api/headers-and-footers.js'
import { showSnackbar } from '../../../../../../../../shared/state/snackbar.js'
import ValidationForm from '../../../../../../../../shared/components/validation-form.vue'
import HeaderFooterFormModal from '../../../../../shared/header-footer-form-modal.vue'
import CategoryForm from '../../../../../shared/category-form.vue'
import CookieBannerPreview from '../cookie-banner-preview/cookie-banner-preview.vue'
import SectionCard from '../../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../../shared/components/text-field.vue'
import PageActionRow from '../../../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../../../../shared/components/icon-button.vue'
import TextButton from '../../../../../../../../shared/components/text-button.vue'
import DataTable from '../../../../../../../../shared/components/data-table.vue'
import Dropdown from '../../../../../../../../shared/components/dropdown.vue'
import { getConsentBannerConfigurationStatus } from '../../../../../../services/ConsentBannerService.js'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS } from '../../../../../../../../shared/permissions/admin-portal-permissions.js'
import bannerPositions from '../../../../../../enums/bannerPositions.js'
import { headersAndFootersTypeIdEnum } from '../../../../../../../../shared/enums/headers-and-footers.js'
import {
	BANNER_LOCATIONS,
	BANNER_COLOUR_SCHEMES,
	bannerLocationRadios,
	bannerColourSchemeRadios,
	showLogoRadios,
	customTabLabelRadios,
	displayToggleLabelRadios,
	displayExpandColapseLabelsRadios,
	autoShowModalRadios
} from './configure-cookie-consent-banner-enums.js'
import RadioGroup from '../../../../../../../../shared/components/radio-group.vue'
const colourSchemeEnum = {
	dark: 0,
	light: 1,
	custom: 2
}
const colorSchemes = [
	{
		identifier: colourSchemeEnum.dark,
		BackgroundColour: '#2e2e37',
		SecondaryColour: '#4f4f50',
		TextColour: '#FFFFFF',
		SubmitPreferencesButtonColour: '#e31f26',
		SubmitPreferencesButtonTextColour: '#FFFFFF',
		AcceptAllButtonColour: '#2e2e37',
		AcceptAllButtonTextColour: '#FFFFFF',
		RejectAllButtonColour: '#2e2e37',
		RejectAllButtonTextColour: '#FFFFFF',
		SliderBackgroundOn: '#e31f26',
		SliderBackgroundOff: '#4f4f50',
		SliderToggle: '#FFFFFF'
	},
	{
		identifier: colourSchemeEnum.light,
		BackgroundColour: '#FFFFFF',
		SecondaryColour: '#4f4f50',
		TextColour: '#000000',
		SubmitPreferencesButtonColour: '#e31f26',
		SubmitPreferencesButtonTextColour: '#FFFFFF',
		AcceptAllButtonColour: '#2e2e37',
		AcceptAllButtonTextColour: '#FFFFFF',
		RejectAllButtonColour: '#FFFFFF',
		RejectAllButtonTextColour: '#2e2e37',
		SliderBackgroundOn: '#e31f26',
		SliderBackgroundOff: '#4f4f50',
		SliderToggle: '#FFFFFF'
	}
]
const defaultColourScheme = {
	identifier: '2',
	BackgroundColour: '#5D707F',
	SecondaryColour: '#6D8A96',
	TextColour: '#FFFFFF',
	SubmitPreferencesButtonColour: '#66CED6',
	SubmitPreferencesButtonTextColour: '#000000',
	AcceptAllButtonColour: '#8797B2',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#A7A5C6',
	RejectAllButtonTextColour: '#FFFFFF',
	CustomiseAllButtonColour: '#8797B2',
	CustomiseAllButtonTextColour: '#FFFFFF',
	SliderBackgroundOff: 'grey',
	SliderBackgroundOn: 'grey',
	SliderToggle: '#FFFFFF'
}
const UNIVERSAL_BRAND = 0
export default {
	name: 'configure-cookie-consent-banner',
	components: {
		ValidationForm,
		CookieBannerPreview,
		SectionCard,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		TextButton,
		Dropdown,
		DataTable,
		HeaderFooterFormModal,
		CategoryForm,
		TextField,
		RadioGroup,
		IconButton
	},
	props: {
		banner: {
			type: Object,
			default: null
		},
		bannerBrandId: Number
	},
	setup () {
		return {
			showSnackbar,
			BANNER_LOCATIONS,
			BANNER_COLOUR_SCHEMES,
			bannerLocationRadios,
			bannerColourSchemeRadios,
			showLogoRadios,
			customTabLabelRadios,
			displayToggleLabelRadios,
			displayExpandColapseLabelsRadios,
			autoShowModalRadios
		}
	},
	data () {
		return {
			UNIVERSAL_BRAND,
			hiddenPreBanner: false,
			bannerPositions: bannerPositions,
			currentStep: 3,
			colourSchemeEnum: colourSchemeEnum,
			colorSchemes: colorSchemes,
			showModal: false,
			showCategoryModal: false,
			selectedCookieCategories: [],
			selectAllChecked: false,
			isValid: false,
			selectedFormType: null,
			formData: {
				type: '',
				id: null,
				name: '',
				html: '',
				enableBanner: true,
				selectedHeaderId: 0, // number
				selectedFooterId: 0, // number
				bannerLocation: bannerPositions.left,
				selectedColourScheme: colourSchemeEnum.dark,
				createdByName: '',
				createdDate: '',
				editedByName: '',
				lastEditedDate: '',
				colourScheme: defaultColourScheme,
				customiseButtons: [
					{
						id: 'BackgroundColour',
						text: 'Background Colour',
						color: defaultColourScheme.BackgroundColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'TextColour',
						text: 'Text Colour',
						color: defaultColourScheme.TextColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SubmitPreferencesButtonColour',
						text: 'Submit Preferences Button Colour',
						color: defaultColourScheme.SubmitPreferencesButtonColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SubmitPreferencesButtonTextColour',
						text: 'Submit Preferences Text Colour',
						color: defaultColourScheme.SubmitPreferencesButtonTextColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'AcceptAllButtonColour',
						text: 'Accept All Button Colour',
						color: defaultColourScheme.AcceptAllButtonColour,
						displayInPanels: true,
						displayInTemplate2: false
					},
					{
						id: 'AcceptAllButtonTextColour',
						text: 'Accept All Button Text Colour',
						color: defaultColourScheme.AcceptAllButtonTextColour,
						displayInPanels: true,
						displayInTemplate2: false
					},
					{
						id: 'RejectAllButtonColour',
						text: 'Reject All Button Colour',
						color: defaultColourScheme.RejectAllButtonColour,
						displayInPanels: true,
						displayInTemplate2: false
					},
					{
						id: 'RejectAllButtonTextColour',
						text: 'Reject All Button Text Colour',
						color: defaultColourScheme.RejectAllButtonTextColour,
						displayInPanels: true,
						displayInTemplate2: false
					},
					{
						id: 'SecondaryColour',
						text: 'Secondary Colour',
						color: defaultColourScheme.SecondaryColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SliderBackgroundOff',
						text: 'Slider Background Off',
						color: defaultColourScheme.SliderBackgroundOff,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SliderBackgroundOn',
						text: 'Slider Background On',
						color: defaultColourScheme.SliderBackgroundOn,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SliderToggle',
						text: 'Slider Toggle',
						color: defaultColourScheme.SliderToggle,
						displayInPanels: true,
						displayInTemplate2: true
					}
				],
				displayLogo: false,
				isValidImage: false,
				uploadedImage: null,
				uploadedImageName: '',
				uploadedImageSize: '',
				uploadedImageUrl: '',
				uploadedImageTag: '',
				showAdvancedOptions: false,
				showCustomTabLabels: false,
				userConsentTabLabel: 'User Consent',
				legitimateinterestTabLabel: 'Legitimate Interest',
				displayToggleLabels: false,
				optInLabel: 'Opt In',
				optOutLabel: 'Opt Out',
				displayExpandCollapseLabels: false,
				expandLabel: 'Expand',
				collapseLabel: 'Collapse',
				submitPreferencesText: 'Submit Preferences',
				autoShowModal: false,
				selectedCategory: {},
				selectedCategories: [],
				deletedCategories: [],
				category: {
					id: null,
					name: '',
					html: '',
					brand: Number,
					associatedBanners: [],
					isStrictlyCategory: false,
					createdByName: '',
					createdDate: '',
					editedByName: '',
					lastEditedDate: ''
				}
			},
			pickerColour: '',
			categoryTableHeader: [
				{
					text: 'Id',
					value: 'cookieCategoryId',
					sortable: false
				},
				{
					text: 'Cookie Category Name',
					value: 'cookieCategoryName',
					sortable: false
				},
				{
					text: 'Type',
					value: 'type',
					sortable: false
				},
				{
					text: 'Display Order',
					value: 'displayOrder',
					sortable: false
				},
				{
					text: 'Action',
					value: 'action',
					sortable: false
				}
			],
			deviceTypes: [
				{
					text: 'Desktop'
				},
				{
					text: 'Tablet'
				},
				{
					text: 'Mobile'
				}
			],
			saving: false,
			sortBy: 'displayOrder',
			sortDesc: false,
			uploading: false,
			maxImageSize10mb: 10 * 1024 * 1024,
			cacheKey: +new Date(),
			sheet: false,
			formUpdated: false,
			editMode: false,
			headersAndFootersTypeIdEnum
		}
	},
	computed: {
		...mapGetters('headers', ['headers']),
		...mapGetters('footers', ['footers']),
		...mapGetters('assets', ['logoUrl']),
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('cookieCategories', ['cookieCategories']),
		...mapGetters('consentBanners', [
			'consentBanners',
			'configurationStatusId',
			'lastSavedStep',
			'preBannerHidden',
			'currentBannerCategories',
			'currentConsentBannerId',
			'consentBannerProfiles'
		]),
		isEditMode () {
			return this.configurationStatusId > 3
		},
		defaultCustomColourScheme () {
			return {
				identifier: '2',
				BackgroundColour: '#5D707F',
				SecondaryColour: '#6D8A96',
				TextColour: '#FFFFFF',
				SubmitPreferencesButtonColour: '#66CED6',
				SubmitPreferencesButtonTextColour: '#000000',
				AcceptAllButtonColour: '#8797B2',
				AcceptAllButtonTextColour: '#FFFFFF',
				RejectAllButtonColour: '#A7A5C6',
				RejectAllButtonTextColour: '#FFFFFF',
				CustomiseAllButtonColour: '#8797B2',
				CustomiseAllButtonTextColour: '#FFFFFF',
				SliderBackgroundOff: 'grey',
				SliderBackgroundOn: 'grey',
				SliderToggle: '#FFFFFF'
			}
		},
		previewSettings () {
			return {
				optOutText: this.formData.optOutLabel,
				optInText: this.formData.optInLabel,
				displayLogo: this.formData.displayLogo,
				displayToggleLabels: this.formData.displayToggleLabels,
				userConsentTabLabel: this.formData.userConsentTabLabel,
				legitimateinterestTabLabel: this.formData.legitimateinterestTabLabel,
				displayExpandCollapseLabels: this.formData.displayExpandCollapseLabels,
				expandLabel: this.formData.expandLabel,
				collapseLabel: this.formData.collapseLabel,
				uploadedImageTag: this.formData.uploadedImageTag,
				submitPreferencesText: this.formData.submitPreferencesText,
				categories: this.previewCategories
			}
		},
		previewCategories () {
			const categories = JSON.parse(
				JSON.stringify(this.formData.selectedCategories)
			)

			categories.forEach(category => {
				category.name = category.cookieCategoryName
				category.isOptIn = false

				// get display text for description
				const matchingCategory = JSON.parse(
					JSON.stringify(this.cookieCategories)
				).find(x => x.categoryId === category.cookieCategoryId)
				category.description = matchingCategory.displayText

				if (category.type === 'Essential') {
					category.isStrictlyNecessary = true
					category.selected = true
					category.cookies = [
						{
							name: 'Site Features',
							isOptIn: false,
							description:
								'These are the minimum necessary cookies we require for the functionality of our site.'
						},
						{
							name: 'Cassie Cookie',
							description:
								'Cassie enables us to capture users preferences compliantly under regulatory frameworks.'
						}
					]
				} else {
					category.cookies = [
						{
							name: category.name + ' Cookie',
							isOptIn: false,
							description:
								'These are the minimum necessary cookies we require for the functionality of our site.'
						}
					]
					category.selected = false
				}
			})

			categories.sort((a, b) => {
				return a.displayOrder - b.displayOrder
			})

			return categories
		},
		uploadedImageTag () {
			let url = this.formData.uploadedImageUrl

			if (!url) {
				url = `https://cassie.syrenis.com/wp-content/uploads/2020/08/Cassie-TEMP-logo-Wht-1.svg`
			}
			const imageTag = '<img src="' + url + '" alt="consent-banner-logo">'
			return imageTag
		},
		customScheme () {
			const customiseColors = this.formData.customiseButtons.reduce(
				(obj, item) => ({ ...obj, [item.id]: item.color }),
				{}
			)
			customiseColors.identifier = colourSchemeEnum.custom
			return customiseColors
		},
		selectedColourSchemeData () {
			try {
				if (this.formData.selectedColourScheme === colourSchemeEnum.custom) {
					return this.customScheme
				}
			} catch (error) {
			}

			const predefinedScheme = colorSchemes.find(
				x => x.identifier === this.formData.selectedColourScheme
			)
			return predefinedScheme
		},
		lastCategory () {
			if (this.formData.selectedCategories.length === 0) return null
			return this.formData.selectedCategories[
				this.formData.selectedCategories.length - 1
			]
		},
		selectedHeaderHTML () {
			if (!this.formData.selectedHeaderId) return null
			return this.headerOptions.find(
				x => x.id === this.formData.selectedHeaderId
			).headerHtml
		},
		selectedFooterHTML () {
			if (!this.formData.selectedFooterId) return null
			return this.footerOptions.find(
				x => x.id === this.formData.selectedFooterId
			).footerHtml
		},
		headerOptions () {
			const headerNoneOption = {
				headerName: 'None',
				id: 0,
				headerHtml: ''
			}

			let filteredHeaders = []

			if (this.bannerBrandId === UNIVERSAL_BRAND) {
				filteredHeaders = [headerNoneOption, ...this.headers.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)]
				if (!this.headers) {
					this.setHeadersToNone()
				}
			} else {
				filteredHeaders = [headerNoneOption, ...this.headers.filter(({ brandId }) => brandId === this.bannerBrandId || brandId === UNIVERSAL_BRAND)]
				if (!this.headers) {
					this.setHeadersToNone()
				}
			}
			return filteredHeaders
		},

		footerOptions () {
			const footerNoneOption = {
				footerName: 'None',
				id: 0
			}

			let filteredFooters = []
			if (this.bannerBrandId === UNIVERSAL_BRAND) {
				filteredFooters = [footerNoneOption, ...this.footers.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)]
				if (!this.footers) {
					this.setfootersToNone()
				}
			} else {
				filteredFooters = [footerNoneOption, ...this.footers.filter(({ brandId }) => brandId === this.bannerBrandId || brandId === UNIVERSAL_BRAND)]
				if (!this.footers) {
					this.setHeadersToNone()
				}
			}

			return filteredFooters
		},
		isCustomScheme () {
			return this.formData.selectedColourScheme === colourSchemeEnum.custom
		},
		availableCookieCategories () {
			// Filter already selected categories from those available to select
			let filteredCookieCategories = []

			if (this.bannerBrandId === UNIVERSAL_BRAND) {
				filteredCookieCategories = this.cookieCategories.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				filteredCookieCategories = this.cookieCategories.filter(({ brandId }) => brandId === this.bannerBrandId || brandId === UNIVERSAL_BRAND)
			}

			if (this.formData.selectedCategories.length === 0) { return filteredCookieCategories }

			const selectedCategoryIds = this.formData.selectedCategories.map(x => {
				return x.cookieCategoryId
			})
			const availableCategories = filteredCookieCategories.filter(
				x => !selectedCategoryIds.includes(x.categoryId)
			)
			return availableCategories.map(category => ({
				...category,
				categoryName: `${category.categoryName} - ${category.brandName}`
			}))
		},
		showSelectAll () {
			const essentialCategory = this.formData.selectedCategories.find(({ type }) => type === 'Essential')
			if (essentialCategory) {
				return this.formData.selectedCategories.length === 1
			} else {
				return this.formData.selectedCategories.length === 0
			}
		},
		isEssentialCategorySelected () {
			const essentialIndex = this.formData.selectedCategories.findIndex(
				category => category.type === 'Essential'
			)
			return essentialIndex !== -1
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		}
	},
	watch: {
		formData: {
			deep: true,
			handler (val) {
				if (!this.isCustomScheme) {
					const scheme = this.colorSchemes.find(
						scheme => scheme.identifier === this.formData.selectedColourScheme
					)
					val.selectedColourSchemeData = scheme
				} else {
					val.selectedColourSchemeData = this.customScheme
				}
				this.$emit('change', val)
			}
		}
	},
	created () {
		this.interval = setInterval(() => {
			this.cacheKey = +new Date()
		}, 60 * 1000)
		if (!this.isEditMode) this.resetCustomiseButtons()
	},
	destroyed () {
		clearInterval(this.interval)
	},

	async mounted () {
		// Navigating directly to a consent banner can bypass some state mutations
		// Set required values if needed
		if (this.currentConsentBannerId == null) {
			this.setCurrentBannerId(this.$route.params.cookieBannerId)
		}
		if (this.configurationStatusId === 0) {
			const configurationStatusId = (
				await getConsentBannerConfigurationStatus(this.$route.params.cookieBannerId)
			).data.configurationStatus
			this.setConfigurationStatusId(configurationStatusId)
		}

		await Promise.all([
			this.loadHeadersAndFooters(),
			this.getCookieCategories(),
			this.getConsentBannerProfiles(this.$route.params.cookieBannerId),
			this.getConsentBannerCategories(this.$route.params.cookieBannerId)
		]).finally(() => {
			this.formData.Headers = this.headers
			this.formData.Footers = this.footers
			this.isEditMode && this.populateForm()
			if (!this.isEditMode) {
				this.autoAddEssentialCategory()
			}
			this.$emit('change', this.formData)
			this.loading = false
		})
		this.$refs.validationForm.reset()
	},
	methods: {
		...mapActions('headers', ['getHeaders', 'getHeader']),
		...mapActions('footers', ['getFooters', 'getFooter']),
		...mapActions('cookieCategories', [
			'getCookieCategories',
			'getCookieCategory',
			'createCookieCategory',
			'updateCookieCategory'
		]),
		...mapActions('assets', ['uploadBannerLogo']),
		...mapActions('consentBanners', [
			'setCurrentBannerId',
			'updateBannerCategories',
			'setBannerPreviewData',
			'updateConsentBannerConfiguration',
			'publishConsentBannerProfile',
			'updateConfigurationStatusId',
			'getConsentBannerProfiles',
			'getConsentBannerCategories',
			'setConfigurationStatusId'
		]),
		async loadHeadersAndFooters () {
			await Promise.all([this.getHeaders(), this.getFooters()])
		},
		autoAddEssentialCategory () {
			const essentialCategory = this.cookieCategories.find(
				category => category.isStrictlyCategory === true
			)
			if (essentialCategory) {
				this.addCategoryToBanner(essentialCategory)
			}
		},
		async proceedFromBanner () {
			this.formUpdated = true
			await this.submitBannerCategories()
			await this.submitBannerData()
		},

		async submitBannerCategories () {
			const bannerCategories = this.formData.selectedCategories

			bannerCategories.forEach(category => {
				delete category.cookieCategoryName
				delete category.type
			})

			const bannerCategoryData = {
				consentBannerId: this.$route.params.cookieBannerId,
				categories: bannerCategories
			}

			this.updateBannerCategories(
				bannerCategoryData,
				this.formData.selectedCategories
			)
		},

		async submitBannerData () {
			const bannerData = {
				consentBannerId: this.$route.params.cookieBannerId,
				footerId: this.formData.selectedFooterId,
				headerId: this.formData.selectedHeaderId,
				logoImgSrc: this.formData.uploadedImageTag,
				expandLabel: this.formData.displayExpandCollapseLabels
					? this.formData.expandLabel
					: '',
				collapseLabel: this.formData.displayExpandCollapseLabels
					? this.formData.collapseLabel
					: '',
				customUserConsentTabLabel: this.formData.userConsentTabLabel,
				customLegitimateInterestTabLabel: this.formData
					.legitimateinterestTabLabel,
				optInLabel: this.formData.displayToggleLabels
					? this.formData.optInLabel
					: '',
				optOutLabel: this.formData.displayToggleLabels
					? this.formData.optOutLabel
					: '',
				selectedWidgetAppearance: this.formData.bannerLocation,
				colourSchemeJson: JSON.stringify(this.selectedColourSchemeData),
				autoDisplayConsentBannerOnPageLoad: this.formData.autoShowModal,
				savePreferencesButtonText: this.formData.submitPreferencesText
			}

			const cookiesPreviewData = {
				footerId: bannerData.footerId,
				headerId: bannerData.headerId,
				logoImgSrc: bannerData.uploadedImageTag,
				expandLabel: bannerData.displayExpandCollapseLabels
					? bannerData.expandLabel
					: '',
				collapseLabel: bannerData.displayExpandCollapseLabels
					? bannerData.collapseLabel
					: '',
				userConsentTabLabel: bannerData.customUserConsentTabLabel,
				legitimateInterestTabLabel: bannerData.customLegitimateInterestTabLabel,
				optInLabel: bannerData.displayToggleLabels ? bannerData.optInLabel : '',
				optOutLabel: bannerData.displayToggleLabels
					? bannerData.optOutLabel
					: '',
				selectedBannerAppearance: bannerData.selectedWidgetAppearance,
				bannerColourSchemeJson: JSON.stringify(this.selectedColourSchemeData),
				autoDisplayConsentBannerOnPageLoad: bannerData.autoShowModal,
				savePreferencesText: bannerData.savePreferencesButtonText
			}

			this.setBannerPreviewData(cookiesPreviewData)

			const defaultProfileId = this.consentBannerProfiles[0].profileId
			const updateBannerStatusObject = {
				consentBannerId: this.$route.params.cookieBannerId,
				configurationStatusId: 4
			}

			await this.updateConsentBannerConfiguration(bannerData)
			await this.publishConsentBannerProfile(defaultProfileId)

			// dont execute if the last saved step is greater than the current step
			if (this.configurationStatusId <= this.currentStep) {
				await this.updateConfigurationStatusId(updateBannerStatusObject)
			}

			if (this.configurationStatusId >= this.currentStep) {
				this.showSnackbar('Banner updated successfully')
			} else if (this.configurationStatusId < this.currentStep) {
				this.$emit('next')
			}
		},
		populateForm () {
			this.formData.autoShowModal = this.banner.autoDisplayConsentBannerOnPageLoad
			this.formData.bannerLocation = this.banner.selectedBannerAppearance
			this.formData.selectedHeaderId = this.banner.headerId
			this.formData.selectedFooterId = this.banner.footerId
			this.formData.userConsentTabLabel = this.banner.userConsentTabLabel
			this.formData.legitimateinterestTabLabel = this.banner.legitimateInterestTabLabel
			this.formData.optInLabel = this.banner.optInLabel
			this.formData.optOutLabel = this.banner.optOutLabel
			this.formData.expandLabel = this.banner.viewCookiesText
			this.formData.collapseLabel = this.banner.hideCookiesText
			this.formData.createdByName = this.banner.createdByName
			this.formData.editedByName = this.banner.editedByName
			this.formData.uploadedImageTag = this.banner.logoImgSrc
			if (this.formData.uploadedImageTag !== null) {
				this.formData.displayLogo = true
			}
			this.formData.submitPreferencesText = this.banner.savePreferencesText
			this.reformatDate(this.banner.createdDate, this.banner.lastEditedDate)
			const bannerPropScheme = JSON.parse(this.banner.bannerColourSchemeJson)
			this.formData.selectedColourSchemeData = bannerPropScheme
			this.formData.selectedColourScheme =
				bannerPropScheme.identifier ?? colourSchemeEnum.dark
			this.populateCustomColors(bannerPropScheme)
			if (this.currentBannerCategories.length > 0) {
				this.populateCategories()
			}
			if (bannerPropScheme === '') {
				this.sheet = true
			}
		},
		populateCustomColors (bannerColourScheme) {
			for (const key in bannerColourScheme) {
				if (!Object.hasOwnProperty.call(bannerColourScheme, key)) continue
				const color = bannerColourScheme[key]
				const matchedColor = this.formData.customiseButtons.find(
					x => x.id.toLocaleLowerCase() === key.toLocaleLowerCase()
				)
				if (matchedColor) matchedColor.color = color
			}
		},
		populateCategories () {
			this.formData.selectedCategories = []
			// Populate selected categories data table from store
			const bannerCategories = this.currentBannerCategories

			for (const element of bannerCategories) {
				const cookieCategory = {
					cookieCategoryName: element.cookieCategoryName,
					displayOrder: element.displayOrder,
					cookieCategoryId: element.cookieCategoryId,
					type: element.type
				}
				this.formData.selectedCategories.push(cookieCategory)
			}
		},

		openColourPicker (element) {
			this.pickerColour = element.color
			this.showColourPicker = true
		},
		getColour (color, element) {
			const matchingColor = element.id
			element.color = color
			this.formData.colourScheme[matchingColor] = color
		},

		addCategoryToBanner (selectedCategory) {
			const isEssential = selectedCategory.isStrictlyCategory
			// Essential category must always be first, so move others down before adding
			if (isEssential) {
				this.formData.selectedCategories.forEach(category => {
					category.displayOrder += 1
				})
			}

			const category = {
				cookieCategoryId: selectedCategory.categoryId,
				cookieCategoryName: selectedCategory.categoryName,
				type: isEssential ? 'Essential' : 'Consent Required',
				displayOrder: isEssential
					? 1
					: this.formData.selectedCategories.length + 1,
				description: selectedCategory.displayText
			}

			this.formData.selectedCategories.push(category)
			this.formData.selectedCategory = {}
		},
		addCookieCategoriesToBanner () {
			const categories = this.selectedCookieCategories.map(selectedCategory => ({
				cookieCategoryId: selectedCategory.categoryId,
				cookieCategoryName: selectedCategory.categoryName,
				type: selectedCategory.isStrictlyCategory ? 'Essential' : 'Consent Required',
				description: selectedCategory.displayText
			}))
			categories.forEach(selectedCategory => {
				selectedCategory.displayOrder = selectedCategory.type === 'Essential'
					? 1
					: this.formData.selectedCategories.length + 1
				if (selectedCategory.type === 'Essential') {
					this.formData.selectedCategories.forEach(currentCategory => {
						currentCategory.displayOrder += 1
					})
				}
				this.formData.selectedCategories.push(selectedCategory)
			})
			this.selectedCookieCategories = []
		},
		toggleSelectAll () {
			if (this.selectedCookieCategories.length === this.availableCookieCategories.length) {
				this.selectAllChecked = false
				this.selectedCookieCategories = []
			} else {
				this.selectAllChecked = true
				this.selectedCookieCategories = this.availableCookieCategories
			}
		},
		updateSelectAllChecked () {
			if (this.selectedCookieCategories.length !== this.availableCookieCategories.length) {
				this.selectAllChecked = false
			} else {
				this.selectAllChecked = true
			}
		},
		deleteCategoryFromConsentBanner (item) {
			const toBeDeletedCategory = this.formData.selectedCategories.find(
				c => c.cookieCategoryId === item.cookieCategoryId
			)
			this.formData.deletedCategories.push(toBeDeletedCategory)
			const deletedCategory = this.formData.selectedCategories.findIndex(
				selectedCategory =>
					selectedCategory.cookieCategoryId ===
					toBeDeletedCategory.cookieCategoryId
			)

			// update display order for all categories after the deleted to reduce by 1
			this.formData.selectedCategories.forEach(cat => {
				if (cat.displayOrder > toBeDeletedCategory.displayOrder) {
					cat.displayOrder -= 1
				}
			})

			this.formData.selectedCategories.splice(deletedCategory, 1)
		},

		moveCategory (category, direction) {
			if (direction === 'moveUp') {
				const previousCategory = this.formData.selectedCategories.find(
					previousCategory => {
						return previousCategory.displayOrder === category.displayOrder - 1
					}
				)
				category.displayOrder -= 1
				previousCategory.displayOrder += 1
			} else if (direction === 'moveDown') {
				const nextCategory = this.formData.selectedCategories.find(
					nextCategory => {
						return nextCategory.displayOrder === category.displayOrder + 1
					}
				)
				category.displayOrder += 1
				nextCategory.displayOrder -= 1
			}
		},
		selectLogo (image) {
			if (image !== null) {
				const imageType = image.type
				const imageSize = image.size
				const jpg = 'image/jpeg'
				const png = 'image/png'
				const gif = 'image/gif'
				const svg = 'image/svg'
				const isValidImageType =
					imageType === jpg ||
					imageType === png ||
					imageType === gif ||
					imageType === svg
				const isValidImageSize = imageSize < this.maxImageSize10mb
				this.formData.isValidImage = isValidImageSize && isValidImageType
				if (this.formData.isValidImage) {
					this.formData.uploadedImage = image
					this.formData.uploadedImageName = image.name
					this.formData.uploadedImageType = image.type
					this.formData.uploadedImageSize = image.size
				}
			}
		},

		clearLogo () {
			this.formData.isValidImage = !this.formData.isValidImage
			this.formData.uploadedImage = null
			this.formData.uploadedImageName = ''
			this.formData.uploadedImageType = ''
			this.formData.uploadedImageSize = ''
			this.formData.isValidImage = false
			this.formData.uploadedImageUrl = ''
			this.formData.uploadedImageTag = ''
		},

		displayLogoSwitch () {
			if (!this.formData.displayLogo) {
				this.formData.uploadedImage = null
				this.formData.uploadedImageName = ''
				this.formData.uploadedImageType = ''
				this.formData.uploadedImageSize = ''
				this.formData.isValidImage = !this.formData.isValidImage
				this.formData.uploadedImageUrl = ''
				this.formData.uploadedImageTag = ''
			}
		},

		uploadLogo () {
			const currentBanner = this.consentBanners.find(banner => {
				return banner.consentBannerId === this.$route.params.cookieBannerId
			})

			const profileId = this.consentBannerProfiles[0].profileId
			const brandId = currentBanner.brandId

			const currentBannerId = this.$route.params.cookieBannerId

			const formData = new FormData()
			formData.append('LogoFile', this.formData.uploadedImage)
			formData.append('ProfileId', profileId)
			formData.append('BrandId', brandId)
			formData.append('ConsentBannerId', currentBannerId)
			this.uploading = true
			this.uploadBannerLogo(formData)
				.then(() => {
					this.formData.uploadedImageTag = ''
					this.formData.uploadedImageUrl = this.logoUrl
					const imageTag =
				'<img src="' +
				this.formData.uploadedImageUrl +
				'?' +
				Date.now() +
				'" alt="consent-banner-logo">'
					this.formData.uploadedImageTag = imageTag
					this.showSnackbar('Image uploaded successfuly')
				})
				.catch(err => {
					this.showSnackbar({ text: err.join(' '), color: 'red' })
					this.clearLogo()
				})
				.finally(() => {
					this.uploading = false
				})
		},
		openCreateModal (type) {
			this.clearForm()
			this.associatedConsentBanners = []
			this.showModal = true
			this.formData.type = type
			this.selectedFormType = type
			this.formData.id = null
			this.editMode = false
		},
		cancelForm () {
			this.showModal = false
			this.editMode = false
		},
		clearForm () {
			this.headerFooterName = ''
			this.headerFooterHtml = ''
			this.formData.brand = Number
			this.formData.type = ''
			this.formData.id = null
			this.formData.name = ''
			this.formData.html = ''
		},
		openEditModal (type) {
			this.editMode = true
			this.selectedFormType = type
			if (type === HEADER) {
				this.getHeader(this.formData.selectedHeaderId)
					.then(header => {
						this.formData.type = HEADER
						this.formData.id = header.id
						this.formData.name = header.headerName
						this.formData.html = header.headerHtml
						this.formData.brand = header.brandId
						this.formData.associatedConsentBanners =
							header.associatedConsentBanners
						this.reformatDate(header.createdDate, header.lastEditedDate)
						this.formData.createdByName = header.createdByName
						this.formData.editedByName = header.editedByName
						this.showModal = true
					})
					.catch(errors => {
						this.errors = errors
					})
			} else if (type === FOOTER) {
				this.getFooter(this.formData.selectedFooterId)
					.then(footer => {
						this.formData.type = FOOTER
						this.formData.id = footer.id
						this.formData.name = footer.footerName
						this.formData.html = footer.footerHtml
						this.formData.brand = footer.brandId
						this.formData.associatedConsentBanners =
							footer.associatedConsentBanners
						this.reformatDate(footer.createdDate, footer.lastEditedDate)
						this.formData.createdByName = footer.createdByName
						this.formData.editedByName = footer.editedByName
						this.showModal = true
					})
					.catch(errors => {
						this.errors = errors
					})
			}
		},
		reformatDate (createdDate, lastEditedDate, type) {
			// created date
			let formattedCreatedDate = new Date(createdDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedCreatedDate.setHours(formattedCreatedDate.getHours() + 8)
			formattedCreatedDate = formattedCreatedDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			if (type === 'category') {
				this.formData.category.createdDate = formattedCreatedDate
			} else {
				this.formData.createdDate = formattedCreatedDate
			}

			// edited date
			let formattedLastEditDate = new Date(lastEditedDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedLastEditDate.setHours(formattedLastEditDate.getHours() + 8)
			formattedLastEditDate = formattedLastEditDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			if (type === 'category') {
				this.formData.category.lastEditedDate = formattedLastEditDate
			} else {
				this.formData.lastEditedDate = formattedLastEditDate
			}
		},
		openEditCategoryModal (category) {
			this.editMode = true

			this.getCookieCategory(category.cookieCategoryId)
				.then(category => {
					this.formData.category.id = category.categoryId
					this.formData.category.name = category.categoryName
					this.formData.category.html = category.displayText
					this.formData.category.brand = category.brandId
					this.formData.category.isStrictlyCategory =
						category.isStrictlyCategory
					this.formData.category.associatedBanners =
						category.associatedConsentBanner
					this.reformatDate(
						category.createdDate,
						category.lastEditedDate,
						'category'
					)
					this.formData.category.createdByName = category.createdBy
					this.formData.category.editedByName = category.editedBy
					this.showCategoryModal = true
				})
				.catch(errors => {
					this.errors = errors
				})
		},
		cancelCategoryForm () {
			this.showCategoryModal = false
		},
		openCreateCategoryModal () {
			this.formData.category.associatedBanners = []
			this.showCategoryModal = true
			this.formData.category.id = null
			this.editMode = false
			this.clearForm()
		},
		async createNewCategory (form) {
			// pass name to categoryDescription
			const newCategory = {
				categoryName: form.name,
				categoryDescription: form.name,
				brandId: form.brand,
				isStrictlyNecessary: false,
				displayText: form.html
			}

			await this.createCookieCategory(newCategory)

			this.showSnackbar('New Category successfully created')

			// reset form and close overlay
			this.showCategoryModal = false

			this.addCategoryToBanner(newCategory)
			form.id = null
			form.associatedBanners = []
			form.brand = Number
			form.html = ''
			form.name = ''
			form.createdByName = ''
			form.createdDate = ''
			form.editedByName = ''
			form.lastEditedDate = ''
		},
		async updateCategory (form) {
			const updatedCategory = {
				id: form.id,
				categoryName: form.name,
				displayText: form.html,
				brandId: form.brand,
				isStrictlyNecessary: form.isStrictlyCategory
			}
			await this.updateCookieCategory(updatedCategory)

			this.showCategoryModal = false

			const cat = this.formData.selectedCategories.find(m => m.cookieCategoryId === form.id)
			if (cat) {
				cat.cookieCategoryName = form.name
			}

			this.showSnackbar('Category - ' + form.name + ' updated successfully')
		},
		cancelBanner () {
			this.$router.push({ name: 'consent-banners' })
		},
		setHeadersToNone () {
			this.formData.selectedHeaderId = 0
		},
		setFootersToNone () {
			this.formData.selectedFooterId = 0
		},
		nextStep () {
			if (!this.formUpdated) {
				this.populateForm()
			}
			this.$emit('next')
			this.formUpdated = false
		},
		backToPreBanner () {
			if (!this.formUpdated && this.isEditMode) {
				this.populateForm()
			}
			this.$emit('back')
			this.formUpdated = false
		},
		setSelectedHeaderFooter (type, id) {
			if (type === 'headerId') {
				this.formData.selectedHeaderId = id
			} else if (type === 'footerId') {
				this.formData.selectedFooterId = id
			}
		},
		getCategoryName (id) {
			const matchingCookieCategory = this.cookieCategories.find(({ categoryId }) => categoryId === id)
			return `${matchingCookieCategory.categoryName} - ${matchingCookieCategory.brandName}`
		},
		getCategoryType (id) {
			const category = this.cookieCategories.find(({ categoryId }) => categoryId === id)
			if (category.isStrictlyCategory) return 'Essential'
			else return 'User Consent'
		},
		resetCustomiseButtons () {
			this.formData.customiseButtons = [
				{
					id: 'BackgroundColour',
					text: 'Background Colour',
					color: this.defaultCustomColourScheme.BackgroundColour,
					displayInPanels: true,
					displayInTemplate2: true
				},
				{
					id: 'TextColour',
					text: 'Text Colour',
					color: this.defaultCustomColourScheme.TextColour,
					displayInPanels: true,
					displayInTemplate2: true
				},
				{
					id: 'SubmitPreferencesButtonColour',
					text: 'Submit Preferences Button Colour',
					color: this.defaultCustomColourScheme.SubmitPreferencesButtonColour,
					displayInPanels: true,
					displayInTemplate2: true
				},
				{
					id: 'SubmitPreferencesButtonTextColour',
					text: 'Submit Preferences Text Colour',
					color: this.defaultCustomColourScheme.SubmitPreferencesButtonTextColour,
					displayInPanels: true,
					displayInTemplate2: true
				},
				{
					id: 'AcceptAllButtonColour',
					text: 'Accept All Button Colour',
					color: this.defaultCustomColourScheme.AcceptAllButtonColour,
					displayInPanels: true,
					displayInTemplate2: false
				},
				{
					id: 'AcceptAllButtonTextColour',
					text: 'Accept All Button Text Colour',
					color: this.defaultCustomColourScheme.AcceptAllButtonTextColour,
					displayInPanels: true,
					displayInTemplate2: false
				},
				{
					id: 'RejectAllButtonColour',
					text: 'Reject All Button Colour',
					color: this.defaultCustomColourScheme.RejectAllButtonColour,
					displayInPanels: true,
					displayInTemplate2: false
				},
				{
					id: 'RejectAllButtonTextColour',
					text: 'Reject All Button Text Colour',
					color: this.defaultCustomColourScheme.RejectAllButtonTextColour,
					displayInPanels: true,
					displayInTemplate2: false
				},
				{
					id: 'SecondaryColour',
					text: 'Secondary Colour',
					color: this.defaultCustomColourScheme.SecondaryColour,
					displayInPanels: true,
					displayInTemplate2: true
				},
				{
					id: 'SliderBackgroundOff',
					text: 'Slider Background Off',
					color: this.defaultCustomColourScheme.SliderBackgroundOff,
					displayInPanels: true,
					displayInTemplate2: true
				},
				{
					id: 'SliderBackgroundOn',
					text: 'Slider Background On',
					color: this.defaultCustomColourScheme.SliderBackgroundOn,
					displayInPanels: true,
					displayInTemplate2: true
				},
				{
					id: 'SliderToggle',
					text: 'Slider Toggle',
					color: this.defaultCustomColourScheme.SliderToggle,
					displayInPanels: true,
					displayInTemplate2: true
				}
			]
		}
	}
}
</script>

<style lang="scss">
.cassie-banner-settings-container {
	border: var(--global-cassie-settings-border);
	border-radius: 5px;
}

.banner-appearance-radio {
	display: flex;
	flex-direction: column-reverse;
	padding: 5px;
}

.cassie-banner-appearance-image {
	width: 75px;

	@media screen and (min-width: var(--global-cassie-breakpoint-lg)) {
		width: 125px;
	}
	@media screen and (min-width: var(--global-cassie-breakpoint-xl)) {
		width: 150px;
	}
}

.cassie-colour-picker-container {
	background-color: white;
}

.cassie-customise-button-container {
	@media screen and (min-width: var(--global-cassie-breakpoint-lg)) {
		width: 50%;
	}
}

.cassie-display-logo-switch {
	max-width: 50px;
}

.cassie-advanced-options-switch {
	max-width: 50px;
}

.cassie-preview-type-tabs-container {
	border-radius: 5px;
}
.cassie-preview-type-tab {
	font-size: 10px;
	color: white !important;
	text-transform: var(--cassie-tab-text-transform);
}

.cassie-preview-type-tab-active {
	background: var(--global-color-blue-darken-2);
	border-radius: 5px;
}
.cassie-preview-type-tab-active::before {
	opacity: 0.12;
}

.cassie-device-type-tab-active {
	color: var(--cassie-tab-active-text-color);
	background: var(--global-color-blue-darken-2) !important;
}

.cassie-device-type-tab {
	margin: 0 10px;
	font-size: 10px;
	color: var(--global-color-blue-darken-2);
	border: 1px solid var(--global-color-blue-darken-2);
	border-radius: 5px;
}

.cassie-preview-container {
	height: 500px;
}

.cassie-banner-logo {
	img {
		padding: 16px !important;
		max-width: 125px !important;
		float: right !important;
	}
}
.cassie-radio-group {
	.v-messages.v-messages {
		display: none;
	}
}
</style>
