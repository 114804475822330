<template>
	<div>
		<ValidationForm
			#default="{ handleSubmit }"
			ref="validationForm"
		>
			<div class="cassie-vertical-md">
				<SectionCard>
					<template #title>
						Translations
					</template>
					<template #subtitle>
						You can translate your Consent Banner into multiple languages. To do this
						please click on the
						{{
							currentBannerExistingTranslations.length === 0
								? '"Create Bulk Translations"'
								: '"Edit Bulk Translations"'
						}}
						link.
					</template>
					<template #title-action>
						<div>
							<TextButton
								v-if="userCanCreateUpdate"
								:disabled="!hasAvailableLanguages"
								@click="showTranslationsSingleModal = true"
							>
								<v-icon>
									mdi-plus
								</v-icon>{{ "Add Language Translation" }}
							</TextButton>
							<TextButton
								v-if="userCanCreateUpdate"
								@click="openCreateBulkTranslationModal"
							>
								<v-icon>
									mdi-plus
								</v-icon>{{
									currentBannerExistingTranslations.length === 0
										? "Create Bulk Translations"
										: "Edit Bulk Translations"
								}}
							</TextButton>
						</div>
					</template>
					<template #body>
						<div class="d-flex flex-column cassie-vertical-sm">
							<div class="text-subtitle-2">
								Associated Translations
							</div>
							<v-alert
								v-if="currentBannerExistingTranslations.length === 0"
								light
							>
								<div class="grey--text lighten-4 text-center">
									No translations have been added to this Consent Banner
								</div>
							</v-alert>
							<DataTable
								v-else
								:headers="translationsTableHeader"
								:items="currentBannerExistingTranslations"
								hide-default-footer
								@click:row="editTranslationFromConsentBanner"
							>
								<template #item.action="{ item }">
									<IconButton
										tooltip-text="Edit Translation"
										@click="editTranslationFromConsentBanner(item)"
									>
										{{ !userCanCreateUpdate ? 'mdi-eye' : 'mdi-pencil' }}
									</IconButton>
									<IconButton
										v-if="!userCanDelete"
										tooltip-text="Delete Translation"
										@click.stop.prevent="deleteTranslationFromConsentBanner(item)"
									>
										mdi-trash-can
									</IconButton>
								</template>
							</DataTable>
						</div>
					</template>
				</SectionCard>
			</div>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton @click="cancelTranslations">
						Exit
					</SecondaryActionButton>
					<v-spacer />
					<SecondaryActionButton @click="backToCookies">
						Back
					</SecondaryActionButton>
					<PrimaryActionButton
						v-if="lastSavedStep >= currentStep"
						class="ml-2"
						@click="$emit('next')"
					>
						Next
					</PrimaryActionButton>
					<PrimaryActionButton
						v-else-if="userCanCreateUpdate"
						class="ml-2"
						:loading="saving"
						@click="handleSubmit(proceedFromTranslations)"
					>
						Save and Continue
					</PrimaryActionButton>
				</template>
			</PageActionRow>
		</ValidationForm>
		<TranslationsSingleModal
			v-if="showTranslationsSingleModal"
			:edit-translation="editTranslation"
			@closeTranslationModal="
				() => {
					(showTranslationsSingleModal = false), (editTranslation = null);
				}
			"
		/>
		<TranslationsBulkModal
			v-if="showTranslationsBulkModal"
			:pre-banner="currentBannerPreBanner"
			:banner="currentBannerConfiguration"
			:banner-categories="currentBannerCategories ? currentBannerCategories : []"
			:banner-scripts="currentBannerScripts ? currentBannerScripts : []"
			@closeTranslationModal="closeBulkTranslationModal"
		/>

		<DeleteConfirmationModal
			:show="deleteOverlay"
			:title="'Delete ' + formData.deletedTranslation.languageName + ' Translation'"
			:type="'Translation'"
			@cancelDelete="deleteOverlay = false"
			@confirmDelete="confirmDelete"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ValidationForm from '../../../../../../../../shared/components/validation-form.vue'
import DeleteConfirmationModal from '../../../../../shared/delete-confirmation-modal.vue'
import SectionCard from '../../../../../../../../shared/components/section-card.vue'
import TextButton from '../../../../../../../../shared/components/text-button.vue'
import DataTable from '../../../../../../../../shared/components/data-table.vue'
import TranslationsBulkModal from './configure-translation-bulk-modal.vue'
import TranslationsSingleModal from './configure-translation-single-modal.vue'
import PageActionRow from '../../../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../../../../shared/components/icon-button.vue'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS, CAN_DELETE_CONSENT_BANNERS } from '../../../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		ValidationForm,
		SectionCard,
		TextButton,
		DataTable,
		TranslationsBulkModal,
		TranslationsSingleModal,
		DeleteConfirmationModal,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		IconButton
	},
	props: {
		preBanner: {
			type: Object,
			default: null
		},
		banner: {
			type: Object,
			default: null
		}
	},
	data () {
		return {
			currentStep: 5,
			saving: false,
			editTranslation: null,
			formData: {
				selectedTranslations: [],
				deletedTranslations: [],
				deletedTranslation: {}
			},
			showTranslationsBulkModal: false,
			showTranslationsSingleModal: false,
			deleteOverlay: false,
			translationsTableHeader: [
				{
					text: 'Language',
					value: 'languageName'
				},
				{
					text: 'Language Code',
					value: 'languageCode'
				},
				{
					text: 'Action',
					value: 'action',
					width: 100
					// align: 'right',
				}
			]
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('languages', ['assignedLanguages']),
		...mapGetters('consentBanners', [
			'lastSavedStep',
			'currentConsentBannerId',
			'currentBannerExistingTranslations',
			'currentBannerPreBanner',
			'currentBannerConfiguration',
			'currentBannerCategories',
			'currentBannerScripts',
			'currentBannerTranslationDetails'
		]),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_CONSENT_BANNERS)
		},
		hasAvailableLanguages () {
			const existingLanguages = this.currentBannerExistingTranslations.map(
				translation => translation.languageId
			)
			return (
				this.assignedLanguages
					.filter(al => !existingLanguages.includes(al.languageID))
					.map(al => {
						return {
							languageName: al.languageName,
							languageId: al.languageID
						}
					}).filter(l => l.languageId !== 0).length > 0
			)
		}
	},
	async mounted () {
		await this.getAssignedLanguages()
		await this.getHeaders()
		await this.getFooters()
		await this.getConsentBannerCategories(this.$route.params.cookieBannerId)
		await this.getConsentBannerPreBanner(this.$route.params.cookieBannerId)
		await this.getGpcPreBanner(this.$route.params.cookieBannerId)
		await this.getConsentBannerConfiguration(this.$route.params.cookieBannerId)
		await this.getConsentBannerScripts(this.$route.params.cookieBannerId)
		await this.getAllTranslations(this.$route.params.cookieBannerId)
		this.$refs.validationForm.reset()
	},
	methods: {
		...mapActions('languages', ['getAssignedLanguages']),
		...mapActions('headers', ['getHeaders']),
		...mapActions('footers', ['getFooters']),
		...mapActions('consentBanners', [
			'updateConfigurationStatusId',
			'deleteTranslation',
			'getConsentBannerCategories',
			'getConsentBannerPreBanner',
			'getGpcPreBanner',
			'getConsentBannerConfiguration',
			'getConsentBannerScripts',
			'getTranslation',
			'getAllTranslations'
		]),
		proceedFromTranslations () {
			this.saving = true
			const updateBannerStatusObject = {
				consentBannerId: this.$route.params.cookieBannerId,
				configurationStatusId: 6
			}
			this.updateConfigurationStatusId(updateBannerStatusObject)
			this.saving = false
			this.$emit('next')
		},
		backToCookies () {
			this.$emit('back')
		},
		cancelTranslations () {
			this.$router.push({ name: 'consent-banners' })
		},
		closeBulkTranslationModal () {
			this.showTranslationsBulkModal = false
		},
		// Toggle scrollbar using class replicating swal2 behaviour
		preventScrolling () {
			document.documentElement.classList.add('prevent-scroll')
		},
		allowScrolling () {
			document.documentElement.classList.remove('prevent-scroll')
		},
		openCreateBulkTranslationModal () {
			this.showTranslationsBulkModal = true
		},
		async deleteTranslationFromConsentBanner (translation) {
			this.formData.deletedTranslation = translation

			this.deleteOverlay = true
		},
		async editTranslationFromConsentBanner (translation) {
			await this.getTranslation(translation)
			this.editTranslation = this.currentBannerTranslationDetails
			this.showTranslationsSingleModal = true
		},
		async confirmDelete () {
			this.deleteOverlay = false
			const deleted = {
				languageId: this.formData.deletedTranslation.languageId,
				consentBannerId: this.formData.deletedTranslation.consentBannerId
			}
			await this.deleteTranslation(deleted)
		}
	}
}
</script>

<style lang="scss" module>
.empty-table-alert {
  color: rgba(148, 159, 182) !important;
  text-align: center !important;
  vertical-align: middle !important;
}
</style>
