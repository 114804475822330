<template>
	<!-- eslint-disable max-lines -->
	<ValidationForm
		#default="{ invalid }"
		ref="validationForm"
	>
		<Modal
			width="850px"
		>
			<template #modal-title>
				{{ editMode ? 'Edit ' : 'Create New ' }}Consent Banner Profile
				<v-spacer />
				<IconButton
					@click="$emit('cancelForm')"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div
					class="cassie-vertical-md"
				>
					<div class="d-flex flex-row">
						<div
							v-if="editMode"
							class="text-caption"
						>
							Created By: {{ formData.createdByName }}
							{{ formData.createdDate }}
							<span
								v-if="formData.lastEditedDate && formData.editedByName !== ' '"
							>
								| Last Edited By: {{ formData.editedByName }}
								{{ formData.lastEditedDate }}
							</span>
						</div>
					</div>
					<TextField
						v-model="formData.profileName"
						label="Name*"
						class="cassie-input-width-xl"
						rules="required"
					/>
					<Dropdown
						v-model="formData.accessKey"
						label="Access Key *"
						class="cassie-input-width-xl"
						:items="filteredAccessKeys"
						item-text="accessKeyName"
						item-value="accessKeyId"
						rules="required"
						@change="accessKeyName"
					/>
					<TextField
						v-model.number="formData.cassieCookiesExpiry"
						type="number"
						label="Cassie Cookies Expiry (In Days) *"
						class="cassie-input-width-xl"
						rules="required"
					/>
					<div class="d-flex flex-column">
						<FroalaEditor
							v-if="userCanCreateUpdate"
							v-model="formData.profileDescription"
							:rules="{required: false, max: 2000}"
							title="Description"
						/>
						<div
							v-else
							v-dompurify-html="formData.profileDescription"
							class="mt-2 cassie-disabled-textarea rounded-lg"
						/>
					</div>
					<div class="d-flex flex-column cassie-vertical-sm">
						<Toggle
							:value.sync="formData.loadTemplateHTML"
							label="Load Consent Banners HTML"
						/>
						<Toggle
							:value.sync="formData.loadTemplateCSS"
							label="Load Consent Banners CSS"
						/>
						<Toggle
							:value.sync="formData.loadTemplateJS"
							label="Load Consent Banners JavaScript"
						/>
						<Toggle
							:value.sync="formData.isGpc"
							label="Global Privacy Control (GPC)"
							tooltip-text="Enable if you wish for this Consent Banner to detect GPC signals from user's browsers."
						/>
					</div>
					<v-divider />
					<div class="cassie-vertical-md">
						<div class="text-subtitle-2">
							Pre Banner
						</div>
						<v-row dense>
							<v-col cols="6">
								<Dropdown
									v-model="formData.selectedPreBannerAppearance"
									label="Pre Banner Appearance *"
									rules="required"
									:items="preBannerAppearanceItems"
								/>
							</v-col>
							<v-col cols="6">
								<Dropdown
									v-model="formData.selectedPreBannerColourScheme"
									label="Pre Banner Colour Scheme *"
									rules="required"
									:items="colourSchemeItems"
								/>
							</v-col>
						</v-row>
						<ColourPicker
							v-if="isPreBannerCustomScheme && formData.customisePreBannerButtons"
							:elements.sync="formData.customisePreBannerButtons"
						/>
						<v-divider />
					</div>
					<div class="cassie-vertical-md">
						<div class="text-subtitle-2">
							GPC Banner Appearance*
						</div>
						<v-row dense>
							<v-col cols="6">
								<Dropdown
									v-model="formData.selectedGpcBannerAppearance"
									label="GPC Banner Appearance *"
									rules="required"
									:items="preBannerAppearanceItems"
								/>
							</v-col>
							<v-col cols="6">
								<Dropdown
									v-model="formData.selectedGpcBannerColourScheme"
									label="GPC Banner Colour Scheme *"
									rules="required"
									:items="colourSchemeItems"
								/>
							</v-col>
						</v-row>
						<ColourPicker
							v-if="isGpcCustomScheme && formData.customiseGpcButtons"
							:elements.sync="formData.customiseGpcButtons"
						/>
						<v-divider />
					</div>
					<div class="d-flex flex-column cassie-vertical-md">
						<div class="text-subtitle-2">
							Banner Appearance*
						</div>
						<v-row dense>
							<v-col cols="6">
								<Dropdown
									v-model="formData.selectedBannerAppearance"
									label="Banner Appearance *"
									rules="required"
									:items="bannerAppearanceItems"
								/>
							</v-col>
							<v-col cols="6">
								<Dropdown
									v-model="formData.selectedBannerColourScheme"
									label="Banner Colour Scheme *"
									rules="required"
									:items="colourSchemeItems"
								/>
							</v-col>
						</v-row>
						<ColourPicker
							v-if="isBannerCustomScheme && formData.customiseBannerButtons"
							:elements.sync="formData.customiseBannerButtons"
						/>
					</div>
					<Message v-if="editMode">
						Changes made to this Profile will affect any websites
						currently using it.
					</Message>
				</div>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton
					@click="$emit('cancelForm')"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					:disabled="!userCanCreateUpdate || invalid"
					@click="
						!editMode
							? createNewProfile(formData)
							: updateProfile(formData)
					"
				>
					{{ !editMode ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../../../../shared/components/validation-form.vue'
import FroalaEditor from '../../../../../shared/froala-editor.vue'
import Modal from '../../../../../../../../shared/components/modal.vue'
import TextField from '../../../../../../../../shared/components/text-field.vue'
import ColourPicker from '../../../../../../../../shared/components/colour-picker.vue'
import Dropdown from '../../../../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../../../../shared/components/toggle.vue'
import PrimaryActionButton from '../../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../../../../shared/components/icon-button.vue'
import Message from '../../../../../../../../shared/components/message.vue'
import bannerPositions from '../../../../../../enums/bannerPositions.js'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS } from '../../../../../../../../shared/permissions/admin-portal-permissions.js'
const preBannerColourSchemeEnum = {
	dark: 0,
	light: 1,
	custom: 2
}
const darkPreBannerColourScheme = {
	identifier: preBannerColourSchemeEnum.dark,
	BackgroundColour: '#2e2e37',
	SecondaryColour: '#4f4f50',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#e31f26',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#e31f26',
	RejectAllButtonTextColour: '#FFFFFF',
	CustomiseAllButtonColour: '#e31f26',
	CustomiseAllButtonTextColour: '#FFFFFF'
}
const lightPreBannerColourScheme = {
	identifier: preBannerColourSchemeEnum.light,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#4f4f50',
	TextColour: '#000000',
	AcceptAllButtonColour: '#0679ab',
	AcceptAllButtonTextColour: '#000000',
	RejectAllButtonColour: '#0679ab',
	RejectAllButtonTextColour: '#000000',
	CustomiseAllButtonColour: '#0679ab',
	CustomiseAllButtonTextColour: '#000000'
}

const darkGpcColourScheme = {
	identifier: preBannerColourSchemeEnum.dark,
	BackgroundColour: '#2e2e37',
	SecondaryColour: '#4f4f50',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#e31f26',
	AcceptAllButtonTextColour: '#FFFFFF',
	AcceptAllExcGpcButtonColour: '#e31f26',
	AcceptAllExcGpcButtonTextColour: '#FFFFFF',
	BackButtonColour: '#e31f26',
	BackButtonTextColour: '#FFFFFF'
}
const lightGpcColourScheme = {
	identifier: preBannerColourSchemeEnum.light,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#4f4f50',
	TextColour: '#000000',
	AcceptAllButtonColour: '#0679ab',
	AcceptAllButtonTextColour: '#000000',
	AcceptAllExcGpcButtonColour: '#0679ab',
	AcceptAllExcGpcButtonTextColour: '#000000',
	BackButtonColour: '#0679ab',
	BackButtonTextColour: '#000000'
}

const bannerColourSchemeEnum = {
	dark: 0,
	light: 1,
	custom: 2
}
const darkBannerColourScheme = {
	identifier: bannerColourSchemeEnum.dark,
	BackgroundColour: '#2e2e37',
	SecondaryColour: '#4f4f50',
	TextColour: '#FFFFFF',
	SubmitPreferencesButtonColour: '#e31f26',
	SubmitPreferencesButtonTextColour: '#FFFFFF',
	AcceptAllButtonColour: '#2e2e37',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#2e2e37',
	RejectAllButtonTextColour: '#FFFFFF',
	SliderBackgroundOn: '#e31f26',
	SliderBackgroundOff: '#4f4f50',
	SliderToggle: '#FFFFFF'
}
const lightBannerColourScheme = {
	identifier: bannerColourSchemeEnum.light,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#4f4f50',
	TextColour: '#000000',
	SubmitPreferencesButtonColour: '#e31f26',
	SubmitPreferencesButtonTextColour: '#FFFFFF',
	AcceptAllButtonColour: '#2e2e37',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#FFFFFF',
	RejectAllButtonTextColour: '#2e2e37',
	SliderBackgroundOn: '#e31f26',
	SliderBackgroundOff: '#4f4f50',
	SliderToggle: '#FFFFFF'
}
const defaultGpcCustomScheme = {
	BackgroundColour: '#5D707F',
	SecondaryColour: '#6D8A96',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#66CED6',
	AcceptAllButtonTextColour: '#FFFFFF',
	AcceptAllExcGpcButtonColour: '#e31f26',
	AcceptAllExcGpcButtonTextColour: '#FFFFFF',
	BackButtonColour: '#e31f26',
	BackButtonTextColour: '#FFFFFF'
}
const defaultPreBannerCustomScheme = {
	BackgroundColour: '#5D707F',
	SecondaryColour: '#6D8A96',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#66CED6',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#A7A5C6',
	RejectAllButtonTextColour: '#FFFFFF',
	CustomiseAllButtonColour: '#8797B2',
	CustomiseAllButtonTextColour: '#FFFFFF'
}
const defaultBannerCustomScheme = {
	identifier: '2',
	BackgroundColour: '#5D707F',
	SecondaryColour: '#6D8A96',
	TextColour: '#FFFFFF',
	SubmitPreferencesButtonColour: '#66CED6',
	SubmitPreferencesButtonTextColour: '#000000',
	AcceptAllButtonColour: '#8797B2',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#A7A5C6',
	RejectAllButtonTextColour: '#FFFFFF',
	CustomiseAllButtonColour: '#8797B2',
	CustomiseAllButtonTextColour: '#FFFFFF',
	SliderBackgroundOff: 'grey',
	SliderBackgroundOn: 'grey',
	SliderToggle: '#FFFFFF'
}
const UNIVERSAL_BRAND = 0
export default {
	components: {
		ValidationForm,
		FroalaEditor,
		Modal,
		IconButton,
		TextField,
		ColourPicker,
		Dropdown,
		Message,
		Toggle,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		form: {
			type: Object
		},
		bannerBrandId: Number
	},
	data () {
		return {
			UNIVERSAL_BRAND,
			preBannerColourSchemes: [
				darkPreBannerColourScheme,
				lightPreBannerColourScheme
			],
			gpcColourSchemes: [darkGpcColourScheme, lightGpcColourScheme],
			lightGpcColourScheme,
			darkGpcColourScheme,
			preBannerColourSchemeEnum: preBannerColourSchemeEnum,
			bannerColourSchemes: [darkBannerColourScheme, lightBannerColourScheme],
			bannerColourSchemeEnum: bannerColourSchemeEnum,
			bannerPositions: bannerPositions,
			formData: {
				profileId: null,
				profileName: '',
				profileDescription: '',
				accessKey: Number,
				accessKeyName: '',
				loadTemplateHTML: true,
				loadTemplateCSS: true,
				loadTemplateJS: true,
				cassieCookiesExpiry: 365,
				isGpc: false,
				createdByName: '',
				createdDate: '',
				editedByName: '',
				lastEditedDate: '',
				selectedPreBannerAppearance: 2,
				selectedPreBannerColourScheme: preBannerColourSchemeEnum.dark,
				selectedPreBannerColourSchemeData: darkPreBannerColourScheme,
				selectedGpcBannerAppearance: 2,
				selectedGpcBannerColourScheme: preBannerColourSchemeEnum.dark,
				selectedGpcBannerColourSchemeData: this.darkGpcBannerColourScheme,
				selectedBannerAppearance: bannerPositions.left,
				selectedBannerColourScheme: bannerColourSchemeEnum.dark,
				selectedBannerColourSchemeData: darkBannerColourScheme,
				customisePreBannerButtons: [
					{
						id: 'BackgroundColour',
						text: 'Background Colour',
						color: defaultPreBannerCustomScheme.BackgroundColour
					},
					{
						id: 'TextColour',
						text: 'Text Colour',
						color: defaultPreBannerCustomScheme.TextColour
					},
					{
						id: 'AcceptAllButtonColour',
						text: 'Accept All Button Colour',
						color: defaultPreBannerCustomScheme.AcceptAllButtonColour
					},
					{
						id: 'AcceptAllButtonTextColour',
						text: 'Accept All Button Text Colour',
						color: defaultPreBannerCustomScheme.AcceptAllButtonTextColour
					},
					{
						id: 'RejectAllButtonColour',
						text: 'Reject All Button Colour',
						color: defaultPreBannerCustomScheme.RejectAllButtonColour
					},
					{
						id: 'RejectAllButtonTextColour',
						text: 'Reject All Button Text Colour',
						color: defaultPreBannerCustomScheme.RejectAllButtonTextColour
					},
					{
						id: 'CustomiseAllButtonColour',
						text: 'Customise Button Colour',
						color: defaultPreBannerCustomScheme.CustomiseAllButtonColour
					},
					{
						id: 'CustomiseAllButtonTextColour',
						text: 'Customise Button Text Colour',
						color: defaultPreBannerCustomScheme.CustomiseAllButtonTextColour
					},
					{
						id: 'SecondaryColour',
						text: 'Secondary Colour',
						color: defaultPreBannerCustomScheme.SecondaryColour
					}
				],
				customiseGpcButtons: [
					{
						id: 'BackgroundColour',
						text: 'Background Colour',
						color: defaultGpcCustomScheme.BackgroundColour
					},
					{
						id: 'TextColour',
						text: 'Text Colour',
						color: defaultGpcCustomScheme.TextColour
					},
					{
						id: 'AcceptAllButtonColour',
						text: 'Accept All Button Colour',
						color: defaultGpcCustomScheme.AcceptAllButtonColour
					},
					{
						id: 'AcceptAllButtonTextColour',
						text: 'Accept All Button Text Colour',
						color: defaultGpcCustomScheme.AcceptAllButtonTextColour
					},
					{
						id: 'AcceptAllExcGpcButtonColour',
						text: 'Accept All Exc. GPC Button Colour',
						color: defaultGpcCustomScheme.AcceptAllExcGpcButtonColour
					},
					{
						id: 'AcceptAllExcGpcButtonTextColour',
						text: 'Accept All Exc. GPC Button Text Colour',
						color: defaultGpcCustomScheme.AcceptAllExcGpcButtonTextColour
					},
					{
						id: 'BackButtonColour',
						text: 'Back Button Colour',
						color: defaultGpcCustomScheme.BackButtonColour
					},
					{
						id: 'BackButtonTextColour',
						text: 'Back Button Text Colour',
						color: defaultGpcCustomScheme.BackButtonTextColour
					},
					{
						id: 'SecondaryColour',
						text: 'Secondary Colour',
						color: defaultGpcCustomScheme.SecondaryColour
					}
				],
				customiseBannerButtons: [
					{
						id: 'BackgroundColour',
						text: 'Background Colour',
						color: defaultBannerCustomScheme.BackgroundColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'TextColour',
						text: 'Text Colour',
						color: defaultBannerCustomScheme.TextColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SubmitPreferencesButtonColour',
						text: 'Submit Preferences Button Colour',
						color: defaultBannerCustomScheme.SubmitPreferencesButtonColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SubmitPreferencesButtonTextColour',
						text: 'Submit Preferences Text Colour',
						color: defaultBannerCustomScheme.SubmitPreferencesButtonTextColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'AcceptAllButtonColour',
						text: 'Accept All Button Colour',
						color: defaultBannerCustomScheme.AcceptAllButtonColour,
						displayInPanels: true,
						displayInTemplate2: false
					},
					{
						id: 'AcceptAllButtonTextColour',
						text: 'Accept All Button Text Colour',
						color: defaultBannerCustomScheme.AcceptAllButtonTextColour,
						displayInPanels: true,
						displayInTemplate2: false
					},
					{
						id: 'RejectAllButtonColour',
						text: 'Reject All Button Colour',
						color: defaultBannerCustomScheme.RejectAllButtonColour,
						displayInPanels: true,
						displayInTemplate2: false
					},
					{
						id: 'RejectAllButtonTextColour',
						text: 'Reject All Button Text Colour',
						color: defaultBannerCustomScheme.RejectAllButtonTextColour,
						displayInPanels: true,
						displayInTemplate2: false
					},
					{
						id: 'SecondaryColour',
						text: 'Secondary Colour',
						color: defaultBannerCustomScheme.SecondaryColour,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SliderBackgroundOff',
						text: 'Slider Background Off',
						color: defaultBannerCustomScheme.SliderBackgroundOff,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SliderBackgroundOn',
						text: 'Slider Background On',
						color: defaultBannerCustomScheme.SliderBackgroundOn,
						displayInPanels: true,
						displayInTemplate2: true
					},
					{
						id: 'SliderToggle',
						text: 'Slider Toggle',
						color: defaultBannerCustomScheme.SliderToggle,
						displayInPanels: true,
						displayInTemplate2: true
					}
				]
			},
			isValid: false,
			editMode: false,
			isLoading: false,
			showPreBannerColourPicker: false,
			preBannerPickerColour: '',
			showBannerColourPicker: false,
			bannerPickerColour: ''
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('accessKeys', ['accessKeys']),
		isPreBannerCustomScheme () {
			return (
				this.formData.selectedPreBannerColourScheme ===
				preBannerColourSchemeEnum.custom
			)
		},
		isBannerCustomScheme () {
			return (
				this.formData.selectedBannerColourScheme ===
				bannerColourSchemeEnum.custom
			)
		},
		isGpcCustomScheme () {
			return this.formData.selectedGpcBannerColourScheme ===
			preBannerColourSchemeEnum.custom
		},
		customPreBannerScheme () {
			const customiseColors = this.formData.customisePreBannerButtons.reduce(
				(obj, item) => ({ ...obj, [item.id]: item.color }),
				{}
			)
			customiseColors.identifier = preBannerColourSchemeEnum.custom
			return customiseColors
		},
		customBannerScheme () {
			const customiseColors = this.formData.customiseBannerButtons.reduce(
				(obj, item) => ({ ...obj, [item.id]: item.color }),
				{}
			)
			customiseColors.identifier = bannerColourSchemeEnum.custom
			return customiseColors
		},
		gpcCustomScheme () {
			const customiseColors = this.formData.customiseGpcButtons.reduce(
				(obj, item) => ({ ...obj, [item.id]: item.color }),
				{}
			)
			customiseColors.identifier = preBannerColourSchemeEnum.custom
			return customiseColors
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		},
		filteredAccessKeys () {
			let filteredAccessKeys = []
			if (this.bannerBrandId === UNIVERSAL_BRAND) {
				filteredAccessKeys = this.accessKeys.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				filteredAccessKeys = this.accessKeys.filter(({ brandId }) => brandId === this.bannerBrandId || brandId === UNIVERSAL_BRAND)
			}
			return filteredAccessKeys
		},
		preBannerAppearanceItems () {
			return [
				{
					text: 'Bottom',
					value: 2
				},
				{
					text: 'Top',
					value: 0
				},
				{
					text: 'Middle',
					value: 1
				}
			]
		},
		bannerAppearanceItems () {
			return [
				{
					text: 'Middle',
					value: 0
				},
				{
					text: 'Right',
					value: 1
				},
				{
					text: 'Left',
					value: 2
				},
				{
					text: 'Panels',
					value: 3
				}
			]
		},
		colourSchemeItems () {
			return [
				{
					text: 'Dark',
					value: 0
				},
				{
					text: 'Light',
					value: 1
				},
				{
					text: 'Custom',
					value: 2
				}
			]
		}
	},
	watch: {
		formData: {
			deep: true,
			handler (val) {
				if (!this.isPreBannerCustomScheme) {
					const preBannerScheme = this.preBannerColourSchemes.find(
						preBannerScheme =>
							preBannerScheme.identifier ===
							this.formData.selectedPreBannerColourScheme
					)
					val.selectedPreBannerColourSchemeData = preBannerScheme
				} else {
					val.selectedPreBannerColourSchemeData = this.customPreBannerScheme
				}
				if (!this.isBannerCustomScheme) {
					const bannerScheme = this.bannerColourSchemes.find(
						bannerScheme =>
							bannerScheme.identifier ===
							this.formData.selectedBannerColourScheme
					)
					val.selectedBannerColourSchemeData = bannerScheme
				} else {
					val.selectedBannerColourSchemeData = this.customBannerScheme
				}
				if (!this.isGpcCustomScheme) {
					const scheme = this.gpcColourSchemes.find(
						scheme => scheme.identifier === this.formData.selectedGpcBannerColourScheme
					)
					val.selectedGpcBannerColourSchemeData = scheme
				} else {
					val.selectedGpcBannerColourSchemeData = this.gpcCustomScheme
				}
				this.$emit('change', val)
			}
		}
	},

	mounted () {
		const isEditModal = this.form.profileId > 0
		if (isEditModal) {
			this.editMode = true
			this.formData.profileId = this.form.profileId
			this.formData.profileName = this.form.profileName
			this.formData.profileDescription = this.form.profileDescription
			this.formData.accessKey = this.form.accessKey
			this.formData.loadTemplateHTML = this.form.loadTemplateHTML
			this.formData.loadTemplateCSS = this.form.loadTemplateCSS
			this.formData.loadTemplateJS = this.form.loadTemplateJS
			this.formData.cassieCookiesExpiry = this.form.cassieCookiesExpiry
			this.formData.isGpc = this.form.isGpc
			this.formData.createdByName = this.form.createdByName
			this.formData.createdDate = this.form.createdDate
			this.formData.editedByName = this.form.editedByName
			this.formData.lastEditedDate = this.form.lastEditedDate
			this.formData.selectedPreBannerAppearance = this.form.selectedPreBannerAppearance
			this.formData.selectedPreBannerColourScheme =
				this.form.selectedPreBannerColourSchemeData?.identifier ??
				preBannerColourSchemeEnum.dark
			this.formData.selectedPreBannerColourSchemeData = this.form.selectedPreBannerColourSchemeData
			if (
				this.formData.selectedPreBannerColourScheme ===
				preBannerColourSchemeEnum.custom
			) {
				this.populatePreBannerCustomColors(
					this.formData.selectedPreBannerColourSchemeData
				)
			}
			this.formData.selectedBannerAppearance = this.form.selectedBannerAppearance
			this.formData.selectedBannerColourScheme =
				this.form.selectedBannerColourSchemeData?.identifier ??
				bannerColourSchemeEnum.dark
			this.formData.selectedBannerColourSchemeData = this.form.selectedBannerColourSchemeData
			if (
				this.formData.selectedBannerColourScheme ===
				bannerColourSchemeEnum.custom
			) {
				this.populateBannerCustomColors(
					this.formData.selectedBannerColourSchemeData
				)
			}
			this.formData.selectedGpcBannerAppearance = this.form.selectedGpcBannerAppearance
			this.formData.selectedGpcBannerColourScheme =
				this.form.selectedGpcBannerColourSchemeData?.identifier ??
				preBannerColourSchemeEnum.dark
			this.formData.selectedGpcBannerColourSchemeData = this.form.selectedGpcBannerColourSchemeData
			if (
				this.formData.selectedGpcBannerColourSchemeData ===
				preBannerColourSchemeEnum.custom
			) {
				this.populateGpcBannerCustomColors(
					this.formData.selectedGpcBannerColourSchemeData
				)
			}
		}
		this.$refs.validationForm.reset()
	},
	methods: {
		accessKeyName (selectedAccessKeyId) {
			// Pass access key name from form to add to profiles array in state
			const selectedAccessKey = this.accessKeys.find(
				accessKey => accessKey.accessKeyId === selectedAccessKeyId
			)
			return selectedAccessKey.accessKeyName
		},
		createNewProfile (form) {
			this.isLoading = true
			this.$emit('createNewProfile', form)
			this.isLoading = false
		},
		updateProfile (form) {
			this.isLoading = true
			this.$emit('updateProfile', form)
			this.isLoading = false
		},
		populatePreBannerCustomColors (preBannerColourScheme) {
			for (const key in preBannerColourScheme) {
				if (!Object.hasOwnProperty.call(preBannerColourScheme, key)) continue
				const color = preBannerColourScheme[key]
				const matchedColor = this.formData.customisePreBannerButtons.find(
					x => x.id.toLocaleLowerCase() === key.toLocaleLowerCase()
				)
				if (matchedColor) matchedColor.color = color
			}
		},
		populateGpcBannerCustomColors (gpcColourScheme) {
			for (const key in gpcColourScheme) {
				if (!Object.hasOwnProperty.call(gpcColourScheme, key)) continue
				const color = gpcColourScheme[key]
				const matchedColor = this.formData.customiseGpcButtons.find(
					x => x.id.toLocaleLowerCase() === key.toLocaleLowerCase()
				)
				if (matchedColor) matchedColor.color = color
			}
		},
		populateBannerCustomColors (bannerColourScheme) {
			for (const key in bannerColourScheme) {
				if (!Object.hasOwnProperty.call(bannerColourScheme, key)) continue
				const color = bannerColourScheme[key]
				const matchedColor = this.formData.customiseBannerButtons.find(
					x => x.id.toLocaleLowerCase() === key.toLocaleLowerCase()
				)
				if (matchedColor) matchedColor.color = color
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.cassie-disabled-button {
	color: var(--global-cassie-disabled-button-color);
	background-color: var(--global-cassie-disabled-button-background-color);
	border: var(--global-cassie-disabled-button-border);
	-webkit-box-shadow: revert;
	box-shadow: revert;
}

.cassie-disabled-textarea {
	padding: 10px !important;
	color: var(--global-cassie-disabled-textarea-color);
	border: var(--global-cassie-disabled-textarea-border);
}

.cassie-description-field-container > div {
	padding-top: 16px !important;
}

.cassie-overlay-card {
	max-height: 75vh;
	overflow-y: auto;
}

.cassie-appearance-settings-container {
	border: var(--global-cassie-settings-border);
	border-radius: 5px;
}

.cassie-customise-button-container {
	width: 50%;
}

.cassie-colour-picker-container {
	background-color: white;
}

.profile-form-appearance-radio {
	display: flex;
	flex-direction: row;
	padding: 0px 5px;
}

@media (max-width: var(--global-cassie-breakpoint-lg)) {
	.cassie-overlay-card {
		max-height: 85vh;
	}
}
</style>
